import { useAuth } from 'react-oidc-context';
import { useState } from 'react';
import { Button, ButtonBase } from '@mui/material';

export default (props) => {

    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)

    const [ emailSent, setEmailSent ] = useState(false)

    function sendEmailHandler(){
        // openLoading("Sending email ...")
        fetch(`${window.origin}/api/sendemail`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(response => response.status
        ).then(status => {
            console.log(status)
        })
        setEmailSent(true)
    }

    function naviagteATOPortal(){
        window.open('https://www.ato.gov.au/General/Online-services/Access-Manager/About-Access-Manager/#LogintoAccessManager','_blank')
    }

    function onConfirm(){
        setIsLoading(true)
        fetch(`${window.origin}/api/setup/ato-access`, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if(response.status === 403){
              auth.signinRedirect()
              throw new Error('Session expired');
            }else{
              return props.onNext('workpaper')
              setIsLoading(false)
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    return (
        <>
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Scout,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>ATO Access</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please follow the steps below to configure your ATO access</p>
            </header>
            <p style={{fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 1</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please click on Email button to receive your software service ID (SSID).</p>           
            <p style={{fontWeight: '600', fontSize: '0.9vw', cursor: 'pointer'}} onClick={sendEmailHandler}>{ !emailSent && 'Send Email'}{ emailSent && 'Resend Email'}</p>
            <p style={{marginTop: '2vh', fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 2</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Log in to Access Manager and update the SSID as provided to you via email.</p>           
            <p style={{fontWeight: '600', fontSize: '0.9vw', cursor: 'pointer'}} onClick={naviagteATOPortal}>Go to ATO Portal</p>
            <p style={{marginTop: '2vh', fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 3</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please confirm that you have successfully configured your ATO access.</p>           
            {/* <ButtonBase
                onClick={onConfirm}
                style={{
                    fontSize: '1vw', 
                    borderRadius: '0.3vw',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 0',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600'
                }}
                disabled={isLoading}
            >
                {!isLoading && <><p style={{lineHeight: '1.3vw', margin: 0}}>Confirm</p></>}
                {isLoading && <div class="d-flex justify-content-center" style={{height: '1.3vw'}}>
                    <div class="spinner-border" role="status" style={{width: '1.3vw', height: '1.3vw'}}>
                        <span class="sr-only">Loading...</span>
                    </div>
                    </div>}
            </ButtonBase> */}
            <Button disabled={isLoading} onClick={onConfirm} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 1)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '1.2vw'}}>Confirm</Button>

            <Button disabled={isLoading} onClick={onConfirm} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.8)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundColor: 'rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Skip for now</Button>
            
        </>
    )
}
