import React from "react";
import { 
    useEffect, 
    useState,
} from "react";
import { 
    Outlet,
    useNavigate, 
    // useSearchParams,
} from "react-router-dom";

import { useAuth } from "react-oidc-context";
import Cookies from "universal-cookie";



export default function ProtectedRoute(props) {

    const auth = useAuth();

    const navigate = useNavigate()
    
    const cookies = new Cookies();

    const currentPath = cookies.get('currentPath') || '/'

    // const [ authenticated, setAuthenticated ] = useState(false)

    // useEffect(() => {
    //     keycloak.init({onLoad: 'login-required'})
    // }).then(isAuthenticated => {
    //     setAuthenticated(isAuthenticated)
    //     console.log(isAuthenticated)
    // })

    



    // const navigate = useNavigate();
    // const [
    //     isLoggedIn, 
    //     setIsLoggedIn,
    // ] = useState(false);
    // const checkUserToken = () => {
    //     // const userToken = localStorage.getItem('user-token');
    //     // let params = Object.fromEntries([...searchParams])
    //     let token = Cookies.get('IdToken')

    //     if (token){
    //         console.log('found token')
    //     }else{
    //         setIsLoggedIn(false);
    //         console.log('naviagte')
    //         return navigate('/auth/login');
    //     }
    //     setIsLoggedIn(true);
    // }

    // useEffect(() => {
    //     if(!auth.isAuthenticated){
    //         auth.signinSilent()
    //     }
    //     console.log(auth.isAuthenticated)
    // })

    


    useEffect(() => {
        console.log(auth.isAuthenticated)
        if(!auth.isAuthenticated){
            navigate('/landing')
            auth.signinSilent()
        }else{
            if(!auth.user?.profile?.accountant || !auth.user?.profile?.accountant?.verified || !auth.user?.profile?.accountant?.ssid ){
                navigate('/setup')
            }else{
                if(currentPath.includes('/setup')){
                    navigate('/')
                }
            }
            // navigate(currentPath)
        }
        // }else{
        //     console.log(auth.user?.expires_in*1000)
        //     const timer = setTimeout(() => {
        //         auth.signoutRedirect()
        //     }, auth.user?.expires_in*1000);  // Set timeout of 2 seconds
        //     console.log(auth.user?.access_token)
        //     console.log(auth.user?.preferred_username)
            
        //     return () => clearTimeout(timer);  // Clear the timeout if the component is unmounted
        // }
    },
        [auth.isAuthenticated]
    );

    


    return (
        <React.Fragment>
            {
                auth.isAuthenticated? <Outlet />: null
                // props.children
            }
        </React.Fragment>
    );
}