import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MenuBar from '../../utils/MenuBar';

export default (props) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [workers, setWorkers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${window.origin}/api/inactive-digital-workers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            setWorkers(data);
            setLoading(false);
        })
        .catch(err => {
            console.error('Failed to fetch workers:', err);
            setError(err);
            setLoading(false);
        });
    }, []);

    const filteredWorkers = workers
        .filter(worker => worker.category === "Accounting")
        .filter(worker => searchTerm ? worker.name.toLowerCase().includes(searchTerm.toLowerCase()) : true);

    return (
        <div className={props.imgClass} style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row'}}>

            <MenuBar />

            <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'2vh', height:'100vh'}}>

                <div style={{ width: '100%', }}>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                            <div style={{flex:1, paddingLeft: '2vw'}}>
                                <Form.Group style={{ textAlign:'left'}}>
                                    <i style={{fontSize: '1vw'}} className="fa-solid fa-magnifying-glass"></i>
                                    <Form.Control value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{display: 'inline', height: '45px', backgroundColor: 'rgba(255,255,255,0)', border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '1rem', maxWidth: '40%'}} type="text" placeholder="Search"/>
                                </Form.Group>
                            </div>
                        </div>

                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>Error fetching data.</p>
                        ) : filteredWorkers.length === 0 ? (
                            <p>No results found.</p>
                        ) : (
                            <div style={{marginTop: '2vh', display:'flex', flexDirection:'column', marginLeft: '2vw', marginBottom: '1.5vh', marginRight: '2vw', marginTop: '3vw'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1vw'}}>
                                    <h2 style={{fontSize: '1.2vw', fontWeight: '500', color: 'rgb(255, 255, 255, 0.9)'}}>Accounting</h2>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '20px', marginTop: '10px' }}>
                                    {filteredWorkers.map((worker, index) => (
                                        <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0px 0.5vw 0px 0px' }}>
                                            <Button
                                                onClick={() => { navigate(worker.path) }}
                                                style={{
                                                    width: '100%',
                                                    height: '8vw',
                                                    borderRadius: "5px",
                                                    backgroundImage: `url(${worker.image})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    border: '1px solid rgb(255, 255, 255, 0.15)'
                                                }}
                                            ></Button>
                                            <p style={{ margin: '0.5vw 0.2vw', fontSize: '0.85vw', color: 'rgb(255, 255, 255, 0.7)', textAlign: 'left', fontWeight: '500'}}>{worker.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    )
}
