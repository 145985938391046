import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuBar from '../../../utils/MenuBar';
import MessageModal from '../../../utils/MessageModal';
import { useAuth } from 'react-oidc-context';
import { Backdrop, CircularProgress } from '@mui/material';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Button } from 'react-bootstrap';



function isValidSubscriptionId(subscriptionId) {
    const regex = /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/;
    return regex.test(subscriptionId);
  }

const plans = [
    {
        planId: "gayatri_plan1",
        planName: "Free Trial",
        description: "Start exploring the platform by creating workpapers for up to 5 clients.",
        pricing: 0
    },
    {
        planId: "gayatri_plan2",
        planName: "Small",
        description: "Expand your capabilities and manage workpapers for up to 200 clients.",
        pricing: 1000
    },
    {
        planId: "gayatri_plan3",
        planName: "Medium",
        description: "Scale your operations confidently with workpapers for up to 500 clients.",
        pricing: 2000
    },
    {
        planId: "gayatri_plan4",
        planName: "Large",
        description: "Streamline complex workflows with workpapers for up to 1000 clients.",
        pricing: 3000
    },
    {
        planId: "gayatri_plan5",
        planName: "Corporate",
        description: "Get unlimited workpapers to support your enterprise's extensive needs.",
        pricing: 3500
    }
]

export default function SubscriptionRequired({setCurrentStep}) {


    const auth = useAuth()

    const navigate = useNavigate()

    const [subscriptionCode, setSubscriptionCode] = useState('')

    const [subscriptionType, setSubscriptionType] = useState('plan')

    const [selectedPlan, setSelectedPlan] = useState('gayatri_plan1')

    const [errorModalShow, setErrorModalShow] = useState(false)

    const [errorMessage, setErrorMessage] = useState()

    const [errorDescription, setErrorDescription] = useState()

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const selectPlanHandler = (plan) => {
        console.log('click')
        console.log(plan)
        setSubscriptionType('plan');
        setSelectedPlan(plan.planId)
    }

    const confirmHandler = () => {


        setButtonDisabled(true)
        if(subscriptionType == '' || (subscriptionType == 'code' && subscriptionCode == '')){
            setErrorMessage('No Plan Selected')
            setErrorDescription('Please select a subscription plan or provide a subscription code.')
            setErrorModalShow(true)
            setButtonDisabled(false)

            return
        }

        if(subscriptionType == 'code' && !isValidSubscriptionId(subscriptionCode)){
            setErrorMessage('Invalid Subscription Code')
            setErrorDescription('Please provide a correct subscription code to proceed.')
            setErrorModalShow(true)
            setButtonDisabled(false)

            return
        }

        let requestBody = {
            subscriptionType: subscriptionType,
            subscriptionValue: subscriptionType == 'code'? subscriptionCode: selectedPlan
        }

        handleOpen()

        fetch(`${window.origin}/api/subscription`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`,
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        }).then(json_req => {

            console.log(json_req)
            let status = json_req.status

            setCurrentStep('setup')
            setButtonDisabled(false)
            handleClose()
        }).catch(error => {
            navigate('/mydigitalworkers/gayatri/subscription/resolved', {state: {shortMessage: 'Unknown Error', descriptionMessage: 'Unfortunately, some unknown error has occured. Please try again later!', destination: '', status: 'error'}})
            handleClose()
        })

        
        
    }

    return (
        <>
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '5vw'}}>
                <div style={{ display: 'flex', flexDirection: 'row', borderRadius: '1vw', alignItems: 'center'}}>

                    <div style={{textAlign: 'left', flex: 2, paddingRight: '4vw'}}>
                        <p style={{fontSize: '2.5vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.9)'}}>Gayatri</p>
                        
                        <p style={{fontSize: '1.1vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.9)'}}>Your new virtual recruit will soon become an integral and indispensable part of your team</p>
                        <p style={{fontSize: '0.9vw', lineHeight: '1.6vw', fontWeight: '300', color: 'rgb(255, 255, 255, 0.7)'}}>Specia digital workers can be deployed across a range of industries and have already proven to be highly valuable additions to major organisations. They can interact with human colleagues and customers, perform assigned tasks, read emails, process attachments, extract data, supplement business rules, input data, supply analytics, report metrics and more.</p>
                        <Button style={{ fontSize:'0.9vw', padding: '0.5vw 1.5vw', borderRadius: '0.2vw', marginTop: '1.5vw'}} onClick={confirmHandler} disabled={buttonDisabled}>Get Started</Button>
                    
                    </div>
                    <div style={{width: '10vw', flex: 2, textAlign: 'right', borderRadius: '1vw'}}>
                        <img style={{width: '100%', height: '27vw', borderRadius: '1vw'}} src="https://specia.ai/wp-content/uploads/2021/11/icons8-team-yTwXpLO5HAA-unsplash.jpg" />
                    </div>
                </div>
            </div>
        
        <div style={{textAlign: 'center', display:'flex', flexDirection:'row'}}>

            

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <MessageModal show={errorModalShow} onClose={() => {setErrorModalShow(false)}} message={errorMessage} description={errorDescription} />
            
            <SessionExpiredModal show={sessionExpired}/>
        </div>
        </>
    );
}
