import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';
import TabDetailModal from './TabDetailModal';
import { CircularProgress, IconButton, ThemeProvider, createTheme } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

function ConfigureWorkpaperSettings() {
    const labels = [
        "General Ledger",
        "Balance Sheet",
        "Profit & Loss",
        "Wages",
        "Wages PAS",
        "Debtor",
        "Creditor",
        "Interest & Penalty",
        "Income Tax Statement (IT)",
        "Integrated Client Account (AS)",
        "GST",
        "GST Rec",
        "FR ACC",
    ];

    const auth = useAuth()

    const [tabData, setTabData] = useState([])

    const [detailModalShown, setDetailModalShown] = useState(false)

    const [currentTabId, setCurrentTabId] = useState('')

    const [currentTabName, setCurrentTabName] = useState('')

    const [currentCustomName, setCurrentCustomName] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const [sessionExpired, setSessionExpired] = useState(false)

    function openModalHandler(tabId, tabName, customName){

        console.log(tabId, tabName, customName)
        
        setCurrentTabId(tabId)
        setCurrentTabName(tabName)
        setCurrentCustomName(customName)
        setDetailModalShown(true)
    }

    function closeDetailModalHandler(){
        setDetailModalShown(false)
        setDetailModalShown('')
    }


    const [isEditing, setIsEditing] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState(
        labels.reduce((acc, label) => {
            acc[label] = false;
            return acc;
        }, {})
    );

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setCheckedItems(
            labels.reduce((acc, label) => {
                acc[label] = !selectAll;
                return acc;
            }, {})
        );
    };

    const handleChange = label => {
        setCheckedItems(prevState => {
            const newCheckedItems = { ...prevState, [label]: !prevState[label] };
            const allSelected = labels.every(l => newCheckedItems[l]);
            setSelectAll(allSelected);
            return newCheckedItems;
        });
    };

    const [ tableData, setTableData ] = useState(tabData)


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentitems] = useState(tabData.slice(indexOfFirstItem, indexOfLastItem))


    // Page change handlers
    const nextPage = () => {
        if (currentPage < Math.ceil(tableData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const fetchData = () => {
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/creator/workpaper-mapping`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response.json();
            }).then(data => {
                setTabData(data)
                setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    useEffect(() => {
        setTableData(tabData)
        console.log(tabData)
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(tabData.slice(indexOfFirstItem, indexOfLastItem))
    }, [tabData]);





    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(tabData.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);




    useEffect(()=> {
        fetchData()
    }, [auth.user?.profile.accountant.ssid])

    return (
        <>  
            <SessionExpiredModal show={sessionExpired}/>
            <>

                <div style={{textAlign: 'left', marginBottom: '0.5vw', width: '15vw', marginBottom: '1.5vw'}}>
                        <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                            <ThemeProvider theme={darkTheme}>

                                <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)', cursor: 'none'}}>
                                    <SearchIcon style={{fontSize: '1vw'}}/>
                                </IconButton>
                            </ThemeProvider>
                            <Form.Control 
                                style={{
                                    display: 'inline',  backgroundColor: 'rgba(255,255,255,0)', padding: 0, paddingLeft: '0.8vw', paddingRight: '0.8vw',
                                    border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '80%', marginLeft:'0' , fontWeight: '200'
                                }} 
                                className='shadow-none'
                                type="text" 
                                placeholder="Search" 
                                // value={searchQuery}
                                // onChange={}
                            />
                        </div>
                </div>

                <div style={{margin: '0.5vw 0', marginTop: '0', padding: 0, fontSize: '1.6rem', borderRadius: '20px'}}>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <div style={{lineHeight: '2.2vw', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '1vw'}}>
                            <table className="table table-hover table-dark" style={{ color: 'white', fontSize: '0.9vw', width: '100%', backgroundColor: 'transparent', margin: 0}}>
                                <thead style={{color: 'rgb(230, 230, 230)', borderBottom: '1px solid rgb(255, 255, 255, 0.1)', backgroundColor: 'rgb(255, 255, 255, 0.03)'}}>
                                    <tr style={{}}>
                                        <th style={{padding:'0.5w 1vw', paddingLeft:'1.5vw', width: '50%'}} scope="col">Tab Name</th>
                                        <th style={{padding:'0.5w 1vw', width: '50%'}} scope="col">Custom Name</th> 
                                    </tr>
                                </thead>
                                
                                <tbody style={{fontSize: '0.85vw'}}>
                                    { !isLoading && !tableData.length && 
                                    <tr>
                                        <td colspan="7"><p style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}>No tab found</p></td>
                                    </tr>}
                                    { isLoading && 
                                    <tr>
                                        <td colspan="7"><p style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}><CircularProgress color="inherit" /></p></td>
                                    </tr>}
                                    { currentItems.map((tab, index) => 
                                        <tr key={index} style={{borderTop: '1px solid rgb(255, 255, 255, 0.1)', cursor: 'pointer'}} onClick={() => openModalHandler(tab.tab_id, tab.tab_name, tab.custom_name)}>
                                            <td style={{padding:'0.5w 1vw', paddingLeft:'1.5vw'}} scope="row">{tab.tab_name}</td>
                                            <td style={{padding:'0.5w 1vw'}}>{tab.custom_name}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div style={{ marginTop: '1vw', marginLeft: '0vw', marginRight: '0vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                            <div style={{flex: 1}}>
                                <p style={{ color: 'rgb(255, 255, 255, 0.6)', fontSize: '0.9vw', marginLeft: '0.2vw', marginTop: '1vw' }}>
                                    Showing {indexOfFirstItem+1} to {indexOfLastItem > tableData.length ? tableData.length: indexOfLastItem} of {tableData.length} entries
                                </p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button 
                                    onClick={prevPage} 
                                    disabled={currentPage === 1}
                                    style={{
                                        width: '2.5vw',
                                        height: '2.5vw',
                                        borderRadius: '0.5vw',
                                        marginRight: '6px',
                                        color: currentPage === 1 ? 'gray' : 'white',
                                        backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                        border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                        // color: 'white',
                                        cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '0.9vw',
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                                </button>
                        
                                <button 
                                    onClick={nextPage} 
                                    disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
                                    style={{
                                        width: '2.5vw',
                                        height: '2.5vw',
                                        borderRadius: '0.5vw',
                                        marginLeft: '6px',
                                        color: 'white',
                                        backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                        border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                        cursor: currentPage === Math.ceil(tableData.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '0.9vw',
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: '&rarr;'}} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
            <TabDetailModal show={detailModalShown} onClose={closeDetailModalHandler} tabId={currentTabId} tabName={currentTabName} customName={currentCustomName} fetchDataHandler={fetchData}/>
        </>
    );
}

export default ConfigureWorkpaperSettings;
