import Modal from 'react-bootstrap/Modal';
import React, { useState, useRef, useEffect } from 'react';
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead';
import { Button, ButtonGroup, Offcanvas, OverlayTrigger, Spinner, Tab, Tabs, ToggleButton, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';

import Papa from "papaparse";

import validator from 'validator';

function isValidABN (ABN) {
    if(!ABN) {
        return false
    }

    const result = validator.isNumeric(ABN) && ABN.length === 11;
    if(result) {
        return true
    } else {
        return false
    }
}

function formatABN(abn) {
    // Remove any non-digit characters in case the input is not clean
    let str = abn.replace(/\D/g, '');

    // Check if the input is exactly 11 digits, if not, handle as needed
    if (str.length !== 11) {
        console.error("Invalid ABN: ABN must be exactly 11 digits");
        return null;
    }

    // Insert spaces to format as ABN
    return str.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
}


export default (props) => {

    const auth = useAuth()

    

    return (
        <div className={props.imgClass} style={{ textAlign: 'center', position: 'relative', height: '100%', display: 'flex', flexDirection: 'row' }}>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>

                <p>Add Client</p>
            </div>
        </div>
    );
}