import { Button, CircularProgress, createTheme, styled, ThemeProvider } from '@mui/material';
import { React, useEffect, useState } from 'react'
import { ButtonGroup, Offcanvas, ToggleButton } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';

import LoadingButton from '@mui/lab/LoadingButton';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import ScanTemplateModal from './ScanTemplateModal';


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default ({show, onClose, tabId, tabName, customName, fetchDataHandler}) => {

    console.log(customName)

    const auth = useAuth()

    const [currentCustomeName, setCurrentCustomeName] = useState(customName);

    const [sessionExpired, setSessionExpired] = useState(false)


    const [isLoading, setIsLoading] = useState(false)


    function saveConfigHandler(){
        console.log(tabId, currentCustomeName)

        let newCOnfig = {
            tabId: tabId,
            newCustomName: currentCustomeName
        }

        setIsLoading(true)


        fetch(`${window.origin}/api/creator/workpaper-mapping`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newCOnfig),
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                } else {
                    return Promise.reject(`Failed to edit client. Status: ${response.status}`);
                }
            } else {
                setIsLoading(false)
                fetchDataHandler()
            }
        })
        .catch(error => {
            console.error('Error editing client:', error);
        });
        // onClose()
    }

    function closeHandler(){
        onClose()
    }

    useEffect(() => {
        setCurrentCustomeName(customName)
    }, [show])
    
    
    return (
        <>
            <SessionExpiredModal show={sessionExpired}/>
            <Offcanvas show={show} onHide={closeHandler} placement="end" style={{width: '23vw', backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(50px)', '-webkit-backdrop-filter': 'blur(50px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1vw', paddingRight: '1vw', paddingTop: '1vw', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '2vw'}}>
                <div style={{flex: 1}}>
                    <Offcanvas.Header style={{padding: '1vw'}}>
                    <Offcanvas.Title style={{fontSize: '1.3vw'}}>{tabName}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{paddingTop: '2vw', height: '100%', padding: '1vw'}}>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Custom Name</p>
                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This name will displayed instead of the default tab name.</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="text" placeholder="Custom Name" value={currentCustomeName} onChange={(e) => setCurrentCustomeName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Delete Tab</p>
                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This tab will be deleted from the tab list and will not be populated during workpaper generation process.</p>
                            <Button style={{textTransform: 'none', borderRadius: '10vw', fontSize: '0.8vw', padding: '0.25vw 1vw', lineHeight: '1.5vw'}} variant="contained" color="error">
                                Delete
                            </Button>
                        </Form.Group>
                       
                        
                        
                    </Offcanvas.Body>
                    {/* { isLoading && <>
                        <div style={{textAlign: 'center', paddingTop: '35vh'}}>
                            <CircularProgress color="inherit" />
                        </div>
                    </>} */}
                </div>
                <div style={{textAlign: 'right', paddingBottom: '1.5vw', paddingRight: '1vw'}}>

                    <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1.25vw', borderRadius: '1.5vw', textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)', backgroundColor: 'rgb(0, 0, 0, 0.1)'}}  onClick={closeHandler}>
                        Close
                    </TextButton>
                    <ThemeProvider theme={darkTheme}>
                        <LoadingButton loading={isLoading} variant='contained' style={{ fontSize: '0.9vw', color: isLoading? 'rgb(255, 255, 255, 0)':'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}}  onClick={saveConfigHandler}>
                            <span>Save</span>
                        </LoadingButton>
                    </ThemeProvider>
                </div>

                
            </Offcanvas>

        </>
            
    )
}