import { createSlice, current } from '@reduxjs/toolkit'

export const stepSlice = createSlice({
  name: 'step',
  initialState: {
    value: 'company',
    tier: 'N',
    role: 'user',
    steps:{
      owner: [{
          code: 'company',
          order: 1,
          title: "Company Details",
          description: "Enter Company Information"
      },
      {
          code: 'verification',
          order: 2,
          title: "Account Verification",
          description: "Check your account verification status"
      },
      {
          code: 'Scout',
          order: 3,
          title: "Pricing Plan"
      },
      {
          code: 'ato',
          order: 4,
          title: "ATO Access",
          description: "Follow the steps to get ATO Access"
      },
      // {
      //     code: 'integration',
      //     order: 4,
      //     title: "Integration"
      // },
      {
          code: 'client',
          order: 5,
          title: "First Client",
          description: "Add your first client or all clients"
      },
      {
          code: 'completed',
          order: 6,
          title: "Completed",
          description: "Complete your setup and take rest"
      }],


      manager: [{
          code: 'company',
          order: 1,
          title: "Company Details"
      },
      {
          code: 'verification',
          order: 2,
          title: "Verification"
      },
      {
          code: 'client',
          order: 3,
          title: "First Client"
      },
      {
          code: 'completed',
          order: 4,
          title: "Completed"
      }],
      user: [{
          code: 'company',
          order: 1,
          title: "Company Details"
      },
      {
          code: 'verification',
          order: 2,
          title: "Verification"
      },
      {
          code: 'completed',
          order: 3,
          title: "Completed"
      }]
    }
  },
  reducers: {
    setStep: (state, action) => {

      let currentState = current(state)
      let nextOrder = currentState.steps[currentState.role].find(item => item.code === currentState.value).order + 1

      let nextCode = currentState.steps[currentState.role].find(item => item.order === nextOrder).code
      console.log(nextCode)
      return {...currentState, value: nextCode}
    },
    setTier: (state, action) => {
      return {...state, tier: action.payload}
    },
    setRole: (state, action) => {
      return {...state, role: action.payload}
    }
  },
})

// Action creators are generated for each case reducer function
export const { setStep, setTier, setRole } = stepSlice.actions

export default stepSlice.reducer