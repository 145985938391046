import { useNavigate } from 'react-router-dom';

import Form from 'react-bootstrap/Form';

import CircleSteps from './CircleSteps';

import MenuBar from '../../../utils/MenuBar';

import Nav from 'react-bootstrap/Nav';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';


const tabs = [
    "Integrated Client Account",
    "Interest & Penalty",
    "Income Tax Statement",
    "Franking Account",
    "General Ledger",
    "Balance Sheet",
    "Profit & Loss",
    "Wages PAS",
    "Creditor",
    "GST Rec",
    "Debtor",
    "Wages",
    "GST",
]

export default (props) => {

    const navigate = useNavigate()

    const auth = useAuth()

    const [selectedTabs, setSelectedTabs] = useState([])

    const [isSelectedAll, setIsSelectedAll] = useState(false)

    function onConfirm(){
        // props.onNext('integration')
        fetch(`${window.origin}/api/creator/setup/workpaper`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                workpaper_tabs: selectedTabs
            })
        }).then(response => {
            if(response.status === 403){
              auth.signinRedirect()
              throw new Error('Session expired');
            }else{
              return props.onNext('integration')
            }
        })
    }

    function selectTab(e, label){

        console.log(e)

        if(label === 'Select All'){
            if(!e.target.checked){
                console.log('executed')
                return setSelectedTabs([])
            }else{
                return setSelectedTabs([...tabs])
            }
        }

        if(e.target.checked){
            setSelectedTabs(oldState => [...oldState, label])
        }else{
            setSelectedTabs(oldState => oldState.filter(item => item !== label))
        }
    }

    useEffect(() => {
        if(tabs.length == selectedTabs.length){
            setIsSelectedAll(true)
        }else{
            setIsSelectedAll(false)
        }
    }, [selectedTabs])

    return (
        <>
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Workpaper Configuration</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please select tabs you want to include in your workpaper</p>
            </header>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' , fontSize: '0.9rem'}}>
            
                <Form.Check
                        type="checkbox"
                        id={`checkbox-${-1}`}
                        label={"Select All"}
                        key={"Select All"}
                        checked={isSelectedAll}
                        onChange={(e) => selectTab(e, "Select All")}
                        style={{ margin: '5px 0' }}
                    />
                {tabs.map((label, index) => (
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        label={label}
                        key={label}
                        checked={selectedTabs.includes(label)}
                        onChange={(e) => selectTab(e, label)}
                        style={{ margin: '5px 0' }}
                    />
                ))}
            </div>
                        
            <button
                onClick={onConfirm}
                style={{
                    borderRadius: '4px',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 20px',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600',
                    marginTop: '3vh'
                }}
            >
                Confirm
            </button>
        </>
    )
}
