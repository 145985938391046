import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import 'handsontable/dist/handsontable.full.min.css';

import Handsontable from 'handsontable';
import { registerAllModules } from 'handsontable/registry';

registerAllModules();

const indexedColorMap = {
    0: '#000000',  // Black
    1: '#FFFFFF',  // White
    2: '#FF0000',  // Red
    3: '#00FF00',  // Green
    4: '#0000FF',  // Blue
    5: '#FFFF00',  // Yellow
    6: '#FF00FF',  // Magenta
    7: '#00FFFF',  // Cyan
    8: '#800000',  // Maroon
    9: '#008000',  // Dark Green
    10: '#000080',  // Navy
    11: '#808000',  // Olive
    12: '#800080',  // Purple
    13: '#008080',  // Teal
    14: '#C0C0C0',  // Silver
    15: '#808080',  // Gray
    16: '#9999FF',  // Lavender
    17: '#993366',  // Brown
    18: '#FFFFCC',  // Light Yellow
    19: '#CCFFFF',  // Light Cyan
    20: '#660066',  // Dark Magenta
    21: '#FF8080',  // Salmon
    22: '#0066CC',  // Medium Blue
    23: '#CCCCFF',  // Light Purple
    24: '#000080',  // Dark Blue
    25: '#FF00FF',  // Magenta
    26: '#FFFF00',  // Yellow
    27: '#00FFFF',  // Cyan
    28: '#800080',  // Dark Purple
    29: '#800000',  // Dark Red
    30: '#008080',  // Dark Teal
    31: '#0000FF',  // Blue
    32: '#00CCFF',  // Sky Blue
    33: '#CCFFFF',  // Light Cyan
    34: '#CCFFCC',  // Light Green
    35: '#FFFF99',  // Light Yellow
    36: '#99CCFF',  // Light Blue
    37: '#FF99CC',  // Pink
    38: '#CC99FF',  // Light Purple
    39: '#FFCC99',  // Peach
    40: '#3366FF',  // Royal Blue
    41: '#33CCCC',  // Aqua
    42: '#99CC00',  // Lime
    43: '#FFCC00',  // Gold
    44: '#FF9900',  // Orange
    45: '#FF6600',  // Orange-Red
    46: '#666699',  // Grayish Purple
    47: '#969696',  // Medium Gray
    48: '#003366',  // Dark Blue
    49: '#339966',  // Sea Green
    50: '#003300',  // Dark Green
    51: '#333300',  // Olive Green
    52: '#993300',  // Burnt Orange
    53: '#993366',  // Plum
    54: '#333399',  // Indigo
    55: '#333333',  // Dark Gray
    56: '#000000',  // Black
    57: '#FFFFFF',  // White
    58: '#FF0000',  // Red
    59: '#00FF00',  // Green
    60: '#0000FF',  // Blue
    61: '#FFFF00',  // Yellow
    62: '#FF00FF',  // Magenta
    63: '#00FFFF'   // Cyan
  };

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

export default ({show, workbook, onHide}) => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());






    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton style={{ padding: '0.3rem 1rem', position: 'relative', top: '0px', borderWidth: '0px' }}>
            </Modal.Header>
            <Modal.Body>
                <iframe style={{borderRadius: '0.5vw', height: '80vh'}} src={workbook} width="100%" height={(windowDimensions/100)*80} frameborder="0" scrolling="no"></iframe>
            </Modal.Body>
        </Modal>
    )
}

