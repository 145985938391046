import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';

function Profile() {
    const auth = useAuth();

    const [firstName, setFirstName] = useState(auth.user?.profile?.given_name);
    const [lastName, setLastName] = useState(auth.user?.profile?.family_name);
    const [email, setEmail] = useState(auth.user?.profile?.email);
    const [password, setPassword] = useState('YourActualPasswordHere'); // Replace with the actual password or fetching mechanism
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div>
            <p style={{ fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight: '300' }}>
                Update personal information and interaction preferences.
            </p>
            
            {[
                { label: 'First Name', value: firstName },
                { label: 'Last Name', value: lastName },
                { label: 'Email', value: email },
            ].map((field, index) => (
                <div 
                    key={index} 
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        backgroundColor: 'rgb(255, 255, 255, 0.05)', 
                        padding: '1vw 1.5vw', 
                        borderRadius: '0.6vw', 
                        marginTop: '1.5vw', 
                        marginBottom: '1.5vw',
                    }}
                >
                    <div style={{ flex: 1, fontWeight: '600', fontSize: '1vw' }}>
                        <p style={{ margin: 0 }}>{field.label}</p>
                    </div>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <p style={{ textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '1vw', flex: 1 }}>
                            {field.value}
                        </p>
                        {/* {field.label === 'Password' && (
                            <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer', marginLeft: '10px' }}>
                                {showPassword ? '👁️' : '🙈'}
                            </span>
                        )} */}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Profile;
