import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuBar from '../../../utils/MenuBar';
import ProvideTaxAgentNumber from './ProvideTaxAgentNumber';
import SetupWorkpaper from './SetupWorkpaper';
import ConfigureAtoPortal from './ConfigureAtoPortal';
import FinacialSoftwareIntegration from './FinacialSoftwareIntegration';
import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';

const steps = [
    {
      key: 'tan',
      label: 'Tax Agent Number',
      description: `Please provide your Tax Agent Number`,
    },
    {
      key: 'ato',
      label: 'ATO Configuration',
      description:
        'Please follow the instructions to set up your access to Australian Tax Office',
    },
    {
      key: 'integration',
      label: 'Accounting Software',
      description: `Configure connection to your accounting software`,
    },
  ];

export default function GayatriSetup({ imgClass, currentStep, setCurrentStep }) {
    
    const {state} = useLocation()

    // function onNextStep(nextStep){
    //     setCurrentStep(nextStep)
    // }

    const [activeStep, setActiveStep] = React.useState(steps.findIndex(x => x.key ===currentStep));
    
    const onNextStep = () => {
        console.log(activeStep, steps.length)
        if(activeStep+1 >= steps.length){
            setCurrentStep('complete')
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    useEffect(() => {
        if(currentStep != 'complete'){
            setActiveStep(steps.findIndex(x => x.key ===currentStep))
        }
    }, [currentStep])

    

    return (
        <div style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row'}}>

            {/* navbar */}
            {/* <MenuBar /> */}

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '3vw' }}>
                <>
                    {/* <div style={{ height: '15vh'}}>
                      <div style={{flex: 2, marginLeft: '2vw', marginRight: '2vw', marginTop: '2.5vh'}} >
                        <p style={{fontSize: '3.6vh', marginBottom: '0.4vw'}}>Gayatri</p>
                        <p style={{fontSize: '1.5vh', color: 'rgb(255, 255, 255, 0.7)'}}>Discover the future with precision automation</p>
                      </div>
                    </div> */}
                    
                    <div style={{ height: '85vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{flex: 4}}>
                            <div style={{paddingLeft: '13vw', textAlign: 'left', paddingRight: 0, paddingBottom: '15vh'}}>
                                {steps[activeStep].key === 'tan' && <ProvideTaxAgentNumber onNext={onNextStep}/>}
                                {steps[activeStep].key === 'workpaper' && <SetupWorkpaper onNext={onNextStep}/>}
                                {steps[activeStep].key === 'ato' && <ConfigureAtoPortal onNext={onNextStep}/>}
                                {steps[activeStep].key === 'integration' && <FinacialSoftwareIntegration onNext={onNextStep}/>}
                            </div>
                        </div>
                        <div style={{flex: 3, paddingLeft: '15vw', paddingBottom: '7vw'}}>
                            {/* <ol class="process-steps">
                                <li class="step active">
                                    <div class="step-label">
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                                            <h3 style={{ fontSize: '1.25rem' }}>Tax Agent Number</h3>
                                            <p style={{ fontSize: '0.8rem', color: '#D9D9D9', margin: 0 }}>Enter the Company Information</p>
                                        </div>
                                    </div>
                                    <div class="connector"></div>
                                    <div class="step-circle">1</div>
                                </li>
                                <li class="step">
                                    <div class="step-label">
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                                            <h3 style={{ fontSize: '1.25rem' }}>ATO Access</h3>
                                            <p style={{ fontSize: '0.8rem', color: '#D9D9D9' , margin: 0}}>Follow the steps to get ATO Access</p>
                                        </div>
                                    </div>
                                    <div class="connector"></div>
                                    <div class="step-circle">3</div>
                                </li>
                                <li class="step">
                                    <div class="step-label">
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                                            <h3 style={{ fontSize: '1.25rem' }}>Workpaper Configuration</h3>
                                            <p style={{ fontSize: '0.8rem', color: '#D9D9D9', margin: 0 }}>Check what workpapers you want</p>
                                        </div>
                                    </div>
                                    <div class="connector"></div>
                                    <div class="step-circle">2</div>
                                </li>
                                <li class="step">
                                    <div class="step-label">
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                                            <h3 style={{ fontSize: '1.25rem' }}>Integration</h3>
                                            <p style={{ fontSize: '0.8rem', color: '#D9D9D9' , margin: 0}}>Setup the Financial Software Integration</p>
                                        </div>
                                    </div>
                                    <div class="step-circle">4</div>
                                </li>
                            </ol> */}
                            <>
                        <Box sx={{ maxWidth: 500 }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                    
                                    >
                                    {step.label}
                                    </StepLabel>
                                    <StepContent>
                                    <Typography style={{textAlign: 'left'}}>{step.description}</Typography>
                                    
                                    </StepContent>
                                </Step>
                                ))}
                            </Stepper>
                            </Box>
                        </>
                        </div>
                    </div>
                </>
            </div>

        </div>
    );
}
