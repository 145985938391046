import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useAuth } from "react-oidc-context"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import UserTable from "./UserTable"
import DetailModal from "./DetailModal"
export default (props) => {

    const [userData, setUserData] = useState([])

    const [detailModalShown, setDetailModalShown] = useState(false)

    const [currentUserId, setCurrentUserId] = useState('')

    const [isLoading, setIsLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('');


    const auth = useAuth()


    function openModalHandler(userId){
        setDetailModalShown(true)
        setCurrentUserId(userId)
    }

    function closeDetailModalHandler(){
        setDetailModalShown(false)
        setCurrentUserId('')
    }

    useEffect(()=> {
        if (auth.user?.profile.accountant.ssid){
            fetch(`${window.origin}/api/users`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                  auth.signinRedirect()
                }else{
                  return response.json()
                }
            }).then(data => {
                setUserData(data.filter(item => item.email !== auth.user?.profile.email))
                setIsLoading(false)
            })
        }
    }, [auth.user?.profile.accountant.ssid])

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter userData based on searchQuery
    const filteredUserData = userData.filter(user =>
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>  
            <div style={{margin: '0.5vw 0', marginBottom: 0, display: 'flex', flexDirection: 'row'}}>
                <div style={{flex:1, paddingLeft: '0.2vw'}}>
                    <Form.Group style={{marginBottom: '1vw', textAlign:'left'}}>
                        <i style={{fontSize: '1vw'}} class="fa-solid fa-magnifying-glass" ></i>
                        <Form.Control
                            style={{display: 'inline', height: '45px', backgroundColor: 'rgba(255,255,255,0)', border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '1vw', maxWidth: '40%', marginLeft:'0.5vw'}}
                            type="text"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </Form.Group>
                </div>
            </div>

            <Modal.Body style={{margin: '0.5vw 0', marginTop: '0', padding: 0, fontSize: '1.6rem', borderRadius: '20px'}}>
                <div style={{width: '100%', textAlign: 'left'}}>
                    <UserTable userData={filteredUserData} openModalHandler={openModalHandler} isLoading={isLoading}/>
                </div>
            </Modal.Body>
            <DetailModal show={detailModalShown} onClose={closeDetailModalHandler} userId={currentUserId}/>
        </>
    );
};
