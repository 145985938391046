import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import TraceProvider from "./helpers/tracing";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "react-oidc-context";

import store from './app/store'
import { Provider } from 'react-redux'

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const port = window.location.port;
let baseUrl = window.location.origin;

let realm = 'accountant'

// if(baseUrl === 'https://accountant.specia.ai'){
//   realm = 'accountant-production'
// }

console.log(realm)

const oidcConfig = {
  authority: `${baseUrl}`,
  client_id: "portal",
  redirect_uri: `${baseUrl}/auth/login`,
  post_logout_redirect_uri: `${baseUrl}/auth/logout`,
  response_type: "code",
  automaticSilentRenew: true,
  metadata: {
    authorization_endpoint: `${baseUrl}/realms/${realm}/protocol/openid-connect/auth`,
    token_endpoint: `${baseUrl}/realms/${realm}/protocol/openid-connect/token`,
    end_session_endpoint: `${baseUrl}/realms/${realm}/protocol/openid-connect/logout`,
  },
  scope: "openid"
};

if (baseUrl.includes('localhost:3000')) {
  realm = 'accountant'
  baseUrl = `${baseUrl}`;
  const keycloakUrl = "https://staging.platform.specia.ai"
  oidcConfig.authority = keycloakUrl;
  oidcConfig.metadata = {
    authorization_endpoint: `${keycloakUrl}/realms/${realm}/protocol/openid-connect/auth`,
    token_endpoint: `${keycloakUrl}/realms/${realm}/protocol/openid-connect/token`,
    end_session_endpoint: `${keycloakUrl}/realms/${realm}/protocol/openid-connect/logout`,
  }
  console.info(oidcConfig)
}


const template = (
  // <React.StrictMode>
    <Provider store={store}>
      {/* <TraceProvider> */}
        <AuthProvider {...oidcConfig}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      {/* </TraceProvider> */}
    </Provider>
  // </React.StrictMode>
);
root.render(template);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
