import WorkpaperTemplateSettings from './WorkpaperTemplateSettings';

import ConfigureWorkpaperSettings from './ConfigureWorkpaperSettings';
import TaxAgentNumberSettings from './TaxAgentNumberSettings';

import AccountingSoftwareSettings from './AccountingSoftwareSettings';
import SSIDSettings from './SSIDSettings';
import UsersConfig from './UsersConfig';
import React, { useState } from 'react';
import SubscriptionSettings from './SubscriptionSettings';
import { ButtonBase } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default (props) => {

    const routeParams = useLocation()

    console.log(routeParams)

    const [currentComponent, setCurrentComponent] = useState(routeParams.state? routeParams.state.tab: 'Users');

    const [currentTitle, setCurrentTitle ] = useState('Access Control')

    const handleClick = (componentName, titleName) => {
        setCurrentComponent(componentName);
        setCurrentTitle(titleName)
    };

    return (
        <div className={props.imgClass} style={{ display: 'flex', flexDirection: 'row', height: '81vh', overflowY:'hidden' }}>

            {/* <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '2vh', height: 'calc(100vh - 4vh)' }}> */}

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '81vh', paddingTop: '40px', paddingLeft: '1vw' }}>
                    {/* left */}
                    <div style={{ width: '50%', textAlign: 'left', flex: 3, height: '81vh', overflowY: 'hidden' }}>
                        <h4 style={{ fontSize: '1.3vw' , fontWeight: '400', marginBottom: '1.9vw', marginLeft: '1vw', color: 'rgb(255, 255, 255, 0.7)'}}>Settings</h4>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , marginLeft: '1vw', paddingRight: '5vw'}}>
                            <ButtonBase
                                onClick={() => handleClick('Users', 'Access Control')}
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1vw',
                                    padding: '1.3vw',
                                    borderRadius: '0.5vw',
                                    width: '100%',
                                    background: currentComponent === 'Users' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.04)',
                                    color: currentComponent === 'Users' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                }}
                            >
                                <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Access Control</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'Users' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>
                                    Manage user roles, permissions, and accessibility to various parts of the application.
                                </p>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => handleClick('AccountingSoftwareSettings', 'Accounting Software')}
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1vw',
                                    padding: '1.3vw',
                                    borderRadius: '0.5vw',
                                    width: '100%',
                                    background: currentComponent === 'AccountingSoftwareSettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.05)',
                                    color: currentComponent === 'AccountingSoftwareSettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                }}
                            >
                                <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Accounting Software</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'AccountingSoftwareSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>
                                    Configure integration to third-party Accounting Softwares
                                </p>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => handleClick('WorkpaperTemplateSettings', 'Workpaper Template')}
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1vw',
                                    padding: '1.3vw',
                                    borderRadius: '0.5vw',
                                    width: '100%',
                                    background: currentComponent === 'WorkpaperTemplateSettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.05)',
                                    color: currentComponent === 'WorkpaperTemplateSettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                }}
                            >
                                <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Workpaper Template</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'WorkpaperTemplateSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>
                                    Adjust settings related to the creation, view, and management of workpapers.
                                </p>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => handleClick('SSIDSettings', 'ATO Connection')}
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1vw',
                                    padding: '1.3vw',
                                    borderRadius: '0.5vw',
                                    width: '100%',
                                    background: currentComponent === 'SSIDSettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.05)',
                                    color: currentComponent === 'SSIDSettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                }}
                            >
                                <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>ATO Connection</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'SSIDSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>
                                    Configure the connection parameters to the Australian Tax Office (ATO) system.
                                </p>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => handleClick('SubscriptionSettings', 'Subscription')}
                                style={{
                                    textAlign: 'left',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1vw',
                                    padding: '1.3vw',
                                    borderRadius: '0.5vw',
                                    width: '100%',
                                    background: currentComponent === 'SubscriptionSettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.05)',
                                    color: currentComponent === 'SubscriptionSettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                }}
                            >
                                <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Subscription</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'SubscriptionSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>
                                    Manage and configure your subscription.
                                </p>
                            </ButtonBase>
                        </div>
                    </div>

                    {/* right */}
                    <div style={{ width: '50%', textAlign: 'left', paddingRight: '2vw', flex: 4, height: 'calc(100vh - 4vh - 40px)', overflowY: 'hidden' }}>
                        <h4 style={{ fontSize: '1.3vw' , fontWeight: '400', marginBottom: '1.9vw', color: 'rgb(255, 255, 255, 0.7)'}}>{currentTitle}</h4>
                        {/* {currentComponent === 'TaxAgentNumberSettings' && <TaxAgentNumberSettings />} */}
                        {currentComponent === 'AccountingSoftwareSettings' && <AccountingSoftwareSettings />}
                        {currentComponent === 'SSIDSettings' && <SSIDSettings />}
                        {/* {currentComponent === 'ConfigureWorkpaperSettings' && <ConfigureWorkpaperSettings />} */}
                        {currentComponent === 'WorkpaperTemplateSettings' && <WorkpaperTemplateSettings />}
                        {currentComponent === 'Users' && <UsersConfig />}
                        {currentComponent === 'SubscriptionSettings' && <SubscriptionSettings />}
                    </div>
                </div>

            {/* </div> */}
        </div>
    )
}