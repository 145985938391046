import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuBar from '../../../utils/MenuBar';
import ProvideTaxAgentNumber from './ProvideTaxAgentNumber';
import SetupWorkpaper from './SetupWorkpaper';
import ConfigureAtoPortal from './ConfigureAtoPortal';
import FinacialSoftwareIntegration from './FinacialSoftwareIntegration';

export default function CircleSteps({ imgClass }) {
    
    const navigate = useNavigate();

    const location = useLocation(); // get the current location

    const {state} = useLocation()

    const [currentStep, setCurrentStep] = useState(state.currentStep)

    console.log(currentStep)



    function onNextStep(nextStep){
        setCurrentStep(nextStep)
    }

    return (
        <div style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row'}}>

            {/* navbar */}
            <MenuBar />

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
                <>
                    <div style={{ height: '15vh'}}>
                      <div style={{flex: 2, marginLeft: '2vw', marginRight: '2vw', marginTop: '2.5vh'}} >
                        <p style={{fontSize: '3.6vh', marginBottom: '0.4vw'}}>Scout</p>
                        <p style={{fontSize: '1.5vh', color: 'rgb(255, 255, 255, 0.7)'}}>Discover the future with precision automation</p>
                      </div>
                    </div>
                    <div style={{ height: '85vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{flex: 1}}>
                            <div style={{paddingLeft: '13vw', textAlign: 'left', paddingRight: 0, paddingBottom: '15vh'}}>
                                {currentStep === 'tan' && <ProvideTaxAgentNumber onNext={onNextStep}/>}
                                {currentStep === 'workpaper' && <SetupWorkpaper onNext={onNextStep}/>}
                                {currentStep === 'ato' && <ConfigureAtoPortal onNext={onNextStep}/>}
                                {currentStep === 'integration' && <FinacialSoftwareIntegration onNext={onNextStep}/>}
                            </div>
                        </div>
                        <div style={{flex: 1, paddingLeft: '15vw'}}>
                            <ol class="process-steps">
                                <li class="step-1 active">
                                    <div class="step-label">
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                                            <h3 style={{ fontSize: '1.25rem' }}>Tax Agent Number</h3>
                                            <p style={{ fontSize: '0.8rem', color: '#D9D9D9', margin: 0 }}>Enter the Company Information</p>
                                        </div>
                                    </div>
                                    <div class="connector-1"></div>
                                    <div class="step-circle-1">1</div>
                                </li>
                                <li class="step">
                                    <div class="step-label">
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left' }}>
                                            <h3 style={{ fontSize: '1.25rem' }}>ATO Access</h3>
                                            <p style={{ fontSize: '0.8rem', color: '#D9D9D9' , margin: 0}}>Follow the steps to get ATO Access</p>
                                        </div>
                                    </div>
                                    <div class="step-circle-1">3</div>
                                </li>

                            </ol>
                        </div>
                    </div>
                </>
            </div>

        </div>
    );
}
