import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeciaLogoWithName from '../../resources/SpeciaLogoWithName.png'

import SpeciaLogo2 from '../../resources/circle specia 2.png'
import { useAuth } from 'react-oidc-context';
import { Button } from '@mui/material';



export default function EmployeeSetup() {

    const navigate = useNavigate()
  

  return (
    <div style={{display:'flex', flexDirection:'column', width:'100vw', height:'100vh'}}>

        <div style={{display:'flex', flexDirection:'row', padding: '5vh 15vw'}}>
            <div style={{flex: 1}}>
                <img src={SpeciaLogoWithName} width="40%" style={{paddingBottom: '12vh'}}/>
                <img src={SpeciaLogo2} width="90%" style={{paddingBottom: '15vh'}}/> 
            </div>
            


            <div style={{flex: 1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <div style={{paddingLeft: '10vw'}}>
                    <header>
                        <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                            <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Specia Platform,
                        </p>
                        <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Join your Organization</h1>
                        <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0}}>Please contact your administrator for access to your organization.</p>
                    </header>
                
            <Button onClick={() => {navigate(-1)}} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '1vw'}}>Previous</Button>

            {/* <Button onClick={() => {navigate(-1)}} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundColor: 'rgb(255,255, 255, 0.1)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Previous</Button> */}
            </div>
            </div>

        </div>
    </div>
  );
}
