import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import creatorImg from "../resources/creatorMinimalist.png";
import reporterImg from "../resources/reporterMinimalist.png";

import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

import MenuIcon from '@mui/icons-material/Menu';

import BGD from "../resources/BGD.png";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import HomeBG from "../resources/BGD.png";
import HomeBG_mobile from "../resources/homeBgIm_mobile_view.png";
import MenuBar from './MenuBar';

import MenuBarNew from './MenuBarNew';
import { useAuth } from 'react-oidc-context';
import { CircularProgress, Fab, Fade, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import MyDigitalWorker from '../pages/MyDigitalWoker/MyDigitalWorker';
import QuickAccessBar from './QuickAccessBar';
import SessionExpiredModal from './SessionExpiredModal';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function Header40({ imgClass, menuBarOpen, menuBarClose }) {
    const navigate = useNavigate();
    const auth = useAuth(); // Get the authentication context
    const [workers, setWorkers] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // To indicate the loading state

    const [sessionExpired, setSessionExpired] = useState(false)
    
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    };

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const scrollToMyDigitalWorker = () => {
      window.scrollTo(0, windowDimensions.height);
    }

    useEffect(() => {
          setIsLoading(true);

          fetch(`${window.origin}/api/digital-workers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`
            },
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        })
        .then((data) => {
            // Mapping 'url' to 'path'
            const adjustedData = data.map(worker => ({
                ...worker,
                path: worker.url
            }));
            console.log(adjustedData)
            setWorkers(adjustedData);
            setIsLoading(false);
        })
        .catch((error) => {
            console.error('Failed to fetch workers:', error);
        });

        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
      }, [auth]);


    // if(windowDimensions.height < windowDimensions.width)
        return (
          <>
            <SessionExpiredModal show={sessionExpired}/>
            <div className={imgClass} style={{ textAlign: 'center', position: 'relative', height: windowDimensions.height, flexDirection: 'row', 'backgroundImage': `linear-gradient(180deg, rgb(255, 255, 255, 0) 0%, #120922 100%), linear-gradient(245deg, rgb(17, 10, 34, 0) 0px, rgb(19, 11, 38, 0.98) 80%, rgb(32, 10, 43, 1) 100%), url("${BGD}")`}}>
              {!isLoading && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', position: 'absolute'}}>
                  {/* home page */}
                  <div
                    className="home-page"
                    style={{
                      width: '100%',
                      height: '100vh',
                      display: 'flex',
                      textAlign: 'left',
                      justifyContent: 'center',
                      flexDirection: window.innerWidth <= 768 ? 'column' : 'column',
                    }}
                  >
                    <div
                      style={{
                        margin: '1.5vw 1.5vw',
                        display: 'flex',
                        alignItems: 'center',
                        display: 'flex',
                        alignItems: 'flex-start',
                        // backgroundImage: `url(${HomeBG})`,
                        flex: 1,
                        // backgroundSize: 'contain',
                        // backgroundRepeat: 'no-repeat',
                        // backgroundPosition: 'right',
                        flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                      }}
                    >
                      <div style={{ flex: 1, marginTop: '6vw', marginLeft: '4vw'}}>
                        <p style={{ fontSize: '6.5vw', letterSpacing: '0.8vw', fontWeight: '100' }}>
                          SPECIA
                        </p>
                        <p
                          style={{
                            width: '40%',
                            fontWeight: '300',
                            color: 'rgb(255, 255, 255, 0.85)',
                            fontSize: '0.9vw',
                            borderLeft: '0.2vw solid rgb(255, 255, 255, 0.8)',
                            paddingLeft: '0.7vw'
                          }}
                        >
                          Digital workers with advanced intelligence streamline industries by managing repetitive tasks, interacting with humans, and providing analytics. Unlike humans, they operate error-free and continuously.
                        </p>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2.5vw'}}>
                          <ArrowDownwardOutlinedIcon onClick={scrollToMyDigitalWorker} style={{fontSize: '0.8vw', border: '1.5px solid rgb(255, 255, 255, 0.6)', borderRadius: '10vw', height: '2.8vw',  width: '2.8vw', padding: '0.5vw', cursor: 'pointer', color: 'rgb(255, 255, 255, 0.7)'}}/>
                          <div style={{marginLeft: '1.25vw', color: 'rgb(255, 255, 255, 0.6)', fontSize: '0.9vw'}}>My Digital Workers</div>
                        </div>
                      </div>
                      <div style={{ }}>
                            <QuickAccessBar/>
                      </div>
                    </div>
                    <div
                      className="quick-access"
                      style={{
                        marginTop: window.innerWidth <= 768 ? '2vh' : '2vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: '10vw',
                        paddingRight: '5vw',
                        marginLeft: window.innerWidth <= 768 ? '2vw' : '3vw',
                        marginBottom: window.innerWidth <= 768 ? '2vh' : '2vw',
                        marginRight: window.innerWidth <= 768 ? '2vw' : '3vw',
                      }}
                    >
                      <div
                        // style={{
                        //   display: 'grid',
                        //   gridTemplateColumns: window.innerWidth <= 768 ? 'repeat(2, 1fr)' : 'repeat(5, 1fr)',
                        //   gap: window.innerWidth <= 768 ? '10px' : '20px',
                        //   marginTop: '10px',
                        // }}

                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: 'rgb(150, 150, 150, 0.15)',
                            border: '1px solid rgb(255, 255, 255, 0.15)',
                            padding: '1.25vw 0 1.25vw 1.25vw',
                            borderRadius: '2.5vw',
                            backdropFilter: 'blur(150px)', 
                            '-webkit-backdrop-filter': 'blur(150px)',
                            textAlign: 'center',
                            boxShadow: '0 15px 15px hsl(0deg 0% 0% / 0.4)'
                          }}
                      >
                        {workers.slice(0, 4).map((worker, index) => (
                          <div key={index} style={{ display: 'flex', flexDirection: 'column', padding: '0px 1.25vw 0px 0px' }}>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">{worker.name}</Typography>
                                  {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
                                  {"Click here to access your digital worker"}
                                </React.Fragment>
                              }
                            >
                            <div
                              onClick={() => navigate(worker.path)}
                              style={{
                                width: '17vw',
                                height: '8vw',
                                borderRadius: '1.5vw',
                                border: '0.05vw solid rgb(75, 75, 75)',
                                backgroundImage: `linear-gradient(rgb(255, 255, 255, 0.1), rgb(10, 10, 10, 0.1)), url(${worker.image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                boxShadow: '0 10px 10px hsl(0deg 0% 0% / 0.2)',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'start',
                                

                              }}
                            >
                              <p style={{fontSize: '0.8vw', margin: '0.5vw', background: 'rgb(0, 0, 0, 0.2)', padding: '0.3vw 0.8vw', borderRadius: '5vw'}}>{worker.name}</p>
                            </div>
                            {/* <p style={{margin: 0, paddingTop: '1vw'}}>{worker.name}</p> */}
                            </HtmlTooltip>
                            {/* <p
                              style={{
                                margin: window.innerWidth <= 768 ? '1vw 0.5vw' : '0.5vw 0.2vw',
                                fontSize: window.innerWidth <= 768 ? '3vw' : '1vw',
                                color: 'rgb(255, 255, 255, 0.8)',
                                textAlign: 'left',
                                fontWeight: '400',
                              }}
                            >
                              {worker.name}
                            </p> */}
                          </div>
                        ))}
          
                        {workers.length === 0 && (
                          <div style={{ gridColumn: window.innerWidth <= 768 ? 'span 2' : 'repeat(5, 1fr)', textAlign: 'center', marginTop: '10px' }}>
                            <Button
                              onClick={() => navigate('/store')}
                              style={{
                                width: '100%',
                                height: window.innerWidth <= 768 ? '30vw' : '8vw',
                                borderRadius: window.innerWidth <= 768 ? '1vw' : '5px',
                                background: 'rgba(255, 255, 255, 0.2)',
                                border: '1px solid rgb(255, 255, 255, 0.15)',
                                color: 'rgb(255, 255, 255, 0.9)',
                                fontSize: window.innerWidth <= 768 ? '3vw' : '1vw',
                              }}
                            >
                              Go to Marketplace
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              <Fade in={isLoading}>
                <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', webkitBackdropFilter: 'blur(30px)', backdropFilter: 'blur(30px)', backgroundColor: 'rgb(255, 255, 255, 0.06)', position: 'absolute', zIndex: '1' }}>
                  <CircularProgress color="inherit" />
                </div>
              </Fade>
              
            </div>
            {!isLoading && <MyDigitalWorker/>}
            </>
          );
    // else
    // return (
    //     <div className={imgClass} style={{ textAlign: 'center', position: 'relative', height: '100%', display: 'flex', flexDirection: 'row' }}>
    //       <MenuBar style={{ width: '40%', fontSize: '24px' }} /> {/* Adjusted width and font size */}
    //       {!isLoading && (
    //         <div style={{ display: 'flex', flexDirection: 'column', width: '80%', height: '100%' }}>
    //           {/* home page */}
    //           <div
    //             className="home-page"
    //             style={{
    //               width: '100%',
    //               height: '100vh',
    //               display: 'flex',
    //               textAlign: 'left',
    //               justifyContent: 'center',
    //               flexDirection: window.innerWidth <= 768 ? 'column' : 'column',
    //             }}
    //           >
    //             <div
    //               style={{
    //                 margin: windowDimensions.width <= 768 ? '2vh 2vw' : '3vh 3vw',
    //                 display: 'flex',
    //                 alignItems: 'center',
    //                 backgroundImage: `url(${windowDimensions.height > windowDimensions.width ? HomeBG_mobile : HomeBG})`,
    //                 flex: 1,
    //                 backgroundSize: 'contain',
    //                 backgroundRepeat: 'no-repeat',
    //                 backgroundPosition: 'right',
    //                 flexDirection: windowDimensions.width <= 768 ? 'column' : 'row',
    //               }}
    //             >
    //             <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
    //                 <p style={{ marginBottom: window.innerWidth <= 768 ? '2vw' : '0vw', fontWeight: '300', fontSize: window.innerWidth <= 768 ? '4vw' : '1.3vw' }}>
    //                     Welcome to
    //                 </p>
    //                 <p style={{ fontSize: window.innerWidth <= 768 ? '6vw' : '3vw', letterSpacing: window.innerWidth <= 768 ? '0.8vw' : '0.4vw', fontWeight: '200' }}>
    //                     SPECIA PLATFORM
    //                 </p>
    //                 <p style={{ width: window.innerWidth <= 768 ? '90%' : '45%', fontWeight: '300', color: 'rgb(255, 255, 255, 0.75)', fontSize: window.innerWidth <= 768 ? '3vw' : '0.9vw', textAlign: 'center' }}>
    //                     Digital workers with advanced intelligence streamline industries by managing repetitive tasks, interacting with humans, and providing analytics. Unlike humans, they operate error-free and continuously, enhancing business efficiency.
    //                 </p>
    //             </div>
    //             </div>
    //             <div
    //               className="quick-access"
    //               style={{
    //                 width: '100%',
    //                 marginTop: window.innerWidth <= 768 ? '2vh' : '2vh',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 marginLeft: window.innerWidth <= 768 ? '2vw' : '3vw',
    //                 marginBottom: window.innerWidth <= 768 ? '2vh' : '2vw',
    //                 marginRight: window.innerWidth <= 768 ? '2vw' : '3vw',
    //               }}
    //             >
    //               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
    //                 <h2
    //                   style={{
    //                     fontSize: window.innerWidth <= 768 ? '4vw' : '1.2vw',
    //                     fontWeight: '500',
    //                     color: 'rgb(255, 255, 255, 0.9)',
    //                     marginBottom: window.innerWidth <= 768 ? '2vw' : '1vw',
    //                   }}
    //                 >
    //                   Quick Access
    //                 </h2>
    //               </div>
    //               <div
    //                 style={{
    //                   display: 'grid',
    //                   gridTemplateColumns: window.innerWidth <= 768 ? '1fr' : 'repeat(5, 1fr)', // Changed grid layout
    //                   gap: window.innerWidth <= 768 ? '10px' : '20px',
    //                   marginTop: '10px',
    //                 }}
    //               >
    //                 {workers.slice(0, 5).map((worker, index) => (
    //                   <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: window.innerWidth <= 768 ? '0px 0.2vw 0px 0px' : '0px 0.5vw 0px 0px' }}>
    //                     <Button
    //                       onClick={() => navigate(worker.path)}
    //                       style={{
    //                         width: '100%',
    //                         height: window.innerWidth <= 768 ? '30vw' : '8vw',
    //                         borderRadius: window.innerWidth <= 768 ? '1vw' : '0.7vw',
    //                         backgroundImage: `url(${worker.image})`,
    //                         backgroundSize: 'cover',
    //                         backgroundPosition: 'center',
    //                         border: '1px solid rgb(255, 255, 255, 0.15)',
    //                       }}
    //                     ></Button>
    //                     <p
    //                       style={{
    //                         margin: window.innerWidth <= 768 ? '1vw 0.5vw' : '0.5vw 0.2vw',
    //                         fontSize: window.innerWidth <= 768 ? '3vw' : '1vw',
    //                         color: 'rgb(255, 255, 255, 0.8)',
    //                         textAlign: 'left',
    //                         fontWeight: '400',
    //                       }}
    //                     >
    //                       {worker.name}
    //                     </p>
    //                   </div>
    //                 ))}
      
    //                 {workers.length === 0 && (
    //                   <div style={{ gridColumn: '1', textAlign: 'center', marginTop: '10px' }}>
    //                     <Button
    //                       onClick={() => navigate('/store')}
    //                       style={{
    //                         width: '100%',
    //                         height: window.innerWidth <= 768 ? '30vw' : '8vw',
    //                         borderRadius: window.innerWidth <= 768 ? '1vw' : '5px',
    //                         background: 'rgba(255, 255, 255, 0.2)',
    //                         border: '1px solid rgb(255, 255, 255, 0.15)',
    //                         color: 'rgb(255, 255, 255, 0.9)',
    //                         fontSize: window.innerWidth <= 768 ? '3vw' : '1vw',
    //                       }}
    //                     >
    //                       Go to Marketplace
    //                     </Button>
    //                   </div>
    //                 )}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //       {isLoading && (
    //         <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //           <CircularProgress color="inherit" />
    //         </div>
    //       )}
    //     </div>
      // );
}
      
