import TaxAgentNumberSettings from './TaxAgentNumberSettings';
import SSIDSettings from './SSIDSettings';
import UsersConfig from './UsersConfig';
import React, { useState } from 'react';

export default (props) => {

    const [currentComponent, setCurrentComponent] = useState('Users');

    const [currentTitle, setCurrentTitle ] = useState('Manage Users')

    const handleClick = (componentName, titleName) => {
        setCurrentComponent(componentName);
        setCurrentTitle(titleName)
    };

    return (
        <div className={props.imgClass} style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row', overflow:'hidden'}}>
            {/* <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'2vh', height:'100vh', overflow:'hidden'}}> */}
                <div style={{display:'flex', flexDirection:'row', width:'100%', height:'100vh', paddingTop:'2.5vw', paddingLeft: '1.5vw', overflow:'hidden'}}>
                    {/* left */}
                    <div style={{ width: '50%', textAlign: 'left', flex: 3, height: '90vh', overflowY: 'auto'}}>
                        <h4 style={{ fontSize: '1.3vw' , fontWeight: '400', marginBottom: '1.9vw', marginLeft: '1vw', color: 'rgb(255, 255, 255, 0.7)'}}>Settings</h4>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , paddingRight: '5vw'}}>
                            <div
                                onClick={() => handleClick('Users', 'Manage Users')}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1.5vw',
                                    padding: '1vw 1vw',
                                    borderRadius: '0.4vw',
                                    width: '100%',
                                    background: currentComponent === 'Users' ? 'rgb(220, 220, 220)' : 'transparent',
                                    color: currentComponent === 'Users' ? 'rgb(50, 50, 50)' : 'white',
                                }}
                            >
                                <h3 style={{ fontSize: '1.3vw' , fontWeight: '500'}}>Manage Users</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'Users' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Control access levels and oversee user activities.</p>
                            </div>
                            <div
                                onClick={() => handleClick('TaxAgentNumberSettings', 'Tax Agent Number')}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1.5vw',
                                    padding: '1vw 1vw',
                                    borderRadius: '0.4vw',
                                    width: '100%',
                                    background: currentComponent === 'TaxAgentNumberSettings' ? 'rgb(220, 220, 220)' : 'transparent',
                                    color: currentComponent === 'TaxAgentNumberSettings' ? 'rgb(50, 50, 50)' : 'white',
                                }}
                            >
                                <h3 style={{ fontSize: '1.3vw' , fontWeight: '500'}}>Tax Agent Number</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'TaxAgentNumberSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Update or verify your official tax identification details.</p>
                            </div>
                            <div
                                onClick={() => handleClick('SSIDSettings', 'ATO Connection')}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    marginBottom: '1.5vw',
                                    padding: '1vw 1vw',
                                    borderRadius: '0.4vw',
                                    width: '100%',
                                    background: currentComponent === 'SSIDSettings' ? 'rgb(220, 220, 220)' : 'transparent',
                                    color: currentComponent === 'SSIDSettings' ? 'rgb(50, 50, 50)' : 'white',
                                }}
                            >
                                <h3 style={{ fontSize: '1.3vw' , fontWeight: '500'}}>ATO Connection</h3>
                                <p style={{ fontSize: '0.8vw', margin: 0, color: currentComponent === 'SSIDSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Configure and test your link to the Australian Tax Office systems.</p>
                            </div>
                        </div>
                    </div>

                    {/* right */}
                    <div style={{ width: '50%', flex: 4, textAlign: 'left', paddingRight: '2vw', height: '90vh', overflowY: 'auto'}}>
                        <h4 style={{ fontSize: '1.3vw' , fontWeight: '400', marginBottom: '1.5vw', color: 'rgb(255, 255, 255, 0.7)'}}>{currentTitle}</h4>
                        {currentComponent === 'TaxAgentNumberSettings' && <TaxAgentNumberSettings />}
                        {currentComponent === 'SSIDSettings' && <SSIDSettings />}
                        {currentComponent === 'Users' && <UsersConfig />}
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}

