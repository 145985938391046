import React from "react";
import { 
    useEffect, 
    useState,
} from "react";
import { 
    Outlet,
    useNavigate, 
    // useSearchParams,
} from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { CircularProgress } from "@mui/material";



export default function SetUpRequired(props) {

    const auth = useAuth()

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const [currentStep, setCurrentStep ] = useState()


    useEffect(() => {
        setIsLoading(true)
        fetch(`${window.origin}/api/reporter/setup/status`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(response => {
          if(response.status === 403){
            auth.signinRedirect()
            throw new Error('Session expired');
          }else{
            return response.json()
          }
        }
        ).then(data => {
          console.log(data)
          if(data.currentStep !== 'complete'){
            navigate('/mydigitalworkers/Scout/setup', {state: data})
          }else{
            setCurrentStep('complete')
            setIsLoading(false)
          }
        })
    }, []);

    // useEffect(() => {
    //     if(!auth.user?.profile.accountant.ssid || !auth.user?.profile.accountant.verified ){
    //         navigate('/setup')
    //     }
    // })


    


    return (
        <React.Fragment>
            {
                currentStep == 'complete'? <>{props.children}</>: <div style={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <CircularProgress  color="inherit"/>
                </div>
            }
        </React.Fragment>
    );
}