import { Button, CircularProgress, createTheme, List, ListItem, ListItemText, ListSubheader, styled, ThemeProvider } from '@mui/material';
import { React, useContext, useEffect, useState } from 'react'
import { ButtonGroup, Offcanvas, ToggleButton } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';

import LoadingButton from '@mui/lab/LoadingButton';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ScanTemplateModal from './ScanTemplateModal';
import { SnackbarContext } from '../../../App';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  borderRadius: '10vw'
});


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default ({show, onClose, tabId, template, customName, fetchDataHandler}) => {

    console.log(customName)

    const auth = useAuth()

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const [currentCustomeName, setCurrentCustomeName] = useState(customName);

    const [sessionExpired, setSessionExpired] = useState(false)

    const [templateDetails, setTemplateDetails] = useState([])

    const [scanTemnplateShow, setScanTemplateShow] = useState(false)


    const [isLoading, setIsLoading] = useState(false)

    const [fileUploaded, setFileUploaded] = useState()

    useEffect(() => {
        setFileUploaded({name: template.fileName})
        setCurrentCustomeName(template.name)
    }, [template])


    function saveConfigHandler(){

        // Create FormData object
        const formData = new FormData();

        // Append file to the FormData
        if (fileUploaded.size){formData.append('file', fileUploaded);}

        // Append JSON data to the FormData
        const jsonData = JSON.stringify(templateDetails)

        formData.append('data', jsonData);

        formData.append('name', currentCustomeName);

        if (fileUploaded.name) {formData.append('fileName', fileUploaded.name);}
        

        fetch(`${window.origin}/api/creator/template/${tabId}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: formData,
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }else{
                    throw new Error('Unknown Error');
                }
            } else {
                setSnackBarMessage('Template details have been saved')
                setSnackBarShow(true)
                fetchDataHandler()
            }
        }).catch(error => {
            console.error('Error editing template:', error);
            setSnackBarMessage('Unknown error occurred. Please try again')
            setSnackBarShow(true)
        });
        // onClose()
    }

    function fileUploadHandler(file){
        setFileUploaded(file)

        console.log(file)

        const formData = new FormData();

        formData.append('file', file);

        fetch(`${window.origin}/api/creator/scan-template`, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                } else {
                    return Promise.reject(`Failed to edit client. Status: ${response.status}`);
                }
            } else {
                return response.json()
            }
        }).then(data => {
            setTemplateDetails(data)
            setSnackBarMessage('File uploaded')
            setSnackBarShow(true)
        })
        .catch(error => {
            console.error('Error editing client:', error);
        });

    }

    function deleteHandler(){
        fetch(`${window.origin}/api/creator/template/${tabId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                } else {
                    return Promise.reject(`Failed to edit client. Status: ${response.status}`);
                }
            } else {
                setSnackBarMessage('Template Deleted')
                setSnackBarShow(true)
                fetchDataHandler()
                onClose()
            }
        })
        .catch(error => {
            console.error('Error editing client:', error);
            setSnackBarMessage('Unknown error occurred. Please try again later')
            setSnackBarShow(true)
            fetchDataHandler()
            onClose()
        });
    }

    function closeHandler(){
        onClose()
    }

    useEffect(() => {
        fetch(`${window.origin}/api/creator/template/${tabId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`,
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                } else {
                    return Promise.reject(`Failed to edit client. Status: ${response.status}`);
                }
            } else {
                return response.json()
            }
        }).then(data => {
            setTemplateDetails(data)
            console.log(data)
        })
        .catch(error => {
            console.error('Error editing client:', error);
        });
    }, [show])
    
    
    return (
        <>
            <ScanTemplateModal show={scanTemnplateShow} onClose={() => {setScanTemplateShow(false)}}/>
            <SessionExpiredModal show={sessionExpired}/>
            <Offcanvas show={show} onHide={closeHandler} placement="end" style={{width: '23vw', backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(50px)', '-webkit-backdrop-filter': 'blur(50px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1vw', paddingRight: '1vw', paddingTop: '1vw', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '2vw'}}>
                <div style={{flex: 1}}>
                    <Offcanvas.Header style={{padding: '1vw'}}>
                    <Offcanvas.Title style={{fontSize: '1.3vw'}}>{currentCustomeName}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{paddingTop: '2vw', height: '100%', padding: '1vw'}}>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Template Name</p>
                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This name will displayed as the name of the template when you create workpapers.</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="text" placeholder="Name" value={currentCustomeName} onChange={(e) => setCurrentCustomeName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Template Details</p>
                            <List
                                sx={{
                                    width: '100%',
                                    maxWidth: '100%',
                                    borderRadius: '0.4vw',
                                    border: '1px solid rgb(255, 255, 255, 0.1)',
                                    bgcolor: 'rgb(255, 255, 255, 0.05)',
                                    position: 'relative',
                                    overflow: 'auto',
                                    maxHeight: '20vh',
                                    '& ul': { padding: 0 },
                                    marginBottom: '1vw'
                                }}
                                subheader={<li />}
                                >

                                    {templateDetails.map((item, index) => (
                                    <ListItem key={index} style={{padding: '0.9vw', paddingBottom: 0}}>
                                        <p style={{fontSize: '0.8vw', margin: 0}}>{`Found tag <${item.tag}/> at position ${item.position} in sheet ${item.sheet}`}</p>
                                    </ListItem>
                                    ))}
                            </List>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                >
                                Upload files
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={(event) => fileUploadHandler(event.target.files[0])}
                                    accept=".xlsx"
                                    single
                                />
                            </Button>
                            <p>{fileUploaded?.name}</p>
                        </Form.Group>

                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Delete Template</p>
                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This template will be deleted and it is irreversable.</p>
                            <Button onClick={deleteHandler} style={{textTransform: 'none', borderRadius: '10vw', fontSize: '0.8vw', padding: '0.25vw 1vw', lineHeight: '1.5vw'}} variant="contained" color="error">
                                Delete
                            </Button>
                        </Form.Group>
                       
                        
                        
                    </Offcanvas.Body>
                    {/* { isLoading && <>
                        <div style={{textAlign: 'center', paddingTop: '35vh'}}>
                            <CircularProgress color="inherit" />
                        </div>
                    </>} */}
                </div>
                <div style={{textAlign: 'right', paddingBottom: '1.5vw', paddingRight: '1vw'}}>

                    <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1.25vw', borderRadius: '1.5vw', textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)', backgroundColor: 'rgb(0, 0, 0, 0.1)'}}  onClick={closeHandler}>
                        Close
                    </TextButton>
                    <ThemeProvider theme={darkTheme}>
                        <LoadingButton onClick={saveConfigHandler} loading={isLoading} variant='contained' style={{ fontSize: '0.9vw', color: isLoading? 'rgb(255, 255, 255, 0)':'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}}>
                            <span>Save</span>
                        </LoadingButton>
                    </ThemeProvider>
                </div>

                
            </Offcanvas>

        </>
            
    )
}