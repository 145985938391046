import { useAuth } from 'react-oidc-context';
import { useContext, useState } from 'react';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Button, ButtonBase } from '@mui/material';
import MessageModal from '../../../utils/MessageModal';
import { SnackbarContext } from '../../../App';
import { Form } from 'react-bootstrap';

export default (props) => {

    const auth = useAuth()
    const [tan, setTan] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const [sessionExpired, setSessionExpired] = useState(false)

    const [messageModalShow, setMessageModalShow] = useState(false)

    function onConfirm(){

        if(tan.length === 0){
            setSnackBarMessage('Tax agent number cannot be empty')
            setSnackBarShow(true)
            return
        }

        if(!isAllDigits(tan)){
            setSnackBarMessage('The provided tax agent number is invalid')
            setSnackBarShow(true)
            return
        }

        setIsLoading(true)
        fetch(`${window.origin}/api/setup/tan`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                tan: tan
            })
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }else{
              return props.onNext('ato')
              setIsLoading(false)
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    function isAllDigits(str) {
        return /^\d+$/.test(str) && str.length == 8;
    }

    return (
        <>  
            <MessageModal show={messageModalShow} onClose={() => {setMessageModalShow(false)}} message={'Invalid Tax Agent Number'} description={'The provided tax agent number is invalid. Please try again!'} />
            <SessionExpiredModal show={sessionExpired}/>
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Tax Agent Number</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please provide your tax agent number to proceed</p>
            </header>
            {/* <input
                type="text"
                // value={ABN}
                placeholder='Tax Agent Number'
                value={tan}
                onChange={(e => setTan(e.target.value))}
                style={{
                    width: '100%',
                    borderRadius: '0.5vw',
                    background: 'rgb(255, 255, 255, 0.07)',
                    color: 'white',
                    border: 'none',
                    padding: '0.25vw 1vw',
                    margin: '0 auto',
                    marginBottom: '1.3vw',
                    height: '3vw',
                    fontSize: '0.85vw'
                }}
                disabled={isLoading}
            /> */}

                <Form.Control
                    type="text"
                    value={tan}
                    onChange={(e => setTan(e.target.value))}
                    placeholder='Tax Agent Number'
                    style={{
                        width: '100%',
                        borderRadius: '0.5vw',
                        backgroundColor: 'rgb(255, 255, 255, 0.05)',
                        fontSize: '0.85vw',
                        color: 'white',
                        border: 'none',
                        padding: '0.25vw 1vw',
                        margin: '1vw auto',
                        height: '3vw',
                        marginBottom: '1.5vw'
                    }}
                    disabled={isLoading}
                  />
                        
            {/* <ButtonBase
                onClick={onConfirm}
                style={{
                    borderRadius: '4px',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 20px',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600'
                }}
                disabled={isLoading}
            >
                {!isLoading && <><p style={{lineHeight: '1.6rem', margin: 0}}>Confirm</p></>}
                {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6rem'}}>
                    <div class="spinner-border" role="status" style={{width: '1.6rem', height: '1.6rem'}}>
                        <span class="sr-only">Loading...</span>
                    </div>
                    </div>}
            </ButtonBase> */}

            <Button disabled={isLoading} onClick={onConfirm} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 1)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Confirm</Button>
            </>
    )
}
