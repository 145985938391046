
import { Button, ButtonGroup } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';

import Form from 'react-bootstrap/Form';

import CircleSteps from './CircleSteps';

import MenuBar from '../../../utils/MenuBar';
import ProvideTaxAgentNumber from './ProvideTaxAgentNumber';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';

export default (props) => {

    const navigate = useNavigate()

    const [financialSoftware, setFinancialSoftware] = useState('xero')

    const [username, setUsername] = useState('')

    const [password, setPassword] = useState('')

    const [setupKey, setSetupKey] = useState('')

    const auth = useAuth()

    function onConfirm(){
        let body = {
            accounts: [
                {
                    username: username,
                    password: password,
                    setupKey: setupKey,
                    type: financialSoftware
                }
            ]
        }
        fetch(`${window.origin}/api/creator/setup/integration`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify(body)
        }).then(response => {
            if(response.status === 403){
              auth.signinRedirect()
              throw new Error('Session expired');
            }else{
              navigate('/mydigitalworkers/gayatri/workpapers')
            }
        })

        console.log(body)
    }

    return (
        <>
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Financial Software integration</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please provide your XERO or MYOB credentials or both</p>
            </header>
            <ButtonGroup className="me-2" aria-label="First group" style={{marginBottom: '2.5vh'}}>
                <Button onClick={() => setFinancialSoftware('xero')} style={{backgroundColor: financialSoftware == 'xero'? 'rgb(255, 255, 255, 0.9)': 'transparent', borderColor: 'rgb(255, 255, 255, 0.8)', color: financialSoftware == 'xero'? 'rgb(0, 0, 0, 0.8)': 'rgb(255, 255, 255, 0.8)'}}>XERO</Button> 
                <Button onClick={() => setFinancialSoftware('myob')} style={{backgroundColor: financialSoftware == 'myob'? 'rgb(255, 255, 255, 0.9)': 'transparent', borderColor: 'rgb(255, 255, 255, 0.8)', color: financialSoftware == 'myob'? 'rgb(0, 0, 0, 0.8)': 'rgb(255, 255, 255, 0.8)'}}>MYOB</Button> 
            </ButtonGroup>
            <input
                type="text"
                // value={ABN}
                placeholder='Username'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{
                    width: '100%',
                    borderRadius: '4px',
                    background: 'rgb(255, 255, 255, 0.1)',
                    color: 'white',
                    border: 'none',
                    padding: '5px 15px',
                    margin: '0 auto',
                    marginBottom: '1.3vw',
                    height: '3vw'
                }}
            />
            <input
                type="password"
                // value={ABN}
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{
                    width: '100%',
                    borderRadius: '4px',
                    background: 'rgb(255, 255, 255, 0.1)',
                    color: 'white',
                    border: 'none',
                    padding: '5px 15px',
                    margin: '0 auto',
                    marginBottom: '1.3vw',
                    height: '3vw'
                }}
            />
            <input
                type="password"
                // value={ABN}
                placeholder='MFA Setup Key (Optional)'
                value={setupKey}
                onChange={(e) => setSetupKey(e.target.value)}
                style={{
                    width: '100%',
                    borderRadius: '4px',
                    background: 'rgb(255, 255, 255, 0.1)',
                    color: 'white',
                    border: 'none',
                    padding: '5px 15px',
                    margin: '0 auto',
                    marginBottom: '1.3vw',
                    height: '3vw'
                }}
            />
            
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.7vw', margin: 0, marginBottom: '1vw'}}>*Only provide Setup Key if Multi-factor Authentication is enabled for this account</p>
                        
            <button
                onClick={onConfirm}
                style={{
                    borderRadius: '4px',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 20px',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600'
                }}
            >
                Confirm
            </button>

        </>
    )
}
