import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SpeciaLogoWithName from '../../resources/SpeciaLogoWithName.png'

import SpeciaLogo2 from '../../resources/circle specia 2.png'
import { useAuth } from 'react-oidc-context';
import { Form } from 'react-bootstrap';
import { Backdrop, Button, CircularProgress, ThemeProvider, ToggleButton, ToggleButtonGroup, createTheme, styled } from '@mui/material';






export default function SetupPage() {
    const [companyName, setCompanyName] = useState('');
    const [ABN, setABN] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const [userType, setUserType] = useState('owner')

    const auth = useAuth()

const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
    }));

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    const [alignment, setAlignment] = React.useState('left');

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };



  async function nextButtonHandler(){

        setIsLoading(true)

        setTimeout(() => {
            setIsLoading(false)
            if(userType == 'owner'){
                navigate('/setup/owner')
              }else if (userType == 'employee'){
                navigate('/setup/emp')
              }
        }, 300)

  }

    return (
    <div style={{display:'flex', flexDirection:'column', width:'100vw', height:'100vh'}}>

        <div style={{display:'flex', flexDirection:'row', padding: '5vh 15vw'}}>
            <div style={{flex: 1}}>
                <img src={SpeciaLogoWithName} width="40%" style={{paddingBottom: '12vh'}}/>
                <img src={SpeciaLogo2} width="90%" style={{paddingBottom: '15vh'}}/> 
            </div>
            


            <div style={{flex: 1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                <div style={{paddingLeft: '10vw'}}>
                    <header>
                        <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                            <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Specia Platform,
                        </p>
                        <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Tell us about your role</h1>
                        <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0}}>Please select the option best describes your role in your organization.</p>
                    </header>

                    <ThemeProvider theme={darkTheme}>

                        <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            // aria-label="text alignment"
                            style={{display: 'flex', flexDirection: 'column'}}
                            >

                            <ToggleButton onClick={() => {setUserType('owner')}}  style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-end', padding: '1.2vw', border: 'none', borderRadius: '0.5vw', backgroundColor: userType == 'owner'? 'rgb(255, 255, 255, 0.9)':'rgb(255, 255, 255, 0.05)', marginBottom: '1vw', marginTop: '1vw'}}>
                                <div style={{flex: 1, textAlign: 'left', textTransform: 'none', color: 'rgb(255, 255, 255, 0.8)'}}>
                                    <p style={{marginBottom: '0.3vw', fontWeight: '600', fontSize: '1.1vw', color: userType == 'owner'? 'rgb(0, 0, 0, 0.8)':'rgb(255, 255, 255, 0.8)'}}>Owner</p>
                                    <p style={{marginBottom: '0', fontSize: '0.8vw', fontWeight: '400', color: userType == 'owner'? 'rgb(0, 0, 0, 0.8)':'rgb(255, 255, 255, 0.8)'}}>Select this option if you will be responsible for managing Specia Platform for your organization.</p>
                                </div>
                                <div>
                                    <Form.Check
                                        // style={{height: '1vw', width: '1vw'}}
                                        style={{cursor: 'pointer', paddingLeft: '1vw'}}
                                        checked={userType == 'owner'}
                                        type='checkbox'
                                    />
                                </div>
                            </ToggleButton>
                            <ToggleButton onClick={() => {setUserType('employee')}}  style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '1vw', border: 'none', borderRadius: '0.5vw', backgroundColor: userType == 'employee'? 'rgb(255, 255, 255, 0.9)':'rgb(255, 255, 255, 0.05)', marginBottom: '1.5vw'}}>
                                <div style={{flex: 1, textAlign: 'left', textTransform: 'none', color: 'rgb(255, 255, 255, 0.8)'}}>
                                    <p style={{marginBottom: '0.3vw', fontWeight: '600', fontSize: '1.1vw', color: userType == 'employee'? 'rgb(0, 0, 0, 0.8)':'rgb(255, 255, 255, 0.8)'}}>Employee</p>
                                    <p style={{marginBottom: '0', fontSize: '0.8vw', fontWeight: '400',  color: userType == 'employee'? 'rgb(0, 0, 0, 0.8)':'rgb(255, 255, 255, 0.8)'}}>Select this option if you will be using Specia Platform services to perform your task.</p>
                                </div>
                                <div style={{alignContent: 'flex-start'}}>
                                    <Form.Check
                                    style={{cursor: 'pointer', paddingLeft: '1vw'}}
                                        checked={userType == 'employee'}
                                        type='checkbox'
                                    />
                                </div>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </ThemeProvider>
                  
              
              <Button onClick={nextButtonHandler} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '0vw'}}>Continue</Button>
              <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    onClick={() => {setIsLoading(false)}}
                    >
                    <CircularProgress color="inherit" />
                </Backdrop>
                
              
              <p onClick={() => auth.signoutRedirect()} style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.7)', marginTop: '1vw', cursor: 'pointer'}}><span dangerouslySetInnerHTML={{ __html: '&larr;' }} style={{}} /> Back to Login</p>
              </div>
            </div>

        </div>
    </div>
    );
}
