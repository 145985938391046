import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import SessionExpiredModal from '../../utils/SessionExpiredModal';

function CompanySetting() {
    

    const [name, setName] = useState('')

    const [abn, setABN] = useState('')

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)

    const [sessionExpired, setSessionExpired] = useState(false)


    const auth = useAuth()

    useEffect(()=> {
        console.log(auth.user?.profile.accountant.ssid)
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/company`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response.json()
            }).then(data => {
                setName(data.name)
                setABN(data.abn)
                setIsLoading(false)
            }).catch((error) => {
                console.error('Failed to fetch workers:', error);
            });
        }
    }, [auth.user?.profile.accountant.ssid])

    return (
        <>
        <SessionExpiredModal show={sessionExpired}/>
        <p style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight:'300'}}>Adjust company details and operational preferences.</p>
        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.05)', padding: '1vw 1.5vw', borderRadius: '0.6vw', marginTop: '1.5vw', marginBottom: '1.5vw'}}>
            <div style={{flex: 1, fontWeight: '600', fontSize: '1vw'}}>
                <p style={{margin: 0}}>Company Name</p>
            </div>
            <div style={{flex: 1}}>
                <p style={{textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '1vw'}}>{name}</p>
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.05)', padding: '1vw 1.5vw', borderRadius: '0.6vw', marginTop: '1.5vw', marginBottom: '1.5vw'}}>
            <div style={{flex: 1, fontWeight: '600', fontSize: '1vw'}}>
                <p style={{margin: 0}}>Australian Business Number</p>
            </div>
            <div style={{flex: 1}}>
                <p style={{textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '1vw'}}>{abn}</p>
            </div>
        </div>
        </>
    );
}

export default CompanySetting;
