import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useAuth } from "react-oidc-context"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import SpeciaLogoWithName from '../../resources/SpeciaLogoWithName.png'

import SpeciaLogo2 from '../../resources/circle specia 2.png'

import jwt from 'jwt-decode'
import LoadingModal from "../../utils/LoadingModal"
import SessionExpiredModal from "../../utils/SessionExpiredModal"

export default (props) => {

    const navigate = useNavigate()

    const search = useLocation().search

    const queryParams = new URLSearchParams(search)

    const token = queryParams.get("token")

    const decodedToken = jwt(token)

    const [ loadingMessage, setLoadingMessage ] = useState('')

    const [ isLoading, setIsLoading ] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)


    const auth = useAuth()


    function acceptInvite(){
        if(auth.isAuthenticated){
            auth.signoutSilent()
        }
        setIsLoading(true)
        setLoadingMessage('Accepting Invite ...')
        fetch(`${window.origin}/api/users/accept-invite`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }).then(resp => {
            if(resp.status === 200){
                setIsLoading(false)
                navigate('/invite/accepted')
            }
        }).catch((error) => {
            console.error('Unknown error occured!');
        });
    }

    // useEffect(() => {
    //     if(auth.isAuthenticated){
    //         auth.signoutSilent()
    //     }

    //     fetch(`${window.origin}/api/users/accept-invite`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         }
    //     }).then(resp => resp.json()).then(data => {
    //         console.log(data)
    //     })
        
    // }, []);



    return (
        <>  
            <SessionExpiredModal show={sessionExpired}/>
            <div style={{display:'flex', flexDirection:'column', width:'100vw', height:'100vh'}}>

                <div style={{display:'flex', flexDirection:'row', padding: '5vh 15vw'}}>
                    <div style={{flex: 1}}>
                        <img src={SpeciaLogoWithName} width="40%" style={{paddingBottom: '12vh'}}/>
                        <img src={SpeciaLogo2} width="90%" style={{paddingBottom: '15vh'}}/> 
                    </div>
                    


                    <div style={{flex: 1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <div style={{paddingLeft: '10vw'}}>
                            <header>
                                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Specia Platform,
                                </p>
                                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Accept Your Invite</h1>
                                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw'}}>You have have been invited to join {decodedToken.compName} with the role {decodedToken.role}</p>
                            </header>
                        
                    <button
                        onClick={acceptInvite}
                        style={{
                            borderRadius: '4px',
                            background: '#FE475B',
                            border: 'none',
                            color: 'white',
                            padding: '0.7vw 20px',
                            width:'100%',
                            cursor: 'pointer',
                            fontWeight: '600'
                        }}
                    >
                        Accept
                    </button>
                    </div>
                    </div>

                </div>
            </div>
            <LoadingModal show={isLoading} message={loadingMessage}/>
            
            
        

        </>
    )
}