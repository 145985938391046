// import logo from './logo.svg';
import './App.css';
import React, {
  useState,
  useEffect,
  lazy,
  createContext,
} from 'react';
// import {
//   Component,
// } from 'react';

import {
  // BrowserRouter,
  Routes,
  Route,
  // useLocation,
} from "react-router-dom";

import ProtectedRoute from './auth/ProtectedRoute';
import ScoutLayout from './pages/DigitalAssistants/Layout';


import SignUpJoin from './auth/Invite/SignUpJoin';
import Invite from './pages/Users/Invite';
import AcceptInvite from './pages/Users/AcceptInvite';
import SpeciaHome from './pages/SpeciaHome/SpeciaHome';
import MyDigitalWorker from './pages/MyDigitalWoker/MyDigitalWorker';

// set up account
import SetupPage from './pages/SetupSpecia/SetupPage';

import EmployeeSetup from './pages/SetupSpecia/EmployeeSetup';

import CompanySetup from './pages/SetupSpecia/CompanySetup';

// store
import Store from './pages/Store/Store';
import Accounting from './pages/Store/Accounting';
import DetailStore from './pages/Store/DetailStore';

// set up ato
import GayatriSetup from './pages/Gayatri/SetUp/GayatriSetup';

import ScoutSetup from './pages/DigitalAssistants/InitialSetUp/ScoutSetup';


// gayatri setting
import GayatriSettings from './pages/Gayatri/Settings/GayatriSettings';


// manage clients
import ManageClients from './pages/Gayatri/ManageClients/ManageClients';

// manage Users
import ManageUsers from './pages/Gayatri/ManageUsers/ManageUsers';

// workpaper
import Workpaper from './pages/Gayatri/Workpaper/Workpaper';
import GenerateWorkpaper from './pages/Gayatri/Workpaper/GenerateWorkpaper';

// view
import JournalEntry from './pages/Gayatri/JournalEntry/JournalEntry';
import SubmitJournalEntry from './pages/Gayatri/JournalEntry/SubmitJournalEntry';

// setting
import Setting from './pages/Setting/Settings';
import ScoutSettings from './pages/DigitalAssistants/Settings/ScoutSettings';
import GaytriLayout from './pages/Gayatri/Layout';
import SetUpRequired from './pages/Gayatri/SetUpRequired';
import Landing from './pages/Landing/Landing';
import SubscriptionRequired from './pages/Gayatri/Subscription/SubscriptionRequired';

import SubscriptionResult from './pages/Gayatri/Subscription/SubscriptionResult';
import AddClients from './pages/Gayatri/ManageClients/AddClients';

import Cookies from 'universal-cookie';
import MenuBarNew from './utils/MenuBarNew';
import { Snackbar } from '@mui/material';

const DigitalAssistants = lazy(() => import('./pages/DigitalAssistants/DigitalAssistants'));
const Registration = lazy(() => import('./auth/registration/Registration'));
const Login2 = lazy(() => import('./auth/login/Login2'));
const Logout = lazy(() => import('./auth/login/Logout'));
const Home = lazy(() => import('./pages/Home/Home'));
const SelectTier = lazy(() => import('./pages/DigitalAssistants/SetUp/Reporter/SelectTier'));
const ClientSetUp = lazy(() => import('./pages/DigitalAssistants/SetUp/Reporter/ClientSetUp'));
const FinishedScreen = lazy(() => import('./pages/DigitalAssistants/SetUp/Reporter/FinishedScreen'));
const Reporter = lazy(() => import('./pages/DigitalAssistants/Reporter'));
const ReporterCreateRequest = lazy(() => import('./pages/DigitalAssistants/CreateRequest/ATOReporter/ReporterCreateRequest'));
const ReporterViewReports = lazy(() => import('./pages/DigitalAssistants/ViewReports/ATOReporter/ReporterViewReports'));
const ReporterViewRequests = lazy(() => import('./pages/DigitalAssistants/ViewRequests/ATOReporter/ReporterViewRequests'));
const DisplayReports = lazy(() => import('./pages/DigitalAssistants/ViewReports/ATOReporter/DisplayReports'));
const AccountantDetails = lazy(() => import('./auth/updateinfo/AccountantDetails'));
const InfoUpdated = lazy(() => import('./auth/updateinfo/InfoUpdated'));



const cookies = new Cookies();

export const SnackbarContext = createContext(null);


cookies.set('currentPath', window.location.pathname, { path: '/' });


function App() {

  const [snackBarShow, setSnackBarShow] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
      return;
      }

      setSnackBarShow(false);
  };

  console.log(window.location.pathname )
  const [menuBarShow, setMenuBarShow] = useState(false)

  const menuBarOpenHandler = () => {
    setMenuBarShow(true)
  }

  const menuBarCloseHandler = () => {
    setMenuBarShow(false)
  }


  return (
    <>
    <SnackbarContext.Provider value={{ snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage }}>
      <Routes>
        <Route path="/landing" element={<Landing />} />
        <Route path="/auth/login" element={<Login2 />} />
        <Route path="/auth/logout" element={<Logout />} />
        <Route path="/auth/register" element={<Registration />} />
        <Route path="/invite/accepted" element={<AcceptInvite />} />
        <Route path="/invite/existing" element={<Invite />} />
        <Route path="/invite/new" element={<SignUpJoin />} />

        <Route path="/" element={<ProtectedRoute/>}>
            <Route index element={<SpeciaHome menuBarOpen={menuBarOpenHandler} menuBarClose={menuBarCloseHandler} />} />
            <Route path="MyDigitalWorker" element={<MyDigitalWorker menuBarOpen={menuBarOpenHandler} />} />

            {/* set up page */}
            <Route path="setup" element={<SetupPage />} />
            <Route path="setup/emp" element={<EmployeeSetup/>} />
            <Route path="setup/owner" element={<CompanySetup/>} />

            {/* store */}
            <Route path="store" element={<Store />} />
            <Route path="store/accounting" element={<Accounting />}/>
            <Route path="store/detail/:id" element={<DetailStore />}/>

            {/* setting */}
            <Route path="setting" element={<Setting  menuBarOpen={menuBarOpenHandler}  />}/>

            <Route path="mydigitalworkers/gayatri/subscription" element={<SubscriptionRequired />}></Route>

            <Route path="/mydigitalworkers/gayatri/subscription/resolved" element={<SubscriptionResult />} />

            <Route path="mydigitalworkers/gayatri/setup" element={<GayatriSetup />}></Route>



            <Route path="mydigitalworkers/gayatri" element={<GaytriLayout />}>
              {/* manage clients */}
              <Route path="clients" element={<ManageClients />}></Route>
              <Route path="clients/new" element={<AddClients />}></Route>

              {/* generate workpaper */}
              <Route path="workpapers" element={<Workpaper />}></Route>
              {/* <Route path="workpapers/create" element={<GenerateWorkpaper />}></Route> */}

              {/* view */}
              {/* <Route path="/industries/accounting/gayatri/JournalEntry" element={<JournalEntry />}></Route>
              <Route path="/industries/accounting/gayatri/JournalEntry/SubmitJournalEntry" element={<SubmitJournalEntry />}></Route> */}

              {/* gaytri setting */}
              <Route path="settings" element={<GayatriSettings />}></Route>

            </Route>

            <Route path="mydigitalworkers/Scout/setup" element={<ScoutSetup />}></Route>

            <Route path="mydigitalworkers/Scout" element={<ScoutLayout />}>
                <Route path="home" element={<Home />} />
                <Route path="clients" element={<ClientSetUp />} />
                <Route path="setup/success" element={<FinishedScreen />} />
                <Route path="requests/create" element={<ReporterCreateRequest />} />
                <Route path="requests" element={<ReporterViewRequests />} />
                <Route path="reports" element={<ReporterViewReports />} />
                <Route path="reports/view" element={<DisplayReports />} />
                <Route path="settings" element={<ScoutSettings />} />
            </Route>
          </Route>
        </Routes>
        <div>
          <Snackbar
              open={snackBarShow}
              autoHideDuration={6000}
              onClose={handleClose}
              message={snackBarMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          />
        </div>
      </SnackbarContext.Provider>
      <MenuBarNew show={menuBarShow} closeHandler={() => setMenuBarShow(false)}/>
      </>
  );
}

export default App;
