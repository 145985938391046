

import { React, useContext, useEffect, useState } from 'react'
import { Dropdown, Modal, Offcanvas } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { useAuth } from 'react-oidc-context';
import SessionExpiredModal from '../../utils/SessionExpiredModal';

import TransparentButton from '../../utils/TransparentButton';
import { Button } from '@mui/material';
import { SnackbarContext } from '../../App';

export default ({show, onClose}) => {

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const auth = useAuth()
    
    const [email, setEmail] = useState('');

    const [role, setRole] = useState('User');

    const [isLoading, setIsLoading] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)

    function inviteUserHandler(){
        if(email.length == 0){
            setSnackBarMessage('Email address cannot be empty')
            setSnackBarShow(true)
            return
        }
        fetch(`${window.origin}/api/users/invite`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                username: email,
                role: role
            })
        }).then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            onClose()
            setSnackBarMessage('Invitation sent')
            setSnackBarShow(true)
            return response.json();
        }).catch((error) => {
            console.error('Failed to fetch workers:', error);
            setSnackBarMessage('Failed to send invitation. Please try again later')
            setSnackBarShow(true)
        });
        onClose()
    }

    function closeHandler(){
        onClose()
        setTimeout(()=> {
            setEmail('')
            setRole('User')
        }, 200)
    }
    
    return (
        <>
            <SessionExpiredModal show={sessionExpired}/>

            <Modal
                show={show}
                onHide={closeHandler}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
            >
            
                <Modal.Body style={{padding: '2vw 3vw', paddingBottom: '0vw', textAlign: 'center'}}>

                    <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '2vw', fontWeight: '500'}}>Invite new user</h4>

                    <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 2vw', color: 'rgb(255, 255, 255, 0.7)'}}>Please provide details of the user you want to invite.</p>



                    <Form.Group style={{marginBottom: '1.5vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.85vw'}}>Email Address</p>
                            <Form.Control style={{height: '3vw', backgroundColor: 'rgba(0,0,0,.2)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '1vw'}} type="email" placeholder="Email Address" value={email} onChange={(event) => setEmail(event.target.value)}/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '1.5vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.85vw'}}>Role Type</p>
                            
                            <div style={{display: 'inline-block', backgroundColor: 'rgb(0, 0, 0, 0.2)', borderRadius: '0.6vw', padding: '0.3vw'}}>
                      


                                {['Admin', 'User'].map( (roleType, index) => <span style={{}}>
                                    <TransparentButton style={{padding: '0.3vw 0.75vw', borderRadius: '0.5vw'}} onClick={() => {
                                        setRole(roleType)
                                    }} selected={role == roleType}>{roleType}</TransparentButton>
                                </span>)}

                    
                                
    
                            </div>
                            {/* <div class="dropdown">
                                <a class="btn btn-secondary dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white', backgroundColor: 'rgba(255,255,255,.1)', border: '1px solid rgb(255, 255, 255, 0.2)', borderRadius: '8px', fontWeight: '500', fontSize: '1.1rem', width: '100%', textAlign:'left', padding: '8px 20px'}}>
                                    {role}
                                </a>

                                <ul class="dropdown-menu" style={{width: "100%"}}>
                                    {['Admin', 'User'].map( (roleType, index) => 
                                        <li key={index} onClick={() => setRole(roleType)} style={{cursor: 'pointer'}}><a class="dropdown-item">{roleType}</a></li>
                                    )}
                                </ul>
                            </div> */}
                            <Button onClick={inviteUserHandler} variant="outlined" style={{
                                    borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1.5vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                                , width: '100%', marginTop: '2.5vw'}}>Invite</Button>
                        </Form.Group>

                </Modal.Body>
            </Modal>
            {/* <Offcanvas show={show} onHide={closeHandler} placement="end" style={{backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(30px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '20px'}}>
                <div style={{flex: 1}}>
                    <Offcanvas.Header>
                    <Offcanvas.Title style={{fontSize: '1.5rem'}}>Invite New User</Offcanvas.Title>
                    </Offcanvas.Header>
                    { !isLoading && <Offcanvas.Body style={{paddingTop: '2rem', height: '100%'}}>
                        
                        <Form.Group style={{marginBottom: '3rem', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '1rem'}}>Email Address</p>
                            <Form.Control style={{height: '45px', backgroundColor: 'rgba(255,255,255,.1)', border: '1px solid rgb(255, 255, 255, 0.2)', borderRadius: '8px', fontSize: '1rem', paddingLeft: '20px'}} type="email" placeholder="Email Address" value={email} onChange={(event) => setEmail(event.target.value)}/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '3rem', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '1rem'}}>Role Type</p>
                            <div class="dropdown">
                                <a class="btn btn-secondary dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white', backgroundColor: 'rgba(255,255,255,.1)', border: '1px solid rgb(255, 255, 255, 0.2)', borderRadius: '8px', fontWeight: '500', fontSize: '1.1rem', width: '100%', textAlign:'left', padding: '8px 20px'}}>
                                    {role}
                                </a>

                                <ul class="dropdown-menu" style={{width: "100%"}}>
                                    {['Admin', 'User'].map( (roleType, index) => 
                                        <li key={index} onClick={() => setRole(roleType)} style={{cursor: 'pointer'}}><a class="dropdown-item">{roleType}</a></li>
                                    )}
                                </ul>
                            </div>
                        </Form.Group>
                        
                    </Offcanvas.Body>}
                    { isLoading && <>
                        <div style={{textAlign: 'center', paddingTop: '35vh'}}>
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </>}
                </div>
                <div style={{textAlign: 'right', paddingBottom: '1.5rem'}}>
                    <span style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', padding: '1rem'}} onClick={closeHandler}>
                        Close
                    </span>
                    <span style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', padding: '1rem'}} onClick={inviteUserHandler}>
                        Save
                    </span>
                </div>

                
            </Offcanvas> */}

        </>
            
    )
}