import { useNavigate } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';

import Form from 'react-bootstrap/Form';

import MenuBar from '../../../utils/MenuBar';

import CircleSteps from './CircleSteps';
import { useAuth } from 'react-oidc-context';
import { useState } from 'react';


export default (props) => {

    const auth = useAuth()

    const navigate = useNavigate()

    const [tan, setTan] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    function onConfirm(){
        setIsLoading(true)
        fetch(`${window.origin}/api/setup/tan`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                tan: tan
            })
        }).then(response => {
            if(response.status === 403){
              auth.signinRedirect()
              throw new Error('Session expired');
            }else{
              return props.onNext('ato')
              setIsLoading(false)
            }
        })
    }

    return (
        <>  
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Scout,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Tax Agent Number</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please provide your tax agent number to proceed</p>
            </header>
            <input
                type="text"
                // value={ABN}
                placeholder='Tax Agent Number'
                value={tan}
                onChange={(e => setTan(e.target.value))}
                style={{
                    width: '100%',
                    borderRadius: '4px',
                    background: 'rgb(255, 255, 255, 0.1)',
                    color: 'white',
                    border: 'none',
                    padding: '5px 15px',
                    margin: '0 auto',
                    marginBottom: '1.3vw',
                    height: '3vw'
                }}
                disabled={isLoading}
            />
                        
            <button
                onClick={onConfirm}
                style={{
                    borderRadius: '4px',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 20px',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600'
                }}
                disabled={isLoading}
            >
                {!isLoading && <><p style={{lineHeight: '1.6rem', margin: 0}}>Confirm</p></>}
                {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6rem'}}>
                    <div class="spinner-border" role="status" style={{width: '1.6rem', height: '1.6rem'}}>
                        <span class="sr-only">Loading...</span>
                    </div>
                    </div>}
            </button>
            </>
    )
}
