import { Button, createTheme, styled } from "@mui/material";


export function formatDatetime(isoString) {
    // Convert the ISO string to a Date object
    const date = new Date(isoString);

    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract the components
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0'); // Ensure two digits
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Format the date and time as "21 Mar 2024, 05:06:03"
    const humanReadableDateTime = `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`;

    return humanReadableDateTime
}


export const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));

export const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
