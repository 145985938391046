import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';

function SSIDSettings() {
    const auth = useAuth()

    const [ emailSent, setEmailSent ] = useState(false)

    function sendEmailHandler(){
        // openLoading("Sending email ...")
        fetch(`${window.origin}/api/sendemail`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(response => response.status
        ).then(status => {
            console.log(status)
        })
        setEmailSent(true)
    }

    function naviagteATOPortal(){
        window.open('https://www.ato.gov.au/General/Online-services/Access-Manager/About-Access-Manager/#LogintoAccessManager','_blank')
    }

    return (
        <div>
            <p style={{ fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight: '300' }}>
                Configure and update your SSID.
            </p>
            
            <div 
                style={{ 
                    
                    backgroundColor: 'rgb(255, 255, 255, 0.08)', 
                    padding: '1vw 1.5vw', 
                    borderRadius: '5px', 
                    marginTop: '1.5vw', 
                    marginBottom: '1.5vw' 
                }}
            >
                <p style={{marginTop: '1vh', fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 1</p>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please click on Email button to receive your software service ID (SSID).</p>           
                <p style={{fontSize:'0.9vw', fontWeight: '600', cursor: 'pointer'}} onClick={sendEmailHandler}><a>{ !emailSent && 'Send Email'}{ emailSent && 'Resend Email'}</a></p>
            </div>
            <div 
                style={{ 
                    backgroundColor: 'rgb(255, 255, 255, 0.08)', 
                    padding: '1vw 1.5vw', 
                    borderRadius: '5px', 
                    marginTop: '1.5vw', 
                    marginBottom: '1.5vw' 
                }}
            >
                <p style={{marginTop: '1vh', fontSize: '1.2vw', marginBottom: '.8vh'}}>Step 2</p>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Log in to Access Manager and update the SSID as provided to you via email.</p>           
                <p style={{fontSize:'0.9vw', fontWeight: '600', cursor: 'pointer'}} onClick={naviagteATOPortal}><a>Go to ATO Portal</a></p>
            </div>
        </div>
    );
}

export default SSIDSettings;
