import Form from 'react-bootstrap/Form';
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';

import { useNavigate, Link, useLocation } from "react-router-dom";
import React , { useState } from 'react';
import MessageModal from "../../utils/MessageModal";
import passwordValidator from  'password-validator';
import validator from 'validator';
import { useAuth } from "react-oidc-context";

import SpeciaLogoWithName from '../../resources/SpeciaLogoWithName.png'

import SpeciaLogo2 from '../../resources/circle specia 2.png'

import jwt from 'jwt-decode'
import LoadingModal from '../../utils/LoadingModal';


// import { Component }  from 'react';

export default function Registration()  {
    // function signInWithAzure(){
    //     window.location.href = '/api/v1/auth/login';
    // }


    const search = useLocation().search

    const queryParams = new URLSearchParams(search)

    const token = queryParams.get("token")

    const decodedToken = jwt(token)

    const auth = useAuth();

    const [ accountCreated, setAccountCreated ] = useState(false)

    const [ firstName, setFirstName] = useState('')

    const [lastName, setLastName] = useState('')

    const [ email, setEmail ] = useState(decodedToken.username)

    const [ passwd, setPasswd ] = useState('')

    const [ confirmPasswd, setConfirmPasswd ] = useState('')

    const [ phone, setPhone ] = useState('')


    const [ errorDescription, setErrorDescription ] = useState('')
    const [ messageShow, setMessageShow ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState('')

    const [submittedDescription,setSubmittedDescription] = useState('')
    const [ submittedShow, setSubmittedShow] = useState(false)
    const [ submittedMessage, setSubmittedMessage ] = useState('')

    const [ loadingMessage, setLoadingMessage ] = useState('')

    const [ isLoading, setIsLoading ] = useState(false)



    const navigate = useNavigate();


    function firstNameChangedHandler(e) {
        setFirstName(e.target.value);
    }

    function lastNameChangedHandler(e) {
        setLastName(e.target.value);
    }

    function passwdChangedHandler(e){
        setPasswd(e.target.value)
    }

    function confirmPasswdChangedHandler(e){
        setConfirmPasswd(e.target.value)
    }

    // function companyNameChangedHandler(e){
    //     setCompanyName(e.target.value)
    // }

    // function adminEmailChangedHandler(e){
    //     setAdminEmail(e.target.value)
    // }

    // function ABNchangedHandler(e){
    //     setABN(e.target.value)
    // }

    // function TANchangedHandler(e){
    //     setTAN(e.target.value)
    // }

    function phoneChangedHandler(e){
        setPhone(e.target.value)
    }

    // function addressChangedHandler(e){
    //     setAddress(e.target.value)
    // }

    function isValidFirstName(firstName) {
        if(!firstName) {
            setErrorDescription('First name cannot be empty')
            setErrorMessage('Missing First Name')
            setMessageShow(true)
            return false
        } else {
            return true;
        }
    }

    function isValidLastName(lastName) {
        if(!lastName) {
            setErrorDescription('Last name cannot be empty')
            setErrorMessage('Missing Last Name')
            setMessageShow(true)
            return false
        } else {
            return true;
        }
    }

    function isValidConfirmPassword() {

        console.log(passwd)
        console.log(confirmPasswd)
        if(passwd !== confirmPasswd) {
            setErrorDescription('Password and Confirm Password do not match')
            setErrorMessage('Password Mismatch')
            setMessageShow(true)
            return false
        } else {
            return true;
        }
    }

    function isValidEmail(email) {

        if(!email) {
            setErrorDescription('Email cannot be empty')
            setErrorMessage('Missing Mmail')
            setMessageShow(true)
            return false
        }

        const result = validator.isEmail(email+'');
        if(result === true) {
            return true
        } else {
            setErrorDescription('Please enter a valid work email')
            setErrorMessage('Invalid Email')
            setMessageShow(true)
            return false
        }
    }

    function isValidPassword(password) {
        let passwordSchema = new passwordValidator();
        passwordSchema
        .is().min(8)                                    // Minimum length 8
        .is().max(100)                                  // Maximum length 100
        .has().uppercase()                              // Must have uppercase letters
        .has().lowercase()                              // Must have lowercase letter
        .has().digits(1)                                // Must have 1 digit
        .has().not().spaces()                           // Must not have any space
        .is().not().oneOf(['123456', 'Passw0rd', 'Password123'])   //Password blacklist
        .has(/[!@#$%^&*]/g, 'The string must have at least 1 special character: !@#$%^&*') // Must have at least 1 special character !@#$%^&*

        if(!password) {
            setErrorDescription('Password cannot be empty')
            setErrorMessage('Missing Password')
            setMessageShow(true)
            return false
        }

        const result = passwordSchema.validate(password, { details: true });
        if(result.length === 0){
            return true
        } else {
            setErrorDescription(result[0].message)
            setErrorMessage('Invalid Password')
            setMessageShow(true)
            return false
        }
    }

    // function isValidCompanyName (companyName) {
    //     if(!companyName) {
    //         setErrorDescription('Please enter company name')
    //         setErrorMessage('Invalid company name')
    //         setMessageShow(true)
    //         return false
    //     } else {
    //         console.log(`Valid company: ${companyName}`)
    //         return true;
    //     }
    // }

    // function isValidAdminEmail(adminEmail) {
    //     if(!adminEmail) {
    //         setErrorDescription('Please enter admin email')
    //         setErrorMessage('Invalid admin email')
    //         setMessageShow(true)
    //         return false
    //     }

    //     const result = validator.isEmail(adminEmail+'');
    //     if(result === true) {
    //         console.log(`Valid admin email: ${adminEmail}`)
    //         return true
    //     } else {
    //         setErrorDescription('Please enter a valid email')
    //         setErrorMessage('Invalid admin email')
    //         setMessageShow(true)
    //         return false
    //     }
    // }

    // function isValidABN (ABN) {
    //     if(!ABN) {
    //         setErrorDescription('Please enter ABN')
    //         setErrorMessage('Invalid ABN')
    //         setMessageShow(true)
    //         return false
    //     }

    //     const result = validator.isNumeric(ABN) && ABN.length === 11;
    //     if(result) {
    //         console.log(`Valid ABN: ${ABN}`)
    //         return true
    //     } else {
    //         setErrorDescription('Please enter a valid 11-digit ABN')
    //         setErrorMessage('Invalid ABN')
    //         setMessageShow(true)
    //         return false
    //     }
    // }

    // function isValidTAN (TAN) {
    //     if(!TAN) {
    //         setErrorDescription('Please enter TAN')
    //         setErrorMessage('Invalid TAN')
    //         setMessageShow(true)
    //         return false
    //     }

    //     const result = validator.isNumeric(TAN);
    //     if(result) {
    //         console.log(`Valid TAN: ${TAN}`)
    //         return true
    //     } else {
    //         setErrorDescription(`Please enter a valid TAN`)
    //         setErrorMessage('Invalid TAN')
    //         setMessageShow(true)
    //         return false
    //     }
    // }

    function isValidPhone (phone) {
        if(!phone) {
            setErrorDescription(`Phone Number cannot be empty!`)
            setErrorMessage('Missing Phone Number')
            setMessageShow(true)
            return false
        }

        const result = validator.isMobilePhone(phone);
        if(result) {
            return true
        } else {
            setErrorDescription('Please enter a valid phone number!')
            setErrorMessage('Invalid Phone Number')
            setMessageShow(true)
            return false
        }
    }

    // function isValidAddress (address) {
    //     if(!address) {
    //         setErrorDescription('Please enter an address')
    //         setErrorMessage('Invalid phone address')
    //         setMessageShow(true)
    //         return false
    //     }
    //     console.log(`Valid address: ${address}`)
    //     return true
    // }

    function handleSubmit(){

        if(!isValidFirstName(firstName)) {
            return false;
        }

        if(!isValidLastName(lastName)) {
            return false;
        }

        if(!isValidEmail(email)) {
            return false;
        }

        if(!isValidPassword(passwd)) {
            return false;
        }

        if(!isValidConfirmPassword()){
            return false;
        }

        let postData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: passwd,
        }

        setIsLoading(true)
        setLoadingMessage('Creating you account ...')

        fetch(`${window.origin}/api/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postData),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if(data.statusCode != 201){
                setErrorMessage("Registration Failed")
                setErrorDescription(data.message)
                setMessageShow(true)
            }else{
                setLoadingMessage('Acepting invite ...')
                fetch(`${window.origin}/api/users/accept-invite`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }).then(resp => {
                    if(resp.status === 200){
                        setIsLoading(true)
                        navigate('/invite/accepted')
                    }
                })
            }
        })



    }

    function backToLogin(){
        if(!auth.isAuthenticated){
            auth.signinRedirect()
        }else{
            console.log(auth.user?.access_token)
        }
    }


    return (
        <>

            <div style={{display:'flex', flexDirection:'column', width:'100vw', height:'100vh', overflowY:'scroll'}}>

                <div style={{display:'flex', flexDirection:'row', padding: '5vh 15vw'}}>
                    <div style={{flex: 1}}>
                        <img src={SpeciaLogoWithName} width="40%" style={{paddingBottom: '12vh'}}/>
                        <img src={SpeciaLogo2} width="90%" style={{paddingBottom: '12vh'}}/> 
                    </div>
                    


                    <div style={{flex: 1, display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <div style={{paddingLeft: '10vw'}}>
                            <header>
                                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Specia Platform,
                                </p>
                                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Accept Your Invite</h1>
                                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw'}}>Please create your account before joining {decodedToken.compName}</p>
                            </header>
                            <input
                                type="text"
                                placeholder="First Name" value={firstName} onChange={firstNameChangedHandler}
                                style={{
                                    width: '100%',
                                    fontSize: '0.9vw',
                                    borderRadius: '0.3vw',
                                    background: 'rgb(255, 255, 255, 0.1)',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.4vw 1vw',
                                    marginBottom: '1.3vw',
                                    height: '3vw'
                                }}
                            />
                            <input
                                type="text"
                                placeholder="Last Name" value={lastName} onChange={lastNameChangedHandler}
                                style={{
                                    width: '100%',
                                    fontSize: '0.9vw',
                                    borderRadius: '0.3vw',
                                    background: 'rgb(255, 255, 255, 0.1)',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.4vw 1vw',
                                    marginBottom: '1.3vw',
                                    height: '3vw'
                                }}
                            />
                            <input
                                type="email"
                                placeholder="Email Address" value={decodedToken.username} disabled
                                style={{
                                    width: '100%',
                                    borderRadius: '0.3vw',
                                    fontSize: '0.9vw',
                                    background: 'rgb(255, 255, 255, 0.1)',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.4vw 1vw',
                                    marginBottom: '1.3vw',
                                    height: '3vw'
                                }}
                            />
                            <input
                                type="password"
                                placeholder="Password" value={passwd} onChange={passwdChangedHandler}
                                style={{
                                    width: '100%',
                                    borderRadius: '0.3vw',
                                    fontSize: '0.9vw',
                                    background: 'rgb(255, 255, 255, 0.1)',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.4vw 1vw',
                                    marginBottom: '1.3vw',
                                    height: '3vw'
                                }}
                            />
                            <input
                                type="password"
                                placeholder="Confirm Password" value={confirmPasswd} onChange={confirmPasswdChangedHandler}
                                style={{
                                    width: '100%',
                                    borderRadius: '0.3vw',
                                    fontSize: '0.9vw',
                                    background: 'rgb(255, 255, 255, 0.1)',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.4vw 1vw',
                                    marginBottom: '1.3vw',
                                    height: '3vw'
                                }}
                            />
                            <button
                                onClick={handleSubmit}
                                style={{
                                    borderRadius: '0.3vw',
                                    background: '#FE475B',
                                    fontSize: '0.9vw',
                                    border: 'none',
                                    color: 'white',
                                    padding: '0.7vw 1.5vw',
                                    width:'100%',
                                    cursor: 'pointer',
                                    fontWeight: '600'
                                }}
                            >
                                Sign up and Join
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <LoadingModal show={isLoading} message={loadingMessage}/>
            <MessageModal show={messageShow} onClose={() => {setMessageShow(false)}} message={errorMessage} description={errorDescription}></MessageModal>
            <div>
            <MessageModal show={submittedShow} onClose={() => {setSubmittedShow(false)}} message={submittedMessage} description={submittedDescription}></MessageModal>
            </div>
        </>
    )
}