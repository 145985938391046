import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../resources/speciaLogo.png";


import LogoutIcon from '@mui/icons-material/Logout';

import HomeIcon from '@mui/icons-material/Home';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import SettingsIcon from '@mui/icons-material/Settings';

import HomeLogo from "../resources/home.png";
import StoreLogo from "../resources/marketplace.png";
import MyDigiLogo from "../resources/reportsIcon.png";
import SettingIcon from "../resources/settings_icon.png";
import md5 from './md5';
import { ButtonBase, IconButton, MenuItem, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ThemeProvider, createTheme } from '@mui/material';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default function MenuBarNew({ show, closeHandler }) {

    const location = useLocation()
    

    const navigate = useNavigate();

    const auth = useAuth();

    function logout() {
        auth.signoutRedirect();
        closeHandler()
    }
    

    return (
        <>
            <Offcanvas show={show} onHide={closeHandler} placement="start" style={{width: '18vw', backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(50px)', '-webkit-backdrop-filter': 'blur(50px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1vw', paddingRight: '1vw', paddingTop: '1vw', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '2vw'}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <Offcanvas.Header style={{padding: '1vw'}}>
                    <Offcanvas.Title style={{fontSize: '1.3vw'}}>Specia Platform</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{paddingTop: '2vw', padding: '0.5vw', display: 'flex', flex: 1}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <div style={{flex: 1}}>
                                <ThemeProvider theme={darkTheme}>
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton selected={location.pathname == "/"} style={{padding: '0.3vw 0.6vw', marginBottom: '1.2vw', borderRadius: '0.4vw'}} onClick={() => { navigate('/'); closeHandler() }} >
                                            <ListItemIcon>
                                                <HomeIcon style={{color: 'white'}}  />
                                            </ListItemIcon>
                                            <ListItemText primary="Home" />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton selected={location.pathname == "/MyDigitalWorker"} style={{padding: '0.3vw 0.6vw', marginBottom: '1.2vw', borderRadius: '0.4vw'}} onClick={() => { navigate('/MyDigitalWorker'); closeHandler() }}>
                                            <ListItemIcon>
                                                <FormatListBulletedIcon style={{color: 'white'}} />
                                            </ListItemIcon>
                                            <ListItemText primary="My Digital Workers" />
                                            </ListItemButton>
                                        </ListItem >
                                        <ListItem disablePadding>
                                            <ListItemButton selected={location.pathname == "/setting"} style={{padding: '0.3vw 0.6vw', marginBottom: '1.2vw', borderRadius: '0.4vw'}} onClick={() => { navigate('/setting'); closeHandler() }}>
                                            <ListItemIcon>
                                                <SettingsIcon  style={{color: 'white'}}  />
                                            </ListItemIcon>
                                            <ListItemText primary="Settings" />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </ThemeProvider>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.9vw'}}>
                                <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" style={{ backgroundColor: 'transparent', border: 0 , padding: 0}}>
                                    <img width={'3vw'} src={`https://ui-avatars.com/api/?name=${auth.user?.profile.given_name}+${auth.user?.profile.family_name}`} style={{ width: '2.5vw', borderRadius: '50%'}} alt="User avatar" />
                                </button>
                                <div style={{flex: 1, marginLeft: '1.5vw', fontWeight: '600', fontSize: '0.85vw'}}>
                                    {auth.user?.profile.given_name} {auth.user?.profile.family_name}
                                </div>
                                <ThemeProvider theme={darkTheme}>
                                    <IconButton aria-label="delete" size="large" onClick={logout}>
                                        <LogoutIcon fontSize="medium" />
                                    </IconButton>
                                </ThemeProvider>
                            </div>
                        </div>
                    
                    </Offcanvas.Body>

                </div>
                
                
            </Offcanvas>

        </>
            
    );
}
