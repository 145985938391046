import React, { useState, useEffect } from 'react';
import { Button, Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import { useNavigate } from 'react-router-dom';
import logo from "../resources/speciaLogo.png";

import HomeLogo from "../resources/home.png";
import StoreLogo from "../resources/marketplace.png";
import MyDigiLogo from "../resources/reportsIcon.png";
import SettingIcon from "../resources/settings_icon.png";
import md5 from '../../src/utils/md5';
import { IconButton } from '@mui/material';

export default function MenuBar({ imgClass }) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [navbarWidth, setNavbarWidth] = useState('4vw');
    const [imageWidth, setImageWidth] = useState('1.8vw');

    function logout() {
        auth.signoutRedirect();
    }

    const updateNavbarWidth = () => {
        if (window.innerHeight > window.innerWidth) {
            setNavbarWidth('12vw');
            setImageWidth('6.8vw');
        } else {
            setNavbarWidth('4vw');
            setImageWidth('1.8vw');
        }
    };

    useEffect(() => {
        updateNavbarWidth();
        window.addEventListener('resize', updateNavbarWidth);
        return () => {
            window.removeEventListener('resize', updateNavbarWidth);
        };
    }, []);

    return (
        <div className={imgClass} style={{ textAlign: 'center', position: 'relative', height: '100vh', display: 'flex', flexDirection: 'row' }}>
            {/* navbar */}
            <Navbar expand="xl" className="bg-body-tertiary" style={{ width: navbarWidth, padding: '0px', borderRight: '2px solid rgba(128, 128, 128, 0.1)' }}>
                <Container style={{ display: 'flex', flexDirection: 'column', background: 'transparent', maxWidth: '100%', padding: '20px 0px', height: '100%' }}>
                    {/* logo */}
                    <div onClick={() => { navigate('/') }} style={{ height: '90px', padding: '0 0.5rem', justifyContent: 'center' }}>
                        <img src={logo} style={{ width: imageWidth }} alt="Logo" />
                    </div>
                    {/* navbar */}
                    {/* <Navbar.Collapse id="basic-navbar-nav" style={{ alignItems: 'flex-start' }}> */}
                    <Nav className="me-auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto' }}>
                            {/* home */}
                        <OverlayTrigger
                            placement='right'
                            overlay={<Tooltip id={`home`}>Home</Tooltip>}
                        >
                                {/* <IconButton aria-label="delete"></IconButton> */}
                            <IconButton onClick={() => { navigate('/') }} style={{ color: 'white', margin: 'auto', padding: '1vw 1vw' }}>
                                <img style={{ filter: 'invert(1)', width: imageWidth }} src={HomeLogo} alt="Home" />
                            </IconButton>
                        </OverlayTrigger>
                            {/* mydigitalworkers */}
                        <OverlayTrigger
                            placement='right'
                            overlay={<Tooltip id={`mydigitalworkers`}>My Digital Workers</Tooltip>}
                        >
                            <IconButton onClick={() => { navigate('/MyDigitalWorker') }} style={{ color: 'white', margin: 'auto', marginTop: '1vh', padding: '1vw 1vw' }}>
                                <img style={{ width: imageWidth }} src={MyDigiLogo} alt="My Digital Workers" />
                            </IconButton>
                        </OverlayTrigger>
                            {/* store */}
                            {/* <OverlayTrigger
                                placement='right'
                                overlay={<Tooltip id={`marketplace`}>Marketplace</Tooltip>}
                            >
                                <Nav.Link onClick={() => { navigate('/store') }} style={{ color: 'white', margin: 'auto', padding: '2.5vh 0px' }}>
                                    <img style={{ filter: 'invert(1)' , width: '1.8vw'}} src={StoreLogo} alt="Marketplace" />
                                </Nav.Link>
                            </OverlayTrigger> */}
                    </Nav>
                    {/* </Navbar.Collapse> */}
                    {/* profile picture */}
                    <div style={{ flex: 9, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'column' }}>
                        <Nav className="me-auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 auto', justifyContent: 'flex-end' }}>
                            <OverlayTrigger
                                placement='right'
                                overlay={<Tooltip id={`settings`}>Settings</Tooltip>}
                            >
                                <Nav.Link onClick={() => { navigate('/setting') }} style={{ color: 'white', margin: '2.5vh 0px' }}>
                                    <img style={{ paddingBottom: '2vh', width: imageWidth }} src={SettingIcon} alt="Settings" />
                                </Nav.Link>
                            </OverlayTrigger>
                        </Nav>
                        {/* </Navbar.Collapse> */}
                        <div className="btn-group" style={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
                            <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" style={{ backgroundColor: 'transparent', border: 0 }}>
                                <img width={imageWidth} src={`https://ui-avatars.com/api/?name=${auth.user?.profile.given_name}+${auth.user?.profile.family_name}`} style={{ width: imageWidth, borderRadius: '50%', padding: '1px' }} alt="User avatar" />
                            </button>
                            <ul style={{
                                padding: '30px 40px',
                                borderRadius: '15px',
                                position: 'absolute',
                                left: '120%',
                                top: '-220px',
                                width: '18rem',
                                height: '18rem',
                                backgroundColor: 'rgba(35, 30, 45, 0.9)',
                                '-webkit-backdrop-filter': 'blur(10px)',
                                backdropFilter: 'blur(50px)',
                                border: '1px solid rgba(255, 255, 255, 0.2)'
                            }}
                                className="dropdown-menu">
                                <li>
                                    <div style={{ height: '200px', textAlign: 'center' }}>
                                        <img width="70px" src={`https://ui-avatars.com/api/?name=${auth.user?.profile.given_name}+${auth.user?.profile.family_name}`} style={{ border: "2px solid #ee7170", borderRadius: '50px', padding: '1px', marginBottom: '2.5vh' }} alt="User avatar" />
                                        <p style={{ fontWeight: '600', color: "rgba(245, 245, 245)", marginBottom: '0.6rem', fontSize: '1.1rem' }}>{auth.user?.profile.given_name} {auth.user?.profile.family_name}</p>
                                        <p onClick={() => { navigate('/setting') }} style={{ fontWeight: '500', cursor: 'pointer', fontSize: '0.9rem', color: "rgba(230, 230, 230)" }}>Edit Profile</p>
                                        <br />
                                        <Button onClick={logout} style={{ padding: '8px 35px', borderRadius: '50px', fontSize: '0.8rem' }} variant="primary">
                                            Sign out
                                        </Button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </div>
    );
}
