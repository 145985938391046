import React, { useState, useEffect } from 'react';
import { Button, Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import { useNavigate } from 'react-router-dom';
import logo from "../resources/speciaLogo.png";

import LogoutIcon from '@mui/icons-material/Logout';

import HomeLogo from "../resources/home.png";
import StoreLogo from "../resources/marketplace.png";
import MyDigiLogo from "../resources/reportsIcon.png";
import SettingIcon from "../resources/settings_icon.png";
import md5 from './md5';
import { IconButton, Menu, MenuItem, ThemeProvider, createTheme } from '@mui/material';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default function QuickAccessBar({ imgClass }) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [navbarWidth, setNavbarWidth] = useState('4vw');
    const [imageWidth, setImageWidth] = useState('1.8vw');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function logout() {
        auth.signoutRedirect();
    }

    const updateNavbarWidth = () => {
        if (window.innerHeight > window.innerWidth) {
            setNavbarWidth('12vw');
            setImageWidth('6.8vw');
        } else {
            setNavbarWidth('4vw');
            setImageWidth('1.8vw');
        }
    };

    useEffect(() => {
        updateNavbarWidth();
        window.addEventListener('resize', updateNavbarWidth);
        return () => {
            window.removeEventListener('resize', updateNavbarWidth);
        };
    }, []);

    return (
        <>
            {/* <button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                type="button" className="btn btn-secondary" data-bs-toggle="dropdown" style={{ backgroundColor: 'transparent', border: 0 }}>
                <img src={`https://ui-avatars.com/api/?name=${auth.user?.profile.given_name}+${auth.user?.profile.family_name}`} style={{ width: '3vw', borderRadius: '50%', padding: '1px' }} alt="User avatar" />
            </button> */}

            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{background: 'none', padding: '0', border: 'none'}}
            >
                <img src={`https://ui-avatars.com/api/?name=${auth.user?.profile.given_name}+${auth.user?.profile.family_name}`} style={{ width: '3vw', borderRadius: '50%', padding: '1px' }} alt="User avatar" />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem style={{padding: '1vw 1.5vw'}} onClick={handleClose}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.9vw'}}>
                        <button type="button" className="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false" style={{ backgroundColor: 'transparent', border: 0 , padding: 0}}>
                            <img width={'3vw'} src={`https://ui-avatars.com/api/?name=${auth.user?.profile.given_name}+${auth.user?.profile.family_name}`} style={{ width: '2.5vw', borderRadius: '50%'}} alt="User avatar" />
                        </button>
                        <div style={{flex: 1, marginLeft: '1.5vw', fontWeight: '600', fontSize: '0.85vw'}}>
                            {auth.user?.profile.given_name} {auth.user?.profile.family_name}
                        </div>
                    </div>
                </MenuItem>
                <MenuItem style={{padding: '1vw 1.5vw'}} onClick={() => { navigate('/'); handleClose() }}>Home</MenuItem>
                <MenuItem style={{padding: '1vw 1.5vw'}} onClick={() => { navigate('/setting'); handleClose() }}>Settings</MenuItem>
                <MenuItem style={{padding: '1vw 1.5vw'}} onClick={() => {
                    handleClose()
                    logout()
                }}>Logout</MenuItem>
            </Menu>
        </>
    );
}
