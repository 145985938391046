/* eslint-disable import/no-anonymous-default-export */


import { CircularProgress } from '@mui/material';
import { React, useState, useEffect } from 'react'

export default ({userData, openModalHandler, isLoading}) => {

    const [ tableData, setTableData ] = useState(userData)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentitems] = useState(userData.slice(indexOfFirstItem, indexOfLastItem))


    // Page change handlers
    const nextPage = () => {
        if (currentPage < Math.ceil(tableData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    useEffect(() => {
        setTableData(userData)
        console.log(userData)
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(userData.slice(indexOfFirstItem, indexOfLastItem))
    }, [userData]);


    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(userData.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);

    return (
        <>
            <div style={{lineHeight: '2.2vw', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '1vw'}}>
                <table className="table table-hover table-dark" style={{ color: 'white', fontSize: '0.9vw', width: '100%', backgroundColor: 'transparent', margin: 0}}>
                    <thead style={{color: 'rgb(230, 230, 230)', borderBottom: '1px solid rgb(255, 255, 255, 0.1)', backgroundColor: 'rgb(255, 255, 255, 0.03)'}}>
                        <tr style={{}}>
                            <th style={{padding:'0.5w 1vw', paddingLeft:'1.5vw', width: '25%'}} scope="col">First Name</th>
                            <th style={{padding:'0.5w 1vw', width: '25%'}} scope="col">Last Name</th>
                            <th style={{padding:'0.5w 1vw', width: '50%'}} scope="col">Email Address</th> 
                        </tr>
                    </thead>
                    
                    <tbody style={{}}>
                        { !isLoading && !tableData.length && 
                        <tr>
                            <td colspan="7"><p style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}>No user found</p></td>
                        </tr>}
                        { isLoading && 
                        <tr>
                            <td colspan="7"><p style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}><CircularProgress color="inherit" /></p></td>
                        </tr>}
                        { currentItems.map(user => 
                            <tr style={{borderTop: '1px solid rgb(255, 255, 255, 0.1)', cursor: 'pointer'}} onClick={() => openModalHandler(user.id)}>
                                <td style={{padding:'0.5w 1vw', paddingLeft:'1.5vw'}} scope="row">{user.firstName}</td>
                                <td style={{padding:'0.5w 1vw'}}>{user.lastName}</td>
                                <td style={{padding:'0.5w 1vw'}}>{user.email}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: '1vw', marginLeft: '0vw', marginRight: '0vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                <div style={{flex: 1}}>
                    <p style={{ color: 'rgb(255, 255, 255, 0.6)', fontSize: '0.9vw', marginLeft: '0.2vw', marginTop: '1vw' }}>
                        Showing {indexOfFirstItem+1} to {indexOfLastItem > tableData.length ? tableData.length: indexOfLastItem} of {tableData.length} entries
                    </p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button 
                        onClick={prevPage} 
                        disabled={currentPage === 1}
                        style={{
                            width: '2.5vw',
                            height: '2.5vw',
                            borderRadius: '0.5vw',
                            marginRight: '6px',
                            color: currentPage === 1 ? 'gray' : 'white',
                            backgroundColor: 'rgb(255, 255, 255, 0.02)',
                            border: '0.5px solid rgb(255, 255, 255, 0.3)',
                            // color: 'white',
                            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.9vw',
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                    </button>
            
                    <button 
                        onClick={nextPage} 
                        disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
                        style={{
                            width: '2.5vw',
                            height: '2.5vw',
                            borderRadius: '0.5vw',
                            marginLeft: '6px',
                            color: 'white',
                            backgroundColor: 'rgb(255, 255, 255, 0.02)',
                            border: '0.5px solid rgb(255, 255, 255, 0.3)',
                            cursor: currentPage === Math.ceil(tableData.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.9vw',
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: '&rarr;'}} />
                    </button>
                </div>
            </div>
        </>
    )
}