import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MenuBar from '../../utils/MenuBar';
import { useAuth } from 'react-oidc-context';

const industries = [
    {id: 1, name: 'Accounting', path: '/store/detail/accounting'},
    {id: 2, name: 'Education', path: '/store/accounting'},
    {id: 3, name: 'Trading', path: '/store/accounting'},
    {id: 4, name: 'Manufactoring', path: '/store/accounting'}
];

export default (props) => {

    const navigate = useNavigate()

    const auth = useAuth()

    const [searchTerm, setSearchTerm] = useState("");
    const [workers, setWorkers] = useState([]);

    useEffect(() => {
        fetch(`${window.origin}/api/inactive-digital-workers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`
            },
        })
        .then(response => response.json())
        .then(data => {
            setWorkers(data);
        })
        .catch(error => {
            console.error("Error fetching inactive digital workers:", error);
        });
    }, []);

    const filteredWorkers = searchTerm
        ? workers.filter(worker => worker.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : workers;

    return (
        <div className={props.imgClass} style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row'}}>
            <MenuBar />
            <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'2vh', height:'100vh'}}>
                <div style={{ width: '100%', }}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <div style={{flex:1, paddingLeft: '2vw'}}>
                            <Form.Group style={{ textAlign:'left'}}>
                                <i style={{fontSize: '1vw'}} className="fa-solid fa-magnifying-glass"></i>
                                <Form.Control 
                                    value={searchTerm} 
                                    onChange={(e) => setSearchTerm(e.target.value)} 
                                    style={{display: 'inline', height: '45px', backgroundColor: 'rgba(255,255,255,0)', border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '1vw', maxWidth: '40%', marginLeft:'0.5vw'}} 
                                    type="text" 
                                    placeholder="Search"
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <div style={{marginTop: '2vh', display:'flex', flexDirection:'column', marginLeft: '2vw', marginBottom: '1.5vh', marginRight: '2vw', marginTop: '3vw'}}>
                        {searchTerm ? (
                            <div style={{ width: '100%', maxWidth:'-webkit-fill-available'}}>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <div style={{display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '0.5vw', textAlign: 'center', marginTop:'20px'}}>
                                        {filteredWorkers.map((worker, index) => (
                                            <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1, padding: '0px 0.5vw'}}>
                                                <Button onClick={() => {navigate("/store/detail/" + worker.digital_worker_id)}} style={{width: '90%', height: '8vw', borderRadius: "15px", backgroundImage: `url(${worker.image})`, backgroundSize: 'cover', backgroundPosition: 'center', border: 'none'}}></Button>
                                                <p style={{margin: '0px', fontSize: '1vw', color: 'white', textAlign:'left'}}>{worker.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div style={{ width: '100%', maxWidth:'-webkit-fill-available', marginBottom: '7vh'}}>
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1vw'}}>
                                            <h2 style={{fontSize: '1.2vw', fontWeight: '500', color: 'rgb(255, 255, 255, 0.9)'}}>Industries</h2>
                                        </div>
                                        <div style={{display: 'grid', gridTemplateColumns:'repeat(5, 1fr)', gap:'1vw', textAlign: 'center'}}>
                                            {industries.map((industry, index) => (
                                                <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0' }}>
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', flex: 1}}>
                                                        <Button onClick={() => {navigate(industry.path)}} style={{width: '100%', height: '6vw', borderRadius: "5px", backgroundSize: 'cover', backgroundPosition: 'center', border: 'none', backgroundColor:'#242555', fontSize:'1vw'}}>{industry.name}</Button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '100%', maxWidth:'-webkit-fill-available'}}>
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1vw'}}>
                                            <h2 style={{fontSize: '1.2vw', fontWeight: '500', color: 'rgb(255, 255, 255, 0.9)'}}>Recommended</h2>
                                        </div>
                                        
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '20px', marginTop: '10px' }}>
                                            {workers.map((worker, index) => (
                                                <div key={index} style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '0px 0.5vw 0px 0px' }}>
                                                    <Button
                                                    onClick={() => { navigate("/store/detail/" + worker.digital_worker_id) }}
                                                    style={{
                                                        width: '100%',
                                                        height: '8vw',
                                                        borderRadius: "5px",
                                                        backgroundImage: `url(${worker.image})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        border: '1px solid rgb(255, 255, 255, 0.15)'
                                                    }}
                                                    ></Button>
                                                    <p style={{ margin: '0.5vw 0.2vw', fontSize: '1vw', color: 'rgb(255, 255, 255, 0.7)', textAlign: 'left', fontWeight: '500'}}>{worker.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

