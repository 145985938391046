import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


import styles from '../Gayatri.module.css'
import { Box, Button, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { setStep } from '../../../app/stepSlice';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Form } from 'react-bootstrap';
import { SnackbarContext } from '../../../App';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


const currentDate = new Date();
const currentYear = currentDate.getFullYear();


export default ({show, onHide, onRefresh}) => {

    const navigate = useNavigate()
    const auth = useAuth()

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const [currentStep, setCurrentStep] = useState(1)

    const [fileUploaded, setFileUploaded] = useState()

    const [sessionExpired, setSessionExpired] = useState(false)

    const [currentCustomeName, setCurrentCustomeName] = useState('');

    const [templateDetails, setTemplateDetails] = useState([])

    const [ tags, setTags ] = useState([])

    function nextStepHandler (){
        setCurrentStep((current_step) => current_step+1)
        
    }

    function onSubmit(){
        // Create FormData object
        const formData = new FormData();

        // Append file to the FormData
        if (fileUploaded.size){formData.append('file', fileUploaded);}

        // Append JSON data to the FormData
        const jsonData = JSON.stringify(templateDetails)

        formData.append('data', jsonData);

        formData.append('name', currentCustomeName);

        if (fileUploaded.name) {formData.append('fileName', fileUploaded.name);}
        

        fetch(`${window.origin}/api/creator/template/new`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: formData,
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }else{
                    throw new Error('Unknown Error');
                }
            } else {
                onRefresh()
                onClose()
                setSnackBarMessage('Template created')
                setSnackBarShow(true)

            }
        }).catch(error => {
            setSnackBarMessage('Unknown error occurred. Please try again later')
            setSnackBarShow(true)
            console.error('Error editing template:', error);
        });
        // onClose()
    }

    function onClose (){
        setCurrentStep(1)
        setCurrentCustomeName('')
        setFileUploaded()
        setTemplateDetails([])
        onHide()
    }


    function fileUploadHandler(file){
        setFileUploaded(file)

        const formData = new FormData();

        formData.append('file', file);

        fetch(`${window.origin}/api/creator/scan-template`, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            method: 'POST',
            body: formData,
        })
        .then(response => {
            if(!response.ok) {
                // Redirect if unauthorized, log error for other failures
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                } else {
                    return Promise.reject(`Failed to edit client. Status: ${response.status}`);
                }
            } else {
                return response.json()
            }
        }).then(data => {
            setTemplateDetails(data)
        })
        .catch(error => {
            console.error('Error editing client:', error);
        });

    }


   

    useEffect( () => {
        fetch(`${window.origin}/api/creator/tags`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            setTags(data)
            console.log(data)
            // setIsLoading(false)
        }).catch((error) => {
            console.error(error);
        });
    }, [])





    return (
        
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-70w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <SessionExpiredModal show={sessionExpired}/>
            
            <Modal.Body style={{padding: '2vw 4vw', textAlign: 'center'}}>
                <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '2vw', fontWeight: '500'}}>Create Template</h4>

                {currentStep == 1 && <>
                    <p style={{margin: 0, marginBottom: '0.5vw', fontWeight: '400', fontSize: '0.9vw'}}>Step 1</p>
                    <p style={{margin: 0, marginBottom: '0.5vw', fontWeight: '500', fontSize: '1vw'}}>Configure your Workpaper Template</p>
                    <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 7vw'}}>Please select and add the following tags below to your Excel Template to specify which reports to include in the template and where these report shoulde be placed.</p>
                
                    
                    <div style={{backgroundColor: 'rgb(0, 0, 0, 0.2)', borderRadius: '0.5vw', margin: '0 2vw'}}>
                        <ListItemButton style={{backgroundColor: 'rgb(0, 0, 0, 0)'}}>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', textAlign: 'left'}}>
                                <div style={{flex: 1, fontSize: '0.8vw'}}>{`Tag`}</div>
                                <div style={{flex: 2, fontSize: '0.8vw'}}>{`Report`}</div>
                            </div>
                        </ListItemButton>

                        <Box style={{width: '100%'}}
                            sx={{ width: '100%', maxHeight: '20vw', width: '100%', bgcolor: 'rgb(0, 0, 0, 0)', overflowY: 'scroll' }}
                            >
                                
                            {tags.map((tag) => 
                                <ListItemButton style={{backgroundColor: 'rgb(0, 0, 0, 0.1)', width: '100%'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                        <div style={{flex: 1, fontSize: '0.8vw'}}>{`<${tag.tab_id}/>`}</div>
                                        <div style={{flex: 2, fontSize: '0.8vw'}}>{`${tag.tab_name}`}</div>
                                    </div>
                                </ListItemButton>)}
                        </Box>
                    </div>

                    <Button onClick={nextStepHandler}  variant="outlined" style={{
        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
    , width: '20vw', marginTop: '2vw'}}>Next Step</Button>

                </>}

                {currentStep == 2 && <>
                    <p style={{margin: 0, marginBottom: '0.5vw', fontWeight: '400', fontSize: '0.9vw'}}>Step 2</p>
                    <p style={{margin: 0, marginBottom: '0.5vw', fontWeight: '500', fontSize: '1vw'}}>Upload and review your Workpaper Template</p>
                    <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 7vw'}}>Please upload your template and let Gayatri analyse the template.</p>
                
                    
                    <div style={{margin: '0 2vw', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                            <div style={{flex: 1, marginRight: '3vw'}}>
                                <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                                    <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Template Name</p>
                                    <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>This name will displayed as the name of the template when you create workpapers.</p>
                                    <Form.Control style={{height: '2vw', backgroundColor: 'rgba(0,0,0,.2)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="text" placeholder="Name" value={currentCustomeName} onChange={(e) => setCurrentCustomeName(e.target.value)}/>
                                </Form.Group>
                                <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                                    <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Template Details</p>
                                    <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>Select and upload your template.</p>
                                    
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        style={{fontSize: '0.8vw'}}
                                        >
                                        Upload files
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={(event) => fileUploadHandler(event.target.files[0])}
                                            accept=".xlsx"
                                            single
                                        />
                                    </Button>
                                    <p style={{fontSize: '0.9vw', margin: 0, marginTop: '1vw'}}>{fileUploaded?.name}</p>
                                </Form.Group>
                            </div>
                            <div style={{flex: 1}}>
                                <Box style={{width: '100%', height: '20vw', backgroundColor: 'rgb(0, 0, 0, 0.1)', borderRadius: '0.7vw', border: '1px solid rgb(255, 255, 255, 0.05)'}}
                                    sx={{ width: '100%', width: '100%', overflowY: 'scroll' }}
                                    >
                                        
                                    {templateDetails.map((item) => 
                                        <ListItemButton style={{backgroundColor: 'rgb(0, 0, 0, 0.1)', width: '100%'}}>
                                            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                                <div style={{flex: 1, fontSize: '0.8vw'}}>{`Found tag <${item.tag}/> at position ${item.position} in sheet ${item.sheet}`}</div>
                                            </div>
                                        </ListItemButton>)}
                                </Box>
                            </div>
                    </div>

                    <Button onClick={onSubmit}  variant="outlined" style={{
        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
    , width: '20vw', marginTop: '2vw'}}>Confirm</Button>
                    <p style={{fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.7)'}}>Not ready to upload your template? <span onClick={() => {setCurrentStep((currentStep) => currentStep - 1)}} style={{color: '#f25252', fontWeight: '500', cursor: 'pointer'}}>Previous Step</span></p>

                </>}






            </Modal.Body>
        </Modal>
    )
}



