

import { React, useState } from 'react'
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';

import Spinner from 'react-bootstrap/Spinner';



export default ({show, message}) => {
    
    
    return (
        <Modal style={{ backgroundColor: 'rgb(0, 0, 0, 0.4)'}} contentClassName='whiteContentModal' show={show} centered>
                <Modal.Header style={{ border: 0, color: '#ee7170', padding: 0}}>
                </Modal.Header>
                <Modal.Body style={{padding: '40px 0px', textAlign: 'center'}}>
                    <Form.Group controlId="formFileLg" className="mb-3">
                        <div style={{textAlign: 'center'}}>
                            <Spinner animation="border" variant="secondary" style={{width: '5rem', height: '5rem'}}/>
                            <p style={{fontSize: '1.1rem', fontWeight: '400', marginTop: '30px'}}>{message}</p>
                        </div>
                        <p style={{fontSize: '1.3rem'}}>Please Wait!</p>
                        <p style={{color: 'rgb(255, 255, 255, 0.6)'}}>This might take a while ...</p>
                    </Form.Group>
                </Modal.Body>
            </Modal>
            
    )
}