import React from "react";
import { 
    useEffect, 
    useState,
} from "react";
import { 
    Outlet,
    useNavigate, 
    // useSearchParams,
} from "react-router-dom";

import { useAuth } from "react-oidc-context";
import { CircularProgress, Fade } from "@mui/material";
import SessionExpiredModal from "../../utils/SessionExpiredModal";
import SubscriptionRequired from "./Subscription/SubscriptionRequired";
import GayatriSetup from "./SetUp/GayatriSetup";
import QuickAccessBar from "../../utils/QuickAccessBar";



export default function SetUpRequired(props) {



    const auth = useAuth()

    const navigate = useNavigate()

    const [currentStep, setCurrentStep ] = useState()

    const [setUpCurrentStep, setSetUpCurrentStep] = useState("tan")

    const [isLoading, setIsLoading] = useState(true)

    const [sessionExpired, setSessionExpired] = useState(false)



    useEffect(() => {
        setIsLoading(true)
        fetch(`${window.origin}/api/creator/setup/status`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(response => {
          if(response.status === 403){
            setSessionExpired(true)
            throw new Error('Session expired');
          }else{
            return response.json()
          }
        }
        ).then(data => {
          console.log('current_step; ' +data)
          if (data.currentStep == 'subscription'){
            // navigate('/mydigitalworkers/gayatri/subscription')
            setCurrentStep('subscription')
          }else if(data.currentStep !== 'complete'){
            setSetUpCurrentStep(data.currentStep)
            console.log(data)
            // navigate('/mydigitalworkers/gayatri/setup', {state: data})
            setCurrentStep('setup')
          }else{
            setCurrentStep('complete')
          }
          setIsLoading(false)
          console.log('finished')
          
        }).catch(e => {
          console.log(e)
          setIsLoading(false)
          
        })
    }, []);

    // useEffect(() => {
    //     if(!auth.user?.profile.accountant.ssid || !auth.user?.profile.accountant.verified ){
    //         navigate('/setup')
    //     }
    // })


    


    return (
        <React.Fragment>
              
                    
            <Fade in={isLoading}>
              
              <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backdropFilter: 'blur(30px)', backgroundColor: 'rgb(255, 255, 255, 0.06)', position: 'absolute', zIndex: '1' }}>
                <CircularProgress color="inherit" />
              </div>
            </Fade>

            <SessionExpiredModal show={sessionExpired}/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                
            
            { (currentStep == 'subscription' || currentStep == 'setup')  &&
            
              <div style={{paddingTop: '1.5vw', paddingRight: '1.5vw', display: 'flex', alignItems: 'flex-start'}}>
                <div style={{flex: 1, textAlign: 'right'}}>
                  <QuickAccessBar/>
                </div>
              </div>}
              <div style={{flex: 1, marginBottom: '5vw', width: '100vw'}}>
                {currentStep == 'complete' && <>{props.children}</>}

               

                {
                    currentStep == 'subscription' && <SubscriptionRequired setCurrentStep={setCurrentStep}/>
                }
                {
                    currentStep == 'setup' && <GayatriSetup setCurrentStep={setCurrentStep} currentStep={setUpCurrentStep}/>
                }
              </div>
            </div>
            
        </React.Fragment>
    );
}