import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Box, ButtonBase, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, ThemeProvider, createTheme, useTheme } from '@mui/material';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';




const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


export default (props) => {

    const theme = useTheme();

    const [availableTabs, setAvailableTabs] = useState([]);

    const auth = useAuth()
    const [selectedTabs, setSelectedTabs] = useState([])
    const [isSelectedAll, setIsSelectedAll] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)

    const handleChange = (event) => {
        
    };

    function onConfirm(){
        // props.onNext('integration')
        setIsLoading(true)

        let tabsToAdd = availableTabs.filter((obj) => selectedTabs.includes(obj.tab_id))

        fetch(`${window.origin}/api/creator/setup/workpaper`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                workpaper_tabs: tabsToAdd
            })
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }else{
                setIsLoading(false)
                return props.onNext('integration')
            }
        })



        console.log(tabsToAdd)
    }

    function selectToggle(e, tabId){


        setSelectedTabs(prevState => {
            let newState = [...prevState]
            const index = newState.indexOf(tabId);
            if (index > -1){
                newState.splice(index, 1); // 2nd parameter means remove one item only
            }else{
                newState.push(tabId)
            }
            
            return newState
        })

        console.log(selectedTabs)
    }


    useEffect(() => {
        fetch(`${window.origin}/api/creator/workpaper/available-tabs`, {
            method: 'GET',
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        })
        .then((tabs) => {
            setAvailableTabs(tabs)
        })
        .catch((error) => {
            console.error('Error fetching clients:', error);
        });
    }, [])


    return (
        <>
            <SessionExpiredModal show={sessionExpired}/>
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Gayatri,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>Workpaper Configuration</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please select tabs you want to include in your workpaper</p>
            </header>
            <div style={{ gap: '10px' , fontSize: '0.9rem'}}>
            
                {/* <Form.Check
                        type="checkbox"
                        id={`checkbox-${-1}`}
                        label={"Select All"}
                        key={"Select All"}
                        checked={isSelectedAll}
                        onChange={(e) => selectTab(e, "Select All")}
                        style={{ margin: '5px 0' }}
                    />
                {tabs.map((label, index) => (
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        label={label}
                        key={label}
                        checked={selectedTabs.includes(label)}
                        onChange={(e) => selectTab(e, label)}
                        style={{ margin: '5px 0' }}
                    />
                ))} */}
                <ThemeProvider theme={darkTheme}>
                    {availableTabs.map((tab, index) => 
                        <Chip key={index} label={tab.tab_name} style={{color: selectedTabs.includes(tab.tab_id)?'black':'white', marginRight: '0.75vw', marginBottom: '0.75vw', padding: '0.2vw 0.4vw', cursor: 'pointer', backgroundColor: selectedTabs.includes(tab.tab_id)?'rgb(255, 255, 255, 0.9)': 'rgb(255, 255, 255, 0.1)'}} onClick={(e) => selectToggle(e, tab.tab_id)} />
                    )}
                </ThemeProvider>
            </div>
                        
            <ButtonBase
                onClick={onConfirm}
                style={{
                    borderRadius: '4px',
                    marginTop: '2.5vh',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 20px',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600'
                }}
                disabled={isLoading}
            >
                {!isLoading && <><p style={{lineHeight: '1.6rem', margin: 0}}>Confirm</p></>}
                {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6rem'}}>
                    <div class="spinner-border" role="status" style={{width: '1.6rem', height: '1.6rem'}}>
                        <span class="sr-only">Loading...</span>
                    </div>
                    </div>}
            </ButtonBase>
        </>
    )
}
