import { createTheme, styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Button, Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));

export default function TransparentButton({ onClick, text, children, selected, style }) {

    return (
        <TextButton style={{borderRadius: '2vw', backgroundColor: selected? 'rgb(255, 255, 255, 0.2)':'rgb(255, 255, 255, 0)', borderColor: 'rgb(255, 255, 255, 0.03)', color: selected? 'rgb(255, 255, 255, 0.9)': 'rgb(255, 255, 255, 0.5)', padding: '0.3vw 1.4vw', fontWeight: '500', fontSize: '0.8vw', borderColor: 'rgb(255, 255, 255, 0)', ...style}} onClick={onClick}>{children}</TextButton>
    );
}
