

import { Button, createTheme, styled } from '@mui/material';
import { React, useState } from 'react'
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


export default ({show, onClose, onSubmit, code, codeChangeHandler, status}) => {
    
    
    return (
        <Modal style={{ backgroundColor: 'rgb(0, 0, 0, 0.1)', borderRadius: '1vw'}} contentClassName='whiteContentModal' show={show} onHide={onClose} centered>
            { (status == 'input' || status == 'submitting') && <>
            
                <Modal.Header style={{ border: 0, color: '#ee7170', padding: 0}}>
                </Modal.Header>
                <Modal.Body style={{padding: '1.2vw 2vw', textAlign: 'center'}}>
                    <p style={{fontWeight: '500', fontSize: '1.2vw', paddingTop: '1.3vw', paddingBottom: '1vw'}}>Microsoft Subscription</p>
                    <Form.Group controlId="formFileLg" className="mb-4">
                        <div><p style={{fontSize: '0.8vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.8)'}}>Please enter your Microsoft Subscription ID to activate your subscription.</p></div>
                    </Form.Group>
                    <Form.Group style={{flex: 1, textAlign: 'left'}}>
                        <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw', margin: 0}} type="text" placeholder="Subscription ID" value={code} onChange={codeChangeHandler}  disabled={status == 'submitting'}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{border: 0, alignItems: 'center', justifyContent: 'center'}}>
                {/* <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', flex: 1, textAlign: 'center'}} onClick={onClose}>
                    Close
                </p> */}
                <TextButton style={{fontSize: '0.9vw', width: '100%', margin: '0.5vw', fontWeight: '600', padding: '0.6vw', borderRadius: '10vw', backgroundColor: 'rgb(255, 255, 255, 0.08)', border: '1px solid rgb(255, 255, 255, 0.05)'}} onClick={onSubmit} disabled={status == 'submitting'}>Confirm</TextButton>
                </Modal.Footer>
            </>}
            { status == 'success' && <>
            
                <Modal.Header style={{ border: 0, color: '#ee7170', padding: 0}}>
                </Modal.Header>
                <Modal.Body style={{padding: '1.2vw 2vw', textAlign: 'center'}}>
                    <p style={{fontWeight: '500', fontSize: '1.2vw', paddingTop: '1.3vw', paddingBottom: '1vw'}}>Microsoft Subscription</p>
                    <Form.Group controlId="formFileLg" className="mb-4">
                        <div><p style={{fontSize: '0.8vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.8)'}}>Your subscription has been activated</p></div>
                    </Form.Group>
                    
                </Modal.Body>
                <Modal.Footer style={{border: 0, alignItems: 'center', justifyContent: 'center'}}>
                {/* <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', flex: 1, textAlign: 'center'}} onClick={onClose}>
                    Close
                </p> */}
                <TextButton style={{fontSize: '0.9vw', width: '100%', margin: '0.5vw', fontWeight: '600', padding: '0.6vw', borderRadius: '10vw', backgroundColor: 'rgb(255, 255, 255, 0.08)', border: '1px solid rgb(255, 255, 255, 0.05)'}} onClick={onClose}>Close</TextButton>
                </Modal.Footer>
            </>}

            { status == 'warning' && <>
            
                <Modal.Header style={{ border: 0, color: '#ee7170', padding: 0}}>
                </Modal.Header>
                <Modal.Body style={{padding: '1.2vw 2vw', textAlign: 'center'}}>
                    <p style={{fontWeight: '500', fontSize: '1.2vw', paddingTop: '1.3vw', paddingBottom: '1vw'}}>Microsoft Subscription</p>
                    <Form.Group controlId="formFileLg" className="mb-4">
                        <div><p style={{fontSize: '0.8vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.8)'}}>Your subscription ID is not valid. Please try again!</p></div>
                    </Form.Group>
                    
                </Modal.Body>
                <Modal.Footer style={{border: 0, alignItems: 'center', justifyContent: 'center'}}>
                {/* <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', flex: 1, textAlign: 'center'}} onClick={onClose}>
                    Close
                </p> */}
                <TextButton style={{fontSize: '0.9vw', width: '100%', margin: '0.5vw', fontWeight: '600', padding: '0.6vw', borderRadius: '10vw', backgroundColor: 'rgb(255, 255, 255, 0.08)', border: '1px solid rgb(255, 255, 255, 0.05)'}} onClick={onClose} disabled={status == 'success'}>Close</TextButton>
                </Modal.Footer>
            </>}
        </Modal>
            
    )
}