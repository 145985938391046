import React, { useState } from 'react';

function TaxAgentNumberSettings() {
    const [isEditing, setIsEditing] = useState(false);
    const [taxAgentNumber, setTaxAgentNumber] = useState('123 456 789');

    return (
        <div>
            <p style={{ fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.6)', fontWeight: '300' }}>
                Adjust your Tax Agent Number settings here.
            </p>
            
            <div 
                style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    backgroundColor: 'rgb(255, 255, 255, 0.05)', 
                    padding: '1vw 1.5vw', 
                    borderRadius: '5px', 
                    marginTop: '1.5vw', 
                    marginBottom: '1.5vw' 
                }}
            >
                <div style={{ flex: 1, fontWeight: '600', fontSize: '1vw' }}>
                    <p style={{ margin: 0 }}>Tax Agent Number</p>
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {isEditing ? (
                        <input
                            type="text"
                            value={taxAgentNumber}
                            onChange={(e) => setTaxAgentNumber(e.target.value)}
                            style={{
                                padding: '0',
                                borderRadius: '4px',
                                color: 'black',
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                fontSize: '1vw',
                                flex: 1,
                            }}
                            placeholder="Enter Tax Agent Number"
                        />
                    ) : (
                        <p style={{ textAlign: 'right', color: 'rgb(255, 255, 255, 0.7)', margin: 0, fontSize: '1vw', flex: 1 }}>
                            {taxAgentNumber}
                        </p>
                    )}

                </div>
            </div>
        </div>
    );
}

export default TaxAgentNumberSettings;
