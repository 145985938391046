import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';
import TemplateDetailModal from './TemplateDetailModal';
import { ButtonBase, CircularProgress, Grid, IconButton, ThemeProvider, createTheme, styled } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {formatDatetime} from '../../../utils/funtions'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


import { TextButton, darkTheme } from "../../../utils/funtions"
import CreateWorkpaperTemplate from './CreateWorkpaperTemplate';



const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    borderRadius: '0.5vw', 
    transition: '0.1s',
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        backgroundColor: 'rgb(255, 255, 255, 0.05)',
        '& .MuiImageBackdrop-root': {
        opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
        opacity: 0,
        },
        '& .MuiTypography-root': {
        border: '4px solid currentColor',
        },
    },
}));

function WorkpaperTemplateSettings() {
    const labels = [
        "General Ledger",
        "Balance Sheet",
        "Profit & Loss",
        "Wages",
        "Wages PAS",
        "Debtor",
        "Creditor",
        "Interest & Penalty",
        "Income Tax Statement (IT)",
        "Integrated Client Account (AS)",
        "GST",
        "GST Rec",
        "FR ACC",
    ];

    const auth = useAuth()

    const [tabData, setTabData] = useState([])

    const [detailModalShown, setDetailModalShown] = useState(false)

    const [currentTemplateId, setCurrentTemplateId] = useState('')

    const [currentTemplate, setCurrentTemplate] = useState('')

    const [currentCustomName, setCurrentCustomName] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const [sessionExpired, setSessionExpired] = useState(false)

    const [createTemplateShow, setCreateTemplateShow] = useState(false)


    function closeDetailModalHandler(){
        setDetailModalShown(false)
    }


    const [isEditing, setIsEditing] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState(
        labels.reduce((acc, label) => {
            acc[label] = false;
            return acc;
        }, {})
    );

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setCheckedItems(
            labels.reduce((acc, label) => {
                acc[label] = !selectAll;
                return acc;
            }, {})
        );
    };

    const handleChange = label => {
        setCheckedItems(prevState => {
            const newCheckedItems = { ...prevState, [label]: !prevState[label] };
            const allSelected = labels.every(l => newCheckedItems[l]);
            setSelectAll(allSelected);
            return newCheckedItems;
        });
    };

    const [ tableData, setTableData ] = useState(tabData)


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentitems] = useState(tabData.slice(indexOfFirstItem, indexOfLastItem))


    // Page change handlers
    const nextPage = () => {
        if (currentPage < Math.ceil(tableData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };



    const fetchData = () => {
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/creator/template`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response.json();
            }).then(data => {
                setTabData(data)
                setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }
    }


    const handleOpenCreateTemplate = () => {
        setCreateTemplateShow(true)
    }

    useEffect(() => {
        setTableData(tabData)
        console.log(tabData)
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(tabData.slice(indexOfFirstItem, indexOfLastItem))
    }, [tabData]);





    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(tabData.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);




    useEffect(()=> {
        fetchData()
    }, [auth.user?.profile.accountant.ssid])

    return (
        <>  
            <CreateWorkpaperTemplate show={createTemplateShow} onHide={() => {setCreateTemplateShow(false)}} onRefresh={fetchData}/>
            <SessionExpiredModal show={sessionExpired}/>
            <>

                <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5vw', width: '15vw', marginBottom: '1.5vw', width: '100%'}}>
                        <div style={{backgroundColor: 'rgb(255, 255, 255, 0.08)', borderRadius: '50px', padding: '0.2vw'}}>
                            <ThemeProvider theme={darkTheme}>

                                <IconButton aria-label="delete" style={{backgroundColor: 'rgb(255, 255, 255, 0.15)', padding: '0.5vw', fontSize: '0.5vw', color: 'rgb(255, 255, 255, 0.7)', cursor: 'none'}}>
                                    <SearchIcon style={{fontSize: '1vw'}}/>
                                </IconButton>
                            </ThemeProvider>
                            <Form.Control 
                                style={{
                                    display: 'inline',  backgroundColor: 'rgba(255,255,255,0)', padding: 0, paddingLeft: '0.8vw', paddingRight: '0.8vw',
                                    border: '1px solid rgb(255, 255, 255, 0)', borderRadius: '6px', fontSize: '0.9vw', maxWidth: '80%', marginLeft:'0' , fontWeight: '200'
                                }} 
                                className='shadow-none'
                                type="text" 
                                placeholder="Search" 
                                // value={searchQuery}
                                // onChange={}
                            />
                        </div>
                        <div style={{ flex: 1, textAlign: 'right', paddingLeft: '0.5vw'}}>
                            <OverlayTrigger
                                key={'top-submit'}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-submit`}>
                                        Create Template
                                    </Tooltip>
                                }
                                >
                                    <TextButton variant='text' style={{ fontSize: '0.9vw',cursor: 'pointer', marginLeft: '0.5vw', padding: '0.75vw', minWidth: 0, borderRadius: '2vw', backgroundColor: 'rgb(255, 255, 255, 0.1)', marginLeft: '1vw'}} onClick={handleOpenCreateTemplate}> 
                                        <i className="fa-solid fa-plus" style={{fontSize: '0.9vw', width: '0.95vw', height: '0.95vw'}}></i>
                                    </TextButton>
                                
                            </OverlayTrigger>
                        </div>
                </div>

                <div style={{margin: '0.5vw 0', marginTop: '0', padding: 0, fontSize: '1.6rem', borderRadius: '5vw'}}>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <Grid container spacing={2}>
                            { currentItems.map((row, index) => {
                                return (
                                    <Grid item xs={6} key={index} spacing={1}>
                                        <ImageButton focusRipple style={{width: '100%', textAlign: 'left', borderRadius: '1.2vw'}}>
                                        <div style={{width: '100%', backgroundColor: 'rgb(255, 255, 255, 0.06)', borderRadius: '1.2vw', cursor:'pointer', border: '0.05vw solid rgb(255, 255, 255, 0)'}} 
                                            onClick={() => {
                                                console.log(row)
                                                setCurrentTemplate(row)
                                                setCurrentTemplateId(row.id)
                                                setDetailModalShown(true)
                                            }}>
                                            <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center'}}>
                                                <IconButton style={{margin: '1vw', backgroundColor: 'rgb(255, 255, 255, 0.06)'}} disabled>
                                                    <ContentCopyIcon style={{margin: '0.5vw', fontSize: '2vw', color: 'rgb(255, 255, 255, 0.5)'}}/>
                                                </IconButton>
                                                <div style={{flex: 1, padding: '0.5vw', paddingLeft: '0'}}>
                                                    <p style={{fontSize: '0.9vw', margin: 0, lineHeight: '1.5vw', fontWeight: '600', color: 'rgb(255, 255, 255, 0.9)'}}>{row.name}</p>
                                                    <p style={{fontSize: '0.75vw', lineHeight: '1.5vw', margin: 0, fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)'}}>Last Modified: {formatDatetime(row.lastModified)}</p>
                                                </div>
                                            </div>
                                            {/* <div style={{padding: '1.3vw', paddingTop: '0'}}>
                                                <p style={{fontSize: '0.8vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.9)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Period:</span> {period}</p>
                                                <p style={{fontSize: '0.8vw', lineHeight: '1.5vw', margin: 0, fontWeight: '500', color: 'rgb(255, 255, 255, 0.85)'}}><span style={{color: 'rgb(255, 255, 255, 0.6)'}}>Latest Version:</span> {row.latest_version}</p>
                                                
                                            </div> */}
                                        </div>
                                        </ImageButton>
                                    </Grid>
                                )
                            })}
                        </Grid>

                        <div style={{ marginTop: '1vw', marginLeft: '0vw', marginRight: '0vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                            <div style={{flex: 1}}>
                                <p style={{ color: 'rgb(255, 255, 255, 0.6)', fontSize: '0.9vw', marginLeft: '0.2vw', marginTop: '1vw' }}>
                                    Showing {indexOfFirstItem+1} to {indexOfLastItem > tableData.length ? tableData.length: indexOfLastItem} of {tableData.length} entries
                                </p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button 
                                    onClick={prevPage} 
                                    disabled={currentPage === 1}
                                    style={{
                                        width: '2.5vw',
                                        height: '2.5vw',
                                        borderRadius: '0.5vw',
                                        marginRight: '6px',
                                        color: currentPage === 1 ? 'gray' : 'white',
                                        backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                        border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                        // color: 'white',
                                        cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '0.9vw',
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                                </button>
                        
                                <button 
                                    onClick={nextPage} 
                                    disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
                                    style={{
                                        width: '2.5vw',
                                        height: '2.5vw',
                                        borderRadius: '0.5vw',
                                        marginLeft: '6px',
                                        color: 'white',
                                        backgroundColor: 'rgb(255, 255, 255, 0.02)',
                                        border: '0.5px solid rgb(255, 255, 255, 0.3)',
                                        cursor: currentPage === Math.ceil(tableData.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '0.9vw',
                                    }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: '&rarr;'}} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
            <TemplateDetailModal show={detailModalShown} onClose={closeDetailModalHandler} tabId={currentTemplateId} template={currentTemplate} customName={currentCustomName} fetchDataHandler={fetchData}/>
        </>
    );
}

export default WorkpaperTemplateSettings;
