import Table from 'react-bootstrap/Table';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import React, { useState, useRef, useEffect } from 'react';

// navbar
import MenuBar from '../../../utils/MenuBar';
import GayatriNavBar from '../GayatriNavBar';


export default (props) => {

    const [data, setData] = useState([
      { userName: 'Alice Johnson', userEmail: 'alice.johnson@example.com', userRole: 'Admin' },
      { userName: 'Bob Smith', userEmail: 'bob.smith@example.com', userRole: 'User' },
      { userName: 'Charlie Davis', userEmail: 'charlie.davis@example.com', userRole: 'User' },
    ]);

    const handleRoleChange = (index, newRole) => {
      setData(prevData => {
        const newData = [...prevData];
        newData[index].userRole = newRole;
        return newData;
      });
    };

    const [searchQuery, setSearchQuery] = useState('');

    const filteredData = data.filter(
      (item) =>
        item.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.userEmail.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.userRole.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const userNameRef = useRef(null);
    const userEmailRef = useRef(null);

    const addUser = () => {
      // Getting the current values of the input fields
      const newUserName = userNameRef.current.value;
      const newUserEmail = userEmailRef.current.value;

      // Creating a new user object
      const newUser = {
        userName: newUserName,
        userEmail: newUserEmail,
        userRole: "User" // setting a default role, adjust as necessary
      };

      // Adding the new user to the data array
      setData((prevData) => [...prevData, newUser]);

      // Clearing the input fields after adding the new user
      userNameRef.current.value = '';
      userEmailRef.current.value = '';
    };

    const deleteUser = (index) => {
        setData(prevData => prevData.filter((_, i) => i !== index));
    };

    const handleEdit = (index) => {
        console.log("Edit user with index:", index);
        // ... (implement your editing logic here)
    };


    return (
        <div className={props.imgClass} style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row'}}>

            {/* navbar */}
            <MenuBar />

            <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'2vh', height:'100vh'}}>

                {/* Gayatri Nav Bar */}
                <GayatriNavBar />

                <div style={{}}>
                    {/* top button */}
                    <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row', padding:'20px 0px'}}>
                        {/* searchbox */}
                        <div style={{}}>
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{
                              width: '100%',
                              padding: '10px',
                              fontSize: '16px',
                              border: '1px solid white',
                              backgroundColor: 'transparent',
                            }}
                          />
                        </div>


                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', width:'300px', marginTop:'auto', marginBottom:'auto'}}>
                            <i onClick={handleShow}>Add User</i>
                        </div>
                    </div>

                    {/* popup for add User*/}
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                {/* User Name */}
                                <Form.Group controlId="formUserName" className="mb-3">
                                    <Form.Label>User Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter user name" style={{color: 'black'}} ref={userNameRef} />
                                </Form.Group>

                                {/* User Email */}
                                <Form.Group controlId="formUserEmail" className="mb-3">
                                    <Form.Label>User Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter user email" style={{color: 'black'}} ref={userEmailRef} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={addUser}>
                                Add User
                            </Button>
                        </Modal.Footer>
                    </Modal>



                    {/* table */}
                    <div style={{ border: '1px solid white' }}>
                      <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '1vw' }}>
                        <thead>
                          <tr>
                            <th style={{ padding: '8px', textAlign: 'center' }}>User Name</th>
                            <th style={{ padding: '8px', textAlign: 'center' }}>User Email</th>
                            <th style={{ padding: '8px', textAlign: 'center' }}>User Role</th>
                            <th style={{ padding: '8px', textAlign: 'center' }}></th>
                          </tr>
                        </thead>

                        <tbody>
                            {filteredData.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '8px' }}>{item.userName}</td>
                                    <td style={{ padding: '8px' }}>{item.userEmail}</td>
                                    <td style={{ padding: '8px' }}>
                                        <select
                                            style={{
                                                width: '100%',
                                                padding: '8px',
                                                border: '1px solid white',
                                                borderRadius: '4px',
                                                background: 'transparent',
                                                color: 'white'
                                            }}
                                            value={item.userRole}
                                            onChange={(e) => handleRoleChange(index, e.target.value)}
                                        >
                                            <option value="Admin">Admin</option>
                                            <option value="User">User</option>
                                            <option value="Guest">Guest</option>
                                        </select>
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="custom-dropdown-toggle">
                                                <i style={{color:'white'}} className="fas fa-ellipsis-v"></i>
                                            </Dropdown.Toggle>


                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleEdit(index)}>Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => deleteUser(index)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                      </table>
                    </div>


                </div>
            </div>
        </div>
    )
}
