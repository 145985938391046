import UsersConfig from './UsersConfig';

import React, { useState } from 'react';
import MenuBar from '../../utils/MenuBar';
import { Button, Form } from 'react-bootstrap';
import CompanySetting from './CompanySetting';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import BGD from "../../resources/BGD.png";

import ProfileSettings from './ProfileSettings';
import { useAuth } from 'react-oidc-context';
import QuickAccessBar from '../../utils/QuickAccessBar';
import { ButtonBase } from '@mui/material';

export default (props) => {

    const auth = useAuth()

    const [currentComponent, setCurrentComponent] = useState(auth.user?.profile.groups.includes("client-admin")? 'Users': 'CompanySettings');

    const [searchTerm, setSearchTerm] = useState("");

    const [currentTitle, setCurrentTitle ] = useState(auth.user?.profile.groups.includes("client-admin")? 'Manage Users': 'Company')


    const handleClick = (componentName, titleName) => {
        setCurrentComponent(componentName);
        setCurrentTitle(titleName)
    };

    return (
        <div style={{background: 'linear-gradient(45deg, #120922 0%, #2B1552 100%)' }}>
            <div className={props.imgClass} style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row', padding: 0}}>

                {/* navbar */}
                {/* <Button onClick={props.menuBarOpen} color="primary" aria-label="add" style={{marginLeft: '1vw', marginTop: '1vw', width: '3vw', height: '2.9vw', backgroundColor: 'rgb(255, 255, 255, 0.2)', border: '1px solid rgb(255, 255, 255, 0.04)', borderRadius: '5vw'}}>
                    <MoreHorizIcon />
                </Button> */}

                <div style={{display:'flex', flexDirection:'column', width:'100%', padding:'2vh', height:'100vh', padding: 0}}>
                    
                    {/* top */}
                

                    {/* bottom */}
                    <div style={{display:'flex', flexDirection:'row', width:'100%', height:'100vh', paddingTop:'40px', paddingLeft: '1vw', padding:0}}>
                        {/* left */}
                        <div style={{ width: '50%', height: '100vh', overflowY: 'auto', textAlign: 'left', flex: 3, padding: '3vw', paddingRight: '0'}}>
                            <h4 style={{ fontSize: '1.4vw' , fontWeight: '400', marginBottom: '1.9vw', color: 'rgb(255, 255, 255, 0.7)'}}>Settings</h4>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , paddingRight: '5vw'}}>
                                { auth.user?.profile.groups.includes("client-admin") && <ButtonBase
                                    onClick={() => handleClick('Users', 'Users')}
                                    
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        marginBottom: '1vw',
                                        padding: '1.3vw',
                                        borderRadius: '0.5vw',
                                        width: '100%',
                                        background: currentComponent === 'Users' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.04)',
                                        color: currentComponent === 'Users' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                    }}
                                >
                                    <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Users</h3>
                                    <p style={{ fontSize: '0.9vw', margin: 0, color: currentComponent === 'Users' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Manage user access and engagement settings.</p>
                                </ButtonBase>}
                                <ButtonBase
                                    onClick={() => handleClick('CompanySettings', 'Company')}
                                    
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        marginBottom: '1vw',
                                        padding: '1.3vw',
                                        borderRadius: '0.5vw',
                                        width: '100%',
                                        background: currentComponent === 'CompanySettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.04)',
                                        color: currentComponent === 'CompanySettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                    }}
                                >
                                    <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Company</h3>
                                    <p style={{ fontSize: '0.9vw', margin: 0, color: currentComponent === 'CompanySettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Adjust company details and operational preferences.</p>
                                </ButtonBase>
                                <ButtonBase

                                    onClick={() => handleClick('ProfileSettings', 'Profile')}
                                    
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        marginBottom: '1vw',
                                        padding: '1.3vw',
                                        borderRadius: '0.5vw',
                                        width: '100%',
                                        background: currentComponent === 'ProfileSettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.04)',
                                        color: currentComponent === 'ProfileSettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                    }}
                                >
                                    <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Profile</h3>
                                    <p style={{ fontSize: '0.9vw', margin: 0, color: currentComponent === 'ProfileSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Update personal information and interaction preferences.</p>
                                </ButtonBase>
                                {/* <ButtonBase
                                    onClick={() => handleClick('PaymentSettings', 'Payment')}
                                    style={{
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        marginBottom: '1vw',
                                        padding: '1.3vw',
                                        borderRadius: '0.5vw',
                                        width: '100%',
                                        background: currentComponent === 'PaymentSettings' ? 'rgb(220, 220, 220)' : 'rgb(255, 255, 255, 0.04)',
                                        color: currentComponent === 'PaymentSettings' ? 'rgb(50, 50, 50)' : 'rgb(255, 255, 255, 0.8)',
                                    }}
                                >
                                    <h3 style={{ fontSize: '1.1vw', fontWeight: '550', paddingBottom: '0.1vw'}}>Payment</h3>
                                    <p style={{ fontSize: '0.9vw', margin: 0, color: currentComponent === 'PaymentSettings' ? 'rgb(100, 100, 100)' : 'rgb(255, 255, 255, 0.6)'}}>Configure payment methods and transaction settings.</p>
                                </ButtonBase> */}
                            </div>
                        </div>

                        {/* right */}
                        <div style={{ width: '50%', height: '80vh', overflowY: 'auto', flex: 5, textAlign: 'left', padding: '1.5vw'}}>
                            <div style={{textAlign: 'right'}}>
                                <QuickAccessBar/>
                            </div>
                            <div style={{padding: '1.5vw', paddingTop: '3vw'}}>
                                {currentComponent === 'CompanySettings' && <CompanySetting/>}
                                {currentComponent === 'ProfileSettings' && <ProfileSettings />}
                                {currentComponent === 'Users' && <UsersConfig />}
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    )
}

