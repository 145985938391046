
import Button from 'react-bootstrap/Button';

import fileDownload from 'js-file-download'

import { React, useState } from 'react'
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';


import axios from 'axios';

import Papa from "papaparse";

import { v4 as uuid } from 'uuid';
import { Offcanvas } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';

import validator from 'validator';

const roleValue = {
    'User': 1,
    'Manager': 2,
    'Owner': 3
};

function isValidEmail(email) {

    if(!email) {
        return false
    }

    const result = validator.isEmail(email+'');
    if(result === true) {
        return true
    } else {
        return false
    }
}


function isValidABN (ABN) {
    if(!ABN) {
        return false
    }

    const result = validator.isNumeric(ABN) && ABN.length === 11;
    if(result) {
        return true
    } else {
        return false
    }
}


export default ({uploadModalShow, closeUploadModal}) => {


    const [ uploadedUsers, setUploadedUsers ] = useState([])

    const [ uploadAllowed, setUploadAllowed ] = useState(false)

    const [ message, setMessage ] = useState('')

    const [sessionExpired, setSessionExpired] = useState(false)

    const auth = useAuth()

    function changeHandler(event){
        setUploadAllowed(false)
        let count = 2
        // try{
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                try{
                    let users = results.data.map(user => {
                        
                        if(!['Admin', 'User'].includes(user["Role"])){
                            throw 'Invalid Role'
                        }

                        if(!isValidEmail(user["Email"])){
                            throw 'Invalid Email'
                        }
                        count ++
                        return {
                            email: user["Email"],
                            role: user["Role"]
                        }
                    })
                    
                    users.sort((a, b) => {
                        return roleValue[b.role] - roleValue[a.role];
                    });
                    
                    const uniqueUsers = [];
                    const emailsSeen = new Set();
                    
                    users.forEach(user => {
                        if (!emailsSeen.has(user.email)) {
                            emailsSeen.add(user.email);
                            uniqueUsers.push(user);
                        }
                    });
                    console.log(uniqueUsers)
                    setUploadedUsers(uniqueUsers)
                    setUploadAllowed(true)
                    setMessage(`CSV file validated!`)
                }catch(e){
                    setMessage(`Error at row ${count}: ${e}`)
                }
            }
        });
        // }catch(e){
        //     console.log(`Error at row ${count}`)
        //     console.log(e)
        // }
      
    }


    function inviteBulkUsersHandler(){
        for(let user of uploadedUsers){
            fetch(`${window.origin}/api/users/invite`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                },
                body: JSON.stringify({
                    username: user.email,
                    role: user.role
                })
            })
            closeUploadModal()
        }
        
    }


    
    
    return (
        <Offcanvas show={uploadModalShow} onHide={closeUploadModal} placement="end" style={{backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(50px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '20px'}}>
                
                <div style={{flex: 1}}>
                    <Offcanvas.Header>
                    <Offcanvas.Title style={{fontSize: '1.5rem'}}>Invite Bulk Users</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{paddingTop: '2rem', height: '100%'}}>
                        <Form.Group controlId="formFileLg" className="mb-3">
                            <p style={{fontSize: '1.1rem', fontWeight: '500'}}>Instructions</p>
                            <p style={{marginBottom: 0}}>1. Fill in the CSV template</p>
                            <div style={{padding: '10px 0'}}>
                                <a style={{fontSize: '0.9rem', fontWeight: '600', cursor: 'pointer', textDecoration: 'none', borderRadius: '50px', padding: '10px 25px', margin: '5px 0px'}} className="btn btn-primary" href={require('../../resources/UserTemplate.csv')} download={'CustomerTemplate.csv'}>Download template</a>
                            </div>

                            <Form.Label style={{marginBottom: '20px', marginTop: '2rem'}}>2. Upload the CSV template</Form.Label>
                            <div class="">

                                    <div class="form-group files">
                                        <input type="file" class="form-control" onChange={changeHandler} multiple="" accept=".csv"/>
                                    </div>
    
                            </div>
                            <p style={{paddingTop: '1.5rem'}}>{message}</p>
                        </Form.Group>
                        
                    </Offcanvas.Body>

                </div>
                <div style={{textAlign: 'right', paddingBottom: '1.5rem'}}>
                    <span style={{ color: 'rgb(255, 255, 255, 0.9)', fontWeight: '600', cursor: 'pointer', padding: '1.2rem'}} onClick={closeUploadModal}>
                        Close
                    </span>
                    <button type='button' style={{color: uploadAllowed? 'rgb(255, 255, 255, 0.9)': 'rgb(255, 255, 255, 0.6)', fontWeight: '600', padding: '1rem', padding: 0, backgroundColor: 'transparent', border: 0, marginRight: '0.8rem'}} onClick={inviteBulkUsersHandler} disabled={!uploadAllowed}>
                        Invite
                    </button>
                </div>
        </Offcanvas>
            
    )
}