import { useNavigate } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';

import Form from 'react-bootstrap/Form';

import CircleSteps from './CircleSteps';

import MenuBar from '../../../utils/MenuBar';
import { useAuth } from 'react-oidc-context';
import { useState } from 'react';


export default (props) => {

    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(false)

    const [ emailSent, setEmailSent ] = useState(false)

    const navigate = useNavigate()

    function sendEmailHandler(){
        // openLoading("Sending email ...")
        fetch(`${window.origin}/api/sendemail`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        }).then(response => response.status
        ).then(status => {
            console.log(status)
        })
        setEmailSent(true)
    }

    function naviagteATOPortal(){
        window.open('https://www.ato.gov.au/General/Online-services/Access-Manager/About-Access-Manager/#LogintoAccessManager','_blank')
    }

    function onConfirm(){
        setIsLoading(true)
        fetch(`${window.origin}/api/setup/ato-access`, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if(response.status === 403){
              auth.signinRedirect()
              throw new Error('Session expired');
            }else{
                return navigate('/mydigitalworkers/Scout/requests')
            }
        })
    }

    return (
        <>
            <header>
                <p style={{margin: '0px', color: 'white', fontSize: '1vw', fontWeight: 300}}>
                    <span style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '1vw'}}>Welcome to</span> Scout,
                </p>
                <h1 style={{fontSize: '1.5vw', marginBottom: '1vw'}}>ATO Access</h1>
                <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please follow the steps below to configure your ATO access</p>
            </header>
            <p style={{fontSize: '1.3rem', marginBottom: '.8vh'}}>Step 1</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please click on Email button to receive your software service ID (SSID).</p>           
            <p style={{fontWeight: '600', cursor: 'pointer'}} onClick={sendEmailHandler}>{ !emailSent && 'Send Email'}{ emailSent && 'Resend Email'}</p>
            <p style={{marginTop: '2vh', fontSize: '1.3rem', marginBottom: '.8vh'}}>Step 2</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Log in to Access Manager and update the SSID as provided to you via email.</p>           
            <p style={{fontWeight: '600', cursor: 'pointer'}} onClick={naviagteATOPortal}>Go to ATO Portal</p>
            <p style={{marginTop: '2vh', fontSize: '1.3rem', marginBottom: '.8vh'}}>Step 3</p>
            <p style={{color: 'rgba(255, 255, 255, 0.75)', fontSize: '0.85vw', margin: 0, marginBottom: '1vw'}}>Please confirm that you have successfully configured your ATO access.</p>           
            <button
                onClick={onConfirm}
                style={{
                    borderRadius: '4px',
                    background: '#FE475B',
                    border: 'none',
                    color: 'white',
                    padding: '0.7vw 20px',
                    width:'100%',
                    cursor: 'pointer',
                    fontWeight: '600'
                }}
                disabled={isLoading}
            >
                {!isLoading && <><p style={{lineHeight: '1.6rem', margin: 0}}>Confirm</p></>}
                {isLoading && <div class="d-flex justify-content-center" style={{height: '1.6rem'}}>
                    <div class="spinner-border" role="status" style={{width: '1.6rem', height: '1.6rem'}}>
                        <span class="sr-only">Loading...</span>
                    </div>
                    </div>}
            </button>
        </>
    )
}
