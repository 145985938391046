import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuBar from '../../../utils/MenuBar';
import MessageModal from '../../../utils/MessageModal';


export default function SubscriptionRequired() {

    const { state } = useLocation()

    const navigate = useNavigate()

    const icon = state.status == 'success'? "fa-solid fa-check" : state.status == 'error'? "fa-solid fa-xmark" : "fa-regular fa-envelope"

    return (
        <div style={{textAlign: 'center', position: 'relative', height: '100%', display:'flex', flexDirection:'row'}}>

            <MenuBar />

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
                <p style={{paddingTop: '4vw', fontWeight: '400', fontSize: '3vw'}}>{state.shortMessage}</p>
                <p  style={{paddingTop: '1vw', fontWeight: '400', fontSize: '1vw', color: 'rgb(255, 255, 255, 0.7)'}}>{state.descriptionMessage}</p>

                <i style={{fontSize: '20vw', paddingTop: '2vw', color: 'rgb(255, 255, 255, 0.7)'}} class={icon}></i>
                {state.status == 'success' && <>
                <button
                    style={{
                        borderRadius: '4px',
                        background: '#FE475B',
                        border: 'none',
                        color: 'white',
                        padding: '0.7vw 1vw',
                        width:'10%',
                        cursor: 'pointer',
                        fontWeight: '600',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '2vw',
                        fontSize: '0.9vw'
                    }}
                    onClick={() => {navigate(state.destination)}}
                >Continue</button>
            </>}
            </div>


            
            
        </div>
    );
}
