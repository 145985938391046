import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


import styles from '../Gayatri.module.css'
import { Box, Button, CircularProgress, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { setStep } from '../../../app/stepSlice';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Form } from 'react-bootstrap';
import { SnackbarContext } from '../../../App';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });





export default ({show, onHide, accountType, fetchData}) => {

    const auth = useAuth()

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);
    

    const [sessionExpired, setSessionExpired] = useState(false)

    const [username, setUsername] = useState('')

    const [password, setPassword] = useState('')

    const [mfaCode, setMfaCode] = useState('')

    const [isLoading, setIsLoading] = useState(true)

    const [buttonDisable, setButtonDisable] = useState(true)


    useEffect( () => {
        setIsLoading(true)
        fetch(`${window.origin}/api/creator/as-accounts/credential?acctype=${accountType}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            } else {
                return response.json()
            }
        }).then(data => {
            setUsername(data.username)

            setPassword(data.pwd)

            setMfaCode(data.mfa_setup_key)
            setIsLoading(false)
            // setIsLoading(false)
        }).catch((error) => {
            console.error(error);
            setIsLoading(false)
        });
    }, [accountType])

    function saveCredential(){
        if (username.length == 0){
            setSnackBarMessage('Username cannot be empty')
            setSnackBarShow(true)
            return
        }
        if (password.length == 0){
            setSnackBarMessage('Password cannot be empty')
            setSnackBarShow(true)
            return
        }
        setTimeout(() => {
            setButtonDisable(true)
        }, 300)
        fetch(`${window.origin}/api/creator/as-accounts/credential?acctype=${accountType}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                username: username,
                password, password,
                setupKey: mfaCode
            })
        }).then(response => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }

            fetchData()
            setSnackBarMessage('Credential updated')
            setSnackBarShow(true)
            

        }).catch((error) => {
            console.error(error);
            setSnackBarMessage('Unknown error occurred, Please try again later')
            setSnackBarShow(true)
        });
    }

    useEffect(() => {
        setButtonDisable(false)
    }, [username, password, mfaCode])

    return (
        
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <SessionExpiredModal show={sessionExpired}/>
            
            <Modal.Body style={{padding: '2vw 3vw', textAlign: 'center'}}>

                
                
                    <h4 style={{textAlign: 'center', fontSize: '1.3vw', marginBottom: '2vw', fontWeight: '500'}}>{String(accountType).toUpperCase()} Account</h4>

                    <p style={{margin: 0, marginBottom: '2.5vw', fontWeight: '300', fontSize: '0.8vw', padding: '0 2vw', color: 'rgb(255, 255, 255, 0.7)'}}>Please configure account credential for Gayatri to access your {String(accountType).toUpperCase()} environment.</p>

                    

                    {isLoading && <CircularProgress  style={{marginBottom: '1vw', color: 'white'}}/>}

                {!isLoading && <div>
                    <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                        <p style={{fontSize: '0.9vw', marginBottom: '0.5vw'}}>Username</p>
                        <Form.Control style={{height: '3vw', backgroundColor: 'rgba(0,0,0,.2)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                        <p style={{fontSize: '0.9vw', marginBottom: '0.5vw'}}>Password</p>
                        <Form.Control style={{height: '3vw', backgroundColor: 'rgba(0,0,0,.2)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>
                    <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                        <p style={{fontSize: '0.9vw', marginBottom: '0.5vw'}}>MFA Setup Key</p>
                        <Form.Control style={{height: '3vw', backgroundColor: 'rgba(0,0,0,.2)', border: 'none', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="password" placeholder="Key" value={mfaCode} onChange={(e) => setMfaCode(e.target.value)}/>
                    </Form.Group>

                    <Button disabled={buttonDisable} onClick={saveCredential} variant="outlined" style={{
                        borderColor: 'rgb(255, 255, 255, 1)', color: 'rgb(255, 255, 255, 0.9)', padding: '0.7vw', marginBottom: '1vw', border: 'none', backgroundImage: 'linear-gradient(rgb(255, 255, 255, 0.05), rgb(10, 10, 10, 0.05)),linear-gradient(#f25252, #f25252)', borderRadius: '0.5vw', textTransform: 'none', fontSize: '1vw', fontWeight: 500, letterSpacing: '0.08vw'
                    , width: '100%', marginTop: '2vw'}}>Save</Button>
                </div>}





            </Modal.Body>
        </Modal>
    )
}



