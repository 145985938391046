import { Button, CircularProgress, createTheme, styled } from '@mui/material';
import { React, useEffect, useState } from 'react'
import { ButtonGroup, Offcanvas, ToggleButton } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useAuth } from 'react-oidc-context';
import SessionExpiredModal from '../../../utils/SessionExpiredModal';


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default ({show, onClose, userId}) => {

    const auth = useAuth()

    const [firstName, setFirstName] = useState('');

    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');

    const [role, setRole] = useState('');

    const [isLoading, setIsLoading] = useState(false)

    const [sessionExpired, setSessionExpired] = useState(false)


    function saveRoleHandler(userId, newRole){
        fetch(`${window.origin}/api/creator/users/${userId}/role`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.user?.access_token}`
            },
            body: JSON.stringify({
                "desiredRole": newRole
            })
        }).then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        }).catch((error) => {
            console.error(error);
        });
        onClose()
    }

    function closeHandler(){
        onClose()
        setTimeout(()=> {
            setFirstName('')
            setLastName('')
            setEmail('')
            setRole('')
        }, 200)
    }
    
    useEffect(() => {
        if(userId !== ''){
            setIsLoading(true)
            fetch(`${window.origin}/api/creator/users/${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then((response) => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response.json();
            }).then(data => {

                console.log(data)
                setFirstName(data.firstName)

                setLastName(data.lastName)

                setEmail(data.email)

                setRole(data.role)
                setIsLoading(false)
            }).catch((error) => {
                console.error(error);
            });
        }
        
    }, [userId]);
    
    return (
        <>
            <SessionExpiredModal show={sessionExpired}/>
            <Offcanvas show={show} onHide={closeHandler} placement="end" style={{width: '23vw', backgroundColor: 'rgb(32, 30, 34, 0.7)', backdropFilter: 'blur(50px)', '-webkit-backdrop-filter': 'blur(50px)',border: '1px solid rgb(255, 255, 255, 0.2)', paddingLeft: '1vw', paddingRight: '1vw', paddingTop: '1vw', color: 'white', display: 'flex', flexDirection: 'column', margin: '1rem', borderRadius: '2vw'}}>
                <div style={{flex: 1}}>
                    <Offcanvas.Header style={{padding: '1vw'}}>
                    <Offcanvas.Title style={{fontSize: '1.3vw'}}>{firstName} {lastName}</Offcanvas.Title>
                    </Offcanvas.Header>
                    { !isLoading && <Offcanvas.Body style={{paddingTop: '2vw', height: '100%', padding: '1vw'}}>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>First Name</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="email" placeholder="First Name" value={firstName} disabled/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Last Name</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '0.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="email" placeholder="Last Name" value={lastName} disabled/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '2vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Email Address</p>
                            <Form.Control style={{height: '2vw', backgroundColor: 'rgba(255,255,255,.05)', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '.5vw', fontSize: '0.8vw', paddingLeft: '0.8vw'}} type="email" placeholder="Email Address" value={email} disabled/>
                        </Form.Group>
                        <Form.Group style={{marginBottom: '3vw', flex: 1, textAlign: 'left'}}>
                            <p style={{fontSize: '0.9vw', marginBottom: '1vw'}}>Role</p>
                            <p style={{fontSize: '0.75vw', color: 'rgb(255, 255, 255, 0.6)'}}>The role selected corresponds to the level of access this user has in Gayatri.</p>

                            <div class="dropdown">

                                <ButtonGroup className="btn-group-toggle" style={{}}>
                                    <ToggleButton
                                        key={1}
                                        id={`radio-1`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='ED'
                                        onChange={() => setRole('Admin')}
                                        style={{
                                            borderTopLeftRadius: '0.2vw',
                                            borderBottomLeftRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.3vw 0.75vw',
                                            backgroundColor: role == 'Admin' ? 'white' : '',
                                            borderColor: role == 'Admin' ? 'white' : '',
                                            color: role == 'Admin' ? 'black' : '', // Text color for active button
                                        }}                                            
                                    >
                                        Admin
                                    </ToggleButton>
                                    <ToggleButton
                                        key={2}
                                        id={`radio-2`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='FY'
                                        onChange={() => setRole('User')}
                                        style={{
                                            borderTopRightRadius: '0.2vw',
                                            borderBottomRightRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.3vw 0.75vw',
                                            backgroundColor: role == 'User' ? 'white' : '',
                                            borderColor: role == 'User' ? 'white' : '',
                                            color: role == 'User' ? 'black' : '', // Text color for active button
                                        }}                                            
                                    >
                                        User
                                    </ToggleButton>
                                </ButtonGroup>
                            </div>
                        </Form.Group>
                        
                    </Offcanvas.Body>}
                    { isLoading && <>
                        <div style={{textAlign: 'center', paddingTop: '35vh'}}>
                            <CircularProgress color="inherit" />
                        </div>
                    </>}
                </div>
                <div style={{textAlign: 'right', paddingBottom: '1.5vw', paddingRight: '1vw'}}>

                    <TextButton variant='outlined' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', padding: '0.25vw 1.5vw', marginRight: '1.25vw', borderRadius: '1.5vw', textDecoration: 'none' , borderColor: 'rgb(255, 255, 255, 0.15)', backgroundColor: 'rgb(0, 0, 0, 0.1)'}}  onClick={closeHandler}>
                        Close
                    </TextButton>
                    <Button variant='contained' style={{ fontSize: '0.9vw', color: 'white', fontWeight: '500', cursor: 'pointer', padding: '0.25vw 1.5vw', borderRadius: '1.5vw', backgroundColor: '#f25252', borderColor: '#f25252', textTransform: 'none'}}  onClick={() => saveRoleHandler(userId, role)}>
                        Save
                    </Button>
                </div>

                
            </Offcanvas>

        </>
            
    )
}