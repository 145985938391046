

import { Button, createTheme, styled } from '@mui/material';
import { React, useState } from 'react'
import Form from 'react-bootstrap/Form';

import Modal from 'react-bootstrap/Modal';

import { useAuth } from "react-oidc-context";


const TextButton = styled(Button)(({ theme }) => ({
    color: 'white',
    textTransform: 'none',
    padding: '0.5vw 0.75vw'
  }));


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


export default ({show, onClose}) => {
    
  const auth  = useAuth()
    
    return (
        <Modal style={{ backgroundColor: 'rgb(0, 0, 0, 0.1)', borderRadius: '1vw'}} contentClassName='whiteContentModal' show={show} onHide={onClose} centered>
                <Modal.Header style={{ border: 0, color: '#ee7170', padding: 0}}>
                </Modal.Header>
                <Modal.Body style={{padding: '1rem 2rem', textAlign: 'center'}}>
                    <p style={{fontWeight: '500', fontSize: '1.3rem', paddingTop: '1.3rem', paddingBottom: '1rem'}} >Session Expired</p>
                    <Form.Group controlId="formFileLg" className="mb-4">
                        <div><p style={{fontSize: '1rem', fontWeight: '400', color: 'rgb(255, 255, 255, 0.8)'}}>Your session has expired. Please sign in again!</p></div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer style={{border: 0, alignItems: 'center', justifyContent: 'center'}}>
                {/* <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer', flex: 1, textAlign: 'center'}} onClick={onClose}>
                    Close
                </p> */}
                <TextButton style={{fontSize: '1.1rem', width: '100%', margin: '0.5vw', fontWeight: '600', padding: '0.6rem', borderRadius: '10vw', backgroundColor: 'rgb(255, 255, 255, 0.08)', border: '1px solid rgb(255, 255, 255, 0.05)'}} onClick={async () => {
                  await auth.signoutSilent();
                  await auth.signinRedirect()
                  }}>Sign In</TextButton>
                </Modal.Footer>
            </Modal>
            
    )
}