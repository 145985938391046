import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import MenuBar from '../../utils/MenuBar';

import { useParams } from 'react-router-dom';


export default function DetailStore(props) {
    const auth = useAuth();
    const navigate = useNavigate();
    const pricingSectionRef = useRef(null);

    const [hired, setHired] = useState(false)
    
    const [detailStoreData, setDetailStoreData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    
    useEffect(() => {
        setIsLoading(true);
        console.log("Fetching data for worker ID:", id); // Here is the log statement
        
        fetch(`${window.origin}/api/worker-id?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            setDetailStoreData(data);
            setIsLoading(false);
            setHired(data.exists)
            console.log(data)
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            setError("Failed to fetch data. Please try again later.");
            setIsLoading(false);
        });
    }, [auth, id]);
    
    
    function handleHireNowClick() {
        fetch(`${window.origin}/api/subscribe?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.user?.access_token}`
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            setHired(true)
        })
        .catch(error => {
            console.error("Error fetching data:", error);
            setError("Failed to fetch data. Please try again later.");
            setIsLoading(false);
        });
    }

    if(isLoading) {
        return <p>Loading...</p>;
    }
    
    if(error) {
        return <p>Error: {error}</p>;
    }
    
    if(!detailStoreData) {
        return <p>No data found for the requested worker.</p>;
    }
    

    return (
        <div className={props.imgClass} style={{ textAlign: 'center', position: 'relative', display: 'flex', flexDirection: 'row', overflowY:'hidden'}}>
            <div style={{ position: 'fixed', top: 0, left: 0, height: '100%', zIndex: 1000, background: '' }}>
                <MenuBar />
            </div>
            <div style={{ marginLeft: '100px', display: 'flex', flexDirection: 'column', width: '100%', padding: '2vh', overflowY: 'auto'}}>
                <div style={{ height: '100vh', display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                    <div style={{ width: '50%', textAlign: 'left', padding: '5vw', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <h3 style={{ fontSize:'2vw', marginBottom: '0.5vw' }}>{detailStoreData.name}</h3>
                        <h4 style={{ fontSize:'1.5vw', color: 'rgba(255, 255, 255, 0.65)', marginBottom: '1vw' }}>{detailStoreData.title}</h4>
                        <p style={{ fontSize:'1vw', color: '#FFF', marginBottom: '1.5vw', lineHeight: '1.5' }}>{detailStoreData.description}</p>
                        { !hired && <Button style={{ fontSize:'1vw', width: '30%' }} onClick={handleHireNowClick}>Hire Now</Button>}
                        { hired && <Button style={{ fontSize:'1vw', width: '30%' }} onClick={() => {navigate(detailStoreData.url)}}>Meet {detailStoreData.name}</Button>}
                    </div>
                    <div style={{
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>

                {/* pricing */}
                {/* <div ref={pricingSectionRef} style={{ height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                            margin: '10px',
                            borderRadius: '10px',
                            background: '#501E70', 
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <h2 style={{ color: '#FEB692' }}>Free Package</h2>
                        <Button onClick={() => { navigate(detailStoreData.url) }} 
                                style={{ 
                                    marginTop: '20px', 
                                    background: '#FE475B', 
                                    borderColor: '#FE475B', 
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', 
                                    fontSize: '2vw'
                                }}
                        >
                            Select
                        </Button>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
