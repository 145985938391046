import Header40 from "../../utils/Header40"

export default (props) => {

    return (
        <>
            <Header40 shortText='Welcome to Specia'
            longText='Welcome to the Future of Digital Work Solutions!'
            longerText='Our platform provides a comprehensive suite of cutting-edge digital solutions, ranging from workflow automation to advanced data analytics, all designed to enhance productivity, streamline operations, and usher your business into a more efficient and profitable future'
            imgClass='updradeoptionsheaderdiv'
            menuBarOpen={props.menuBarOpen} menuBarClose={props.menuBarClose}/>
        </>
    )
}
