/* eslint-disable import/no-anonymous-default-export */


import { CircularProgress, List, ListItemButton, ListSubheader, ThemeProvider, createTheme } from '@mui/material';
import { React, useState, useEffect } from 'react'

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default ({userData, openModalHandler, isLoading}) => {

    const [ tableData, setTableData ] = useState(userData)

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    let indexOfLastItem = currentPage * itemsPerPage;
    let indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentitems] = useState(userData.slice(indexOfFirstItem, indexOfLastItem))


    // Page change handlers
    const nextPage = () => {
        if (currentPage < Math.ceil(tableData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    useEffect(() => {
        setTableData(userData)
        console.log(userData)
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(userData.slice(indexOfFirstItem, indexOfLastItem))
    }, [userData]);


    useEffect(() => {
        // Calculate the items for the current page
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentitems(userData.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);

    return (
        <>
            {/* <div style={{lineHeight: '2.2vw', border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '1vw'}}> */}
                {/* <table className="table table-hover table-dark" style={{ color: 'white', fontSize: '0.9vw', width: '100%', backgroundColor: 'transparent', margin: 0}}>
                    <thead style={{color: 'rgb(230, 230, 230)', borderBottom: '1px solid rgb(255, 255, 255, 0.1)', backgroundColor: 'rgb(255, 255, 255, 0.03)'}}>
                        <tr style={{}}>
                            <th style={{padding:'0.5w 1vw', paddingLeft:'1.5vw', width: '25%'}} scope="col">First Name</th>
                            <th style={{padding:'0.5w 1vw', width: '25%'}} scope="col">Last Name</th>
                            <th style={{padding:'0.5w 1vw', width: '50%'}} scope="col">Email Address</th> 
                        </tr>
                    </thead>
                    
                    <tbody style={{fontSize: '0.85vw'}}>
                        { !isLoading && !tableData.length && 
                        <tr>
                            <td colspan="7"><p style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}>No user found</p></td>
                        </tr>}
                        { isLoading && 
                        <tr>
                            <td colspan="7"><p style={{textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}><CircularProgress color="inherit" /></p></td>
                        </tr>}
                        { currentItems.map(user => 
                            <tr style={{borderTop: '1px solid rgb(255, 255, 255, 0.1)', cursor: 'pointer'}} onClick={() => openModalHandler(user.id)}>
                                <td style={{padding:'0.5w 1vw', paddingLeft:'1.5vw'}} scope="row">{user.firstName}</td>
                                <td style={{padding:'0.5w 1vw'}}>{user.lastName}</td>
                                <td style={{padding:'0.5w 1vw'}}>{user.email}</td>
                            </tr>
                        )}
                    </tbody>
                </table> */}
                <ThemeProvider theme={darkTheme}>

                    {/* table */}
                    <div style={{lineHeight: '2vw',border: '1px solid rgb(255, 255, 255, 0.15)', borderRadius: '1.5vw', textAlign: 'left'}}>
                        <List className="table table-hover table-dark" style={{ color: 'rgb(255, 255, 255, 0.9)', borderRadius: '1.5vw', fontSize: '0.75vw', backgroundColor: 'rgb(255, 255, 255, 0.0)', margin: 0, padding: 0}}>
                            <ListSubheader style={{fontSize: '0.7vw', backgroundColor: 'rgb(255, 255, 255, 0.06)', color: 'rgb(255, 255, 255, 0.8)', borderTopLeftRadius: '1.5vw', borderTopRightRadius: '1.5vw'}}>
                                <div style={{display: 'flex', fontWeight: '600'}}>
                                    <div scope="col" style ={{padding:'0.1vw 1.5vw', flex: 1, lineHeight: '2.5vw'}}>FIRST NAME</div>
                                    <div scope="col" style ={{padding:'0.1vw 1.5vw', flex: 1, lineHeight: '2.5vw'}}>LAST NAME</div>
                                    {/* <th scope="col" style ={{padding:'0.5vw 1.5vw', width: '30%'}}>AS CLIENT NAME</th> */}
                                    <div scope="col" style ={{padding:'0.1vw 1.5vw', flex: 2, lineHeight: '2.5vw'}}>EMAIL ADDRESS</div>
                                    
                                </div>
                            </ListSubheader>
                            <div style={{fontSize: '0.85vw'}}>
                                {currentItems.map((user, index) => (
                                    <ListItemButton key={index} style={{"cursor": "pointer", display: 'table-row', display: 'flex', borderTop: '0.05vw solid rgb(255, 255, 255, 0.05)', fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.8)'}} onClick={() => openModalHandler(user.id)}>          
                                        {/* <ListItemButton style={{flex: 1}}> */}
                                            <div style ={{padding:'0vw 2vw', flex: 1}}>{user.firstName}</div>
                                            {/* <td style ={{padding:'0.5vw 1.5vw'}}>{item.as_client_name}</td> */}
                                            <div style ={{padding:'0vw 1.5vw', flex: 1}}>{user.lastName}</div>
                                            <div style ={{padding:'0vw 1.5vw', flex: 2}}>{user.email}</div>
                                            
                                        {/* </ListItemButton> */}
                                    </ListItemButton>
                                ))}
                                { !tableData.length && !isLoading &&
                                    <div style={{display: 'flex'}}>
                                        <div style={{flex: 1}}><p style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}>No client found</p></div>
                                    </div>
                                }
                                { isLoading &&
                                    <div style={{display: 'flex'}}>
                                        <div style={{flex: 1}}><p style={{textAlign: 'center', marginTop: '30px', marginBottom: '30px'}}><CircularProgress color="inherit" /></p></div>
                                    </div>
                                }
                                
                            </div>
                        </List>
                    </div>
                    </ThemeProvider>
            {/* </div> */}

            <div style={{ marginTop: '1vw', marginLeft: '0vw', marginRight: '0vw', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', textAlign: 'left' }}>
                <div style={{flex: 1}}>
                    <p style={{ color: 'rgb(255, 255, 255, 0.6)', fontSize: '0.9vw', marginLeft: '0.2vw', marginTop: '1vw' }}>
                        Showing {indexOfFirstItem+1} to {indexOfLastItem > tableData.length ? tableData.length: indexOfLastItem} of {tableData.length} entries
                    </p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button 
                        onClick={prevPage} 
                        disabled={currentPage === 1}
                        style={{
                            width: '2.5vw',
                            height: '2.5vw',
                            borderRadius: '0.5vw',
                            marginRight: '6px',
                            color: currentPage === 1 ? 'gray' : 'white',
                            backgroundColor: 'rgb(255, 255, 255, 0.02)',
                            border: '0.5px solid rgb(255, 255, 255, 0.3)',
                            // color: 'white',
                            cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.9vw',
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: '&larr;' }} />
                    </button>
            
                    <button 
                        onClick={nextPage} 
                        disabled={currentPage === Math.ceil(tableData.length / itemsPerPage)}
                        style={{
                            width: '2.5vw',
                            height: '2.5vw',
                            borderRadius: '0.5vw',
                            marginLeft: '6px',
                            color: 'white',
                            backgroundColor: 'rgb(255, 255, 255, 0.02)',
                            border: '0.5px solid rgb(255, 255, 255, 0.3)',
                            cursor: currentPage === Math.ceil(tableData.length / itemsPerPage) ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '0.9vw',
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: '&rarr;'}} />
                    </button>
                </div>
            </div>
        </>
    )
}